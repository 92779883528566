import React from 'react';
import { Link } from 'react-router-dom';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';

import Select from 'react-select';
import Loader from 'react-loader-spinner';
import NumberFormat from 'react-number-format';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';

import AnosLetivosService from 'services/AnosLetivosService';
import CursosService from 'services/CursosService';

class CadastroCargaHoraria extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showAlert: false,

      selectCursos: '',
    };

    this.anosLetivosService = new AnosLetivosService();
    this.cursosService = new CursosService();
    
    this.cadastrarCargaHoraria = this.cadastrarCargaHoraria.bind(this);
    this.atualizarCargaHoraria = this.atualizarCargaHoraria.bind(this);
    this.validarCampos = this.validarCampos.bind(this);
    this.limparFormulario = this.limparFormulario.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.contentLoad = this.contentLoad.bind(this);
  }

  async componentDidMount() {
    try {
      const anoLetivo = await this.anosLetivosService
        .carregarAnoLetivo(this.props.match.params.idAnoLetivo);

      const cursos = await this.cursosService
        .carregarCursosEnsinoFundamental();

      const selectCursos = [];
      cursos.forEach((curso) => {
        selectCursos.push({ value: curso.id, label: curso.nome });
      });

      if (this.props.match.params.idCargaHoraria !== undefined) {
        const cargaHoraria = await this.anosLetivosService
          .carregarCargaHoraria(this.props.match.params.idCargaHoraria);

        this.setState({
          cargaHorariaSelecionada: cargaHoraria,
        });

        this.contentLoad();
      }

      this.setState({
        anoLetivo,
        selectCursos,
      });
    } catch (msg) {
      this.setState({
        erro: true,
      });
    }
  }

  contentLoad() {
    this.setState({
      diasLetivos: this.state.cargaHorariaSelecionada.diasLetivos,
      cargaHoraria: this.state.cargaHorariaSelecionada.cargaHoraria,
      cursoSelecionado: { 
        value: this.state.cargaHorariaSelecionada.curso.id, 
        label: this.state.cargaHorariaSelecionada.curso.nome,
      },
    });
  }

  validarCampos() {
    let ret = true;
    if(!this.state.cursoSelecionado) {
      this.setState({ cursoState: 'danger' });
      ret = false;
    }
    if(!this.state.diasLetivos) {
      this.setState({ diasLetivosState: 'has-danger' });
      ret = false;
    }
    if(!this.state.cargaHoraria) {
      this.setState({ cargaHorariaState: 'has-danger' });
      ret = false;
    }
    return ret;
  }

  async cadastrarCargaHoraria() {
    if (!this.validarCampos()) return;

    this.setState({
      showLoaderModal: true,
      showAlert: false,
      loaderModalText: 'Cadastrando carga horária...',
    });

    const cargaHoraria = {
      cargaHoraria: this.state.cargaHoraria,
      diasLetivos: this.state.diasLetivos,
      anoLetivo: {
        id: this.state.anoLetivo.id,
      },
      curso: {
        id: this.state.cursoSelecionado.value,
      },
    };

    try {
      await this.anosLetivosService.cadastrarCargaHoraria(cargaHoraria);
      this.limparFormulario();
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Carga horária cadastrada com sucesso',
      });
    } catch (msg) {
      if (this.umounted) return;
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao cadastrar carga horária',
      });
    }
  }

  async atualizarCargaHoraria() {
    if (!this.validarCampos()) return;

    this.setState({
      showLoaderModal: true,
      showAlert: false,
      loaderModalText: 'Atualizando carga horária...',
    });

    const cargaHoraria = {
      id: this.state.cargaHorariaSelecionada.id,
      cargaHoraria: this.state.cargaHoraria,
      diasLetivos: this.state.diasLetivos,
      curso: {
        id: this.state.cursoSelecionado.value,
      },
    };

    try {
      await this.anosLetivosService.atualizarCargaHoraria(cargaHoraria);
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Carga horária atualizada com sucesso',
      });
    } catch (msg) {
      if (this.umounted) return;
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao atualizar carga horária',
      });
    }
  }

  limparFormulario() {
    this.setState({
      cursoSelecionado: '',
      diasLetivos: '',
      cargaHoraria: '',
    });
  }

  onSubmit(e) {
    e.preventDefault();

    if (this.state.showLoaderModal) return;

    if (!this.state.cargaHorariaSelecionada) {
      this.cadastrarCargaHoraria();
    } else {
      this.atualizarCargaHoraria();
    }
  }

  conteudoPagina() {
    return (!this.state.anoLetivo)
      ? <Card>
        <div align="center" style={{ margin: 50 }}>
          <Loader
            type="Oval"
            color="#053d7c"
            height="50"
            width="50" />
        </div>
      </Card> : <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos`}>Anos letivos</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`}>Anos letivos {this.state.anoLetivo.ano}</Link></BreadcrumbItem>
            <BreadcrumbItem>{this.props.match.params.idCargaHoraria ? 'Atualizar' : 'Cadastro'} Carga Horária</BreadcrumbItem>
          </Breadcrumb>
          <Form onSubmit={this.onSubmit}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Dados da Carga Horaria</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="3">
                    <FormGroup className={'has-label has-danger'}>
                      <label>Curso *</label>
                      <Select
                        noOptionsMessage={() => 'Nenhuma entrada'}
                        className={`react-select primary ${this.state.cursoState}`}
                        classNamePrefix="react-select"
                        value={this.state.cursoSelecionado}
                        onChange={(event) => {
                          this.setState({ cursoState: 'primary', cursoSelecionado: event });
                        }}
                        options={this.state.selectCursos}
                        placeholder="Curso..."
                      />
                      {this.state.cursoState === 'danger' ? (
                        <label className="error">
                          Informe o curso.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <label>Dias letivos *</label>
                    <FormGroup className={`has-label ${this.state.diasLetivosState}`}>
                      <Input
                        value={this.state.diasLetivos}
                        decimalSeparator={false}
                        allowNegative={false}
                        style={{ textAlign: 'right' }}
                        onValueChange={(val) => {
                          this.setState({ diasLetivos: val.formattedValue, diasLetivosState: '' })
                        }}
                        tag={NumberFormat} />
                      {this.state.diasLetivosState === 'has-danger' ? (
                        <label className="error">
                          Informe a quantidade de dias letivos
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <label>Carga Horária *</label>
                    <FormGroup className={`has-label ${this.state.cargaHorariaState}`}>
                      <Input
                        value={this.state.cargaHoraria}
                        decimalSeparator={false}
                        allowNegative={false}
                        style={{ textAlign: 'right' }}
                        onValueChange={(val) => {
                          this.setState({ cargaHoraria: val.formattedValue, cargaHorariaState: '' })
                        }}
                        tag={NumberFormat} />
                      {this.state.cargaHorariaState === 'has-danger' ? (
                        <label className="error">
                          Informe a carga horária
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
                <div className="category form-category">
                  * Campos obrigatórios
                </div>
              </CardBody>
              <CardFooter>
                <Row>
                  <Col md="6" className="text-left">
                    <Button color="primary" onClick={() => this.props.history.push(`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`)} >
                      Voltar
                    </Button>
                  </Col>
                  <Col md="6" className="text-right">
                    {
                      !this.state.cargaHorariaSelecionada
                        ? <Button type="submit" color="primary" >
                          Cadastrar
                        </Button>
                        : <Button type="submit" style={{ marginRight: 5 }} color="primary">
                          Atualizar
                        </Button>
                    }
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Form>
        </Col>
      </Row>;
  }

  render() {
    return (
      <div className="content">
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
        <LoaderModal
          isOpen={this.state.showLoaderModal}
          text={this.state.loaderModalText} />
      </div>
    );
  }
}

export default CadastroCargaHoraria;
