import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import Loader from 'react-loader-spinner';

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
} from 'reactstrap';

import env from 'conf/env.json';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';

import DocumentosService from 'services/DocumentosService';

import RootFooter from './Footer';

class Cadastro extends React.Component {
  constructor(props) {
    super(props);

    this.chave = this.props.match.params.chave;
    this.idEnte = this.props.match.params.idEnte;

    this.state = {
      showAlert: false,
    };

    this.documentosService = new DocumentosService();
  }

  componentWillUnmount() {
    this.documentosService.abortPedingRequests();
  }

  async componentDidMount() {
    try {
      const validacao = this.idEnte
        ? await this.documentosService.validarDocumentoEnte(this.idEnte, this.chave)
        : await this.documentosService.validarDocumento(this.chave);
      this.setState({ validacao });
    } catch (e) {
      this.setState({
        validacao: { erro: true },
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao validar documento',
      });
    }
  }

  resultado() {
    if (this.state.validacao.erro) {
      return <>
        <img src="https://sisaeduc.s3.sa-east-1.amazonaws.com/public/invalid.png" alt="erro" />
        <br />
        <b>Ocorreu um erro ao validar o documento.
          Não foi possível determinar a autenticidade do documento.</b>
      </>;
    }
    if (this.state.validacao.resultado) {
      const url = this.idEnte
        ? `${env.IMAGE_BUCKET_URL}/validacao/${this.idEnte}/${this.chave}.pdf`
        : `${env.IMAGE_BUCKET_URL}/validacao/${this.chave}.pdf`;
      return <>
        <img src="https://sisaeduc.s3.sa-east-1.amazonaws.com/public/valid.png" alt="válido" />
        <br />
        <b>{this.state.validacao.msg}</b>
        <br /><br />
        <a target="_blank" rel="noopener noreferrer"
          href={url}>Ver documento</a>
      </>;
    }
    return <>
      <img src="https://sisaeduc.s3.sa-east-1.amazonaws.com/public/invalid.png" alt="inválido" />
      <br />
      <b>{this.state.validacao.msg}</b>
    </>;
  }

  render() {
    return <>
      <header id="header" className="fixed-top">
        <div className="container d-flex align-items-center">
          <h1 className="logo mr-auto"><Link to="/">{env.SYSNAME}</Link></h1>
          <nav className="nav-menu d-none d-lg-block">
            <ul>
              <li><NavLink to="/">&nbsp;</NavLink></li>
            </ul>
          </nav>
        </div>
      </header>
      <main id="main">
        <section className="breadcrumbs">
          <div className="container">
            <div className="d-flex justify-content-between align-items-center">
              <h2>Documentos</h2>
              <ol>
                <li><NavLink to="/">Início</NavLink></li>
                <li>Documentos</li>
              </ol>
            </div>
          </div>
        </section>
        <div className="container">
          <Alert
            style={{ marginTop: '20px' }}
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
        </div>
        <section className="inner-page">
          <div className="container">
            <Row>
              <Col className="mr-auto" md={{ size: 6, offset: 3 }}>
                <Card className="card-signup text-left" style={{ padding: '20px' }}>
                  <CardHeader>
                    <CardTitle tag="h4">Documentos</CardTitle>
                    <div align="center" style={{ margin: 50 }}>
                      {
                        !this.state.validacao ? <>
                          <Loader
                            type="Oval"
                            color="#053d7c"
                            height="50"
                            width="50" />
                          <br />
                          Validando
                        </> : this.resultado()
                      }
                    </div>
                  </CardHeader>
                  <CardBody>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </section>
      </main>
      <RootFooter />
      <LoaderModal
        isOpen={this.state.buttonDisabled}
        text='Validando...' />
    </>;
  }
}

export default Cadastro;
