import React from 'react';
import { connect } from 'react-redux';

// reactstrap components
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';

import Loader from 'react-loader-spinner';

import LoaderModal from 'views/components/LoaderModal';
import Alert from 'views/components/Alert';

import LoginService from 'services/LoginService';
import DashboardService from 'services/DashboardService';

import Papel from 'assets/csv/papeis.json';
import AcaoTrack from 'assets/csv/acoes-track.json';

class DashboardAdmin extends React.Component {
  constructor(props) {
    super(props);

    this.idPagina = `${this.props.dadosUsuario.idVinculo}-logintrack`;
    this.paginaSelecionada = sessionStorage.getItem(`${this.idPagina}-pg`) || 1;
    this.linhasPorPagina = sessionStorage.getItem(`${this.idPagina}-ln`) || 5;
    this.like = '';

    this.papeis = [
      Papel.GESTOR,
      Papel.DIRETOR,
      Papel.PROFESSOR,
      Papel.ADMINISTRADOR,
      Papel.ALUNO,
      Papel.PAIS,
      Papel.COORDENADOR_PEDAGOGICO,
      Papel.SECRETARIO,
    ];

    this.acoesTrack = [
      AcaoTrack.LOGIN,
    ];

    this.state = {
      showLoaderModal: false,
      showAlert: false,
      anoAtual: new Date().getFullYear(),

      error: null,

      dadosTabela: {
        header: [['Tipo', '10%'], ['Nome', '30%'], ['Cidade', '20%'], ['Papel', '20%'], ['Data', '10%']],
        columnAlign: ['text-left', 'text-left', 'text-left', 'text-center', 'text-center'],
        keys: ['tipo_str', 'nome', 'ente', 'papel_str', 'data'],
        rows: [],
        total: undefined,
      },
    };

    this.loginService = new LoginService();
    this.dashboardService = new DashboardService();
  }

  async componentDidMount() {
    try {
      const informacoesGerais = await this.dashboardService
        .carregarInformacoesGeraisAdmin();

      // this.carregarAcaoTracks(this.paginaSelecionada, this.linhasPorPagina, '');

      this.setState({
        informacoesGerais,
      });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  async carregarAcaoTracks(pagina, linhasPorPagina, like) {
    try {
      const data = await this.dashboardService
        .carregarAcaoTracks(pagina, linhasPorPagina, like);

      data.acaoTracks.forEach((acaoTrack) => {
        Object.assign(acaoTrack, {
          papel_str: this.papeis[acaoTrack.papel].label,
          tipo_str: this.acoesTrack[acaoTrack.tipo].label,
        });
      });

      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela,
          { rows: data.acaoTracks, total: data.total }),
      });
    } catch (e) {
      if (this.umounted) return;
      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela, { total: -1 }),
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao buscar informações de acesso',
      });
    }
  }

  conteudoPagina() {
    return this.state.informacoesGerais ? <>
      <div className="content">
        <Alert
          color={this.state.alertColor}
          isOpen={this.state.showAlert}
          toggle={() => { this.setState({ showAlert: false }); }}>
          {this.state.alertMsg}
        </Alert>
        <Breadcrumb>
          <BreadcrumbItem>Dashboard</BreadcrumbItem>
        </Breadcrumb>
        <Row>
          <Col lg="4" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="fa fa-building text-warning" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Total de Entes</p>
                      <CardTitle tag="p">{this.state.informacoesGerais.totalEntes}</CardTitle>
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats">
                  <i className="fa fa-calendar-o" />
                  {this.state.anoAtual}
                </div>
              </CardFooter>
            </Card>
          </Col>
          <Col lg="4" md="6" sm="6">
            <Card className="card-stats">
              <CardBody>
                <Row>
                  <Col md="4" xs="5">
                    <div className="icon-big text-center icon-warning">
                      <i className="fa fa-dollar text-success" />
                    </div>
                  </Col>
                  <Col md="8" xs="7">
                    <div className="numbers">
                      <p className="card-category">Total Faturado</p>
                      <CardTitle tag="p">R$ {this.state.informacoesGerais.totalFaturado}</CardTitle>
                    </div>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <hr />
                <div className="stats">
                  <i className="fa fa-calendar-o" />
                  {this.state.anoAtual}
                </div>
              </CardFooter>
            </Card>
          </Col>
        </Row>
        {
          /*
            <Row>
              <Col>
                <Card>
                  <CardHeader>
                    <CardTitle tag="h4">Informações de acesso</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <AdaptiveTable
                      disableActions
                      selectedPage={this.paginaSelecionada}
                      rowsPerPage={this.linhasPorPagina}
                      rowsPerPageCallback={(info) => {
                        this.paginaSelecionada = 1;
                        sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                        this.linhasPorPagina = info.rowsPerPage;
                        sessionStorage.setItem(`${this.idPagina}-ln`, this.linhasPorPagina);
                        this.carregarAcaoTracks(this.paginaSelecionada,
                          info.rowsPerPage, this.like);
                      }}
                      likeCallback={(text) => {
                        this.like = text;
                        this.paginaSelecionada = 1;
                        sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                        this.carregarAcaoTracks(this.paginaSelecionada, this.linhasPorPagina, text);
                      }}
                      paginatorCallback={(page) => {
                        this.paginaSelecionada = page;
                        sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                        this.carregarAcaoTracks(this.paginaSelecionada,
                          this.linhasPorPagina, this.like);
                      }}
                      data={this.state.dadosTabela} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          */
        }
      </div>
    </> : <Card>
      <div align="center" style={{ margin: 50 }}>
        <Loader
          type="Oval"
          color="#053d7c"
          height="50"
          width="50" />
      </div>
    </Card>;
  }

  render() {
    return (
      <div className='content'>
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
        <LoaderModal
          isOpen={this.state.showLoaderModal}
          text="Carregando..." />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ dadosUsuario: state.dadosUsuario });

export default connect(mapStateToProps)(DashboardAdmin);
