import React from 'react';

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  FormGroup,
  Input,
} from 'reactstrap';

import Loader from 'react-loader-spinner';

import LoginService from 'services/LoginService';
import LimitesService from 'services/LimitesService';
import EntesService from 'services/EntesService';

import TipoPlano from 'assets/csv/tipos-plano.json';

class LimitesEnte extends React.Component {
  constructor(props) {
    super(props);

    this.tiposPlano = [
      TipoPlano.K10,
      TipoPlano.K20,
      TipoPlano.K50,
      TipoPlano.CUSTOM,
    ];

    this.state = {
      erro: null,

      limites: null,
    };

    this.loginService = new LoginService();
    this.limitesService = new LimitesService();
    this.entesService = new EntesService();
  }

  componentWillUnmount() {
    this.loginService.abortPedingRequests();
    this.limitesService.abortPedingRequests();
    this.entesService.abortPedingRequests();
  }

  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);
    try {
      const dadosEnte = await this.entesService
        .carregarDadosEnte();

      const limites = await this.entesService
        .carregarLimites();

      this.setState({
        dadosEnte,
        limites,
      });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  conteudoPagina() {
    return (this.state.limites === null)
      ? <Card>
        <div align="center" style={{ margin: 50 }}>
          <Loader
            type="Oval"
            color="#053d7c"
            height="50"
            width="50" />
        </div>
      </Card> : <Row>
        <Col md="12">
          <Breadcrumb>
            <BreadcrumbItem active>Meu plano</BreadcrumbItem>
          </Breadcrumb>
          <Card>
            <CardHeader>
              <CardTitle tag="h4">Meu plano</CardTitle>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="2">
                  <FormGroup className={'has-label'}>
                    <label>Plano assinado</label>
                    <Input
                      disabled
                      value={this.tiposPlano[this.state.dadosEnte.tipoPlano].label}
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup className={'has-label'}>
                    <label>Data de vencimento</label>
                    <Input
                      disabled
                      value={this.state.dadosEnte.dataVencimentoPlano}
                      type="text"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <br />
              <h5>Limites</h5>
              <Row>
                <Col md="2">
                  <FormGroup className={'has-label'}>
                    <label>Escolas</label>
                    <Input
                      disabled
                      value={this.state.limites.escolas}
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup className={'has-label'}>
                    <label>Cursos</label>
                    <Input
                      disabled
                      value={this.state.limites.cursos}
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup className={'has-label'}>
                    <label>Alunos</label>
                    <Input
                      disabled
                      value={this.state.limites.alunos}
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup className={'has-label'}>
                    <label>Estruturas curriculares por curso</label>
                    <Input
                      disabled
                      value={this.state.limites.estruturasCurriculares}
                      type="text"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="2">
                  <FormGroup className={'has-label'}>
                    <label>Turmas por escola e ano letivo</label>
                    <Input
                      disabled
                      value={this.state.limites.turmas}
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup className={'has-label'}>
                    <label>Diretores</label>
                    <Input
                      disabled
                      value={this.state.limites.diretores}
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup className={'has-label'}>
                    <label>Professores</label>
                    <Input
                      disabled
                      value={this.state.limites.professores}
                      type="text"
                    />
                  </FormGroup>
                </Col>
                <Col md="2">
                  <FormGroup className={'has-label'}>
                    <label>Arquivos de histórico por aluno</label>
                    <Input
                      disabled
                      value={this.state.limites.historicos}
                      type="text"
                    />
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>;
  }

  render() {
    return (
      <div className="content">
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
          </div>
          </Card>
        }
      </div>
    );
  }
}

export default LimitesEnte;
