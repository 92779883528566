import React from 'react';
import { Link } from 'react-router-dom';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';

import Loader from 'react-loader-spinner';
import NumberFormat from 'react-number-format';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';

import EscolasService from 'services/EscolasService';
import UsuarioService from 'services/UsuarioService';
import LoginService from 'services/LoginService';

class CadastroItemMerenda extends React.Component {
  constructor(props) {
    super(props);

    this.escolasService = new EscolasService();
    this.usuarioService = new UsuarioService();
    this.loginService = new LoginService();

    this.state = {
      showAlert: false,
      buttonDisabled: false,

      loaderModalText: '',

      nome: '',
      quantidade: '',

      itemMerendaSelecionado: null,
      erro: null,
    };

    this.cadastrarItemMerenda = this.cadastrarItemMerenda.bind(this);
    this.atualizarItemMerenda = this.atualizarItemMerenda.bind(this);
    this.validarCampos = this.validarCampos.bind(this);
    this.limparFormulario = this.limparFormulario.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentWillUnmount() {
    this.loginService.abortPedingRequests();
    this.escolasService.abortPedingRequests();
    this.usuarioService.abortPedingRequests();

    this.umounted = true;
  }

  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);

    try {
      const escola = await this.escolasService
        .carregarEscola(this.props.match.params.idEscola);

      this.setState({ escola });

      if (this.props.match.params.idItemMerenda !== undefined) {
        const itemMerenda = await this.escolasService
          .carregarItemMerenda(this.props.match.params.idItemMerenda);

        this.setState({
          itemMerendaSelecionado: itemMerenda,
        });

        this.contentLoad();
      }
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  contentLoad() {
    this.setState({
      nome: this.state.itemMerendaSelecionado.nome,
      quantidade: this.state.itemMerendaSelecionado.quantidade,
    });
  }

  validarCampos() {
    let ret = true;
    if (this.state.nome === '') {
      this.setState({ nomeState: 'has-danger' });
      ret = false;
    }
    if (this.state.quantidade === '') {
      this.setState({ quantidadeState: 'has-danger' });
      ret = false;
    }
    return ret;
  }

  async cadastrarItemMerenda() {
    if (!this.validarCampos()) return;

    this.setState({
      buttonDisabled: true,
      showAlert: false,
      loaderModalText: 'Cadastrando item...',
    });

    const itemMerenda = {
      nome: this.state.nome,
      quantidade: this.state.quantidade,
      escola: {
        id: this.props.match.params.idEscola,
      },
    };

    try {
      await this.escolasService.cadastrarItemMerenda(itemMerenda);
      this.limparFormulario();
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Item cadastrado com sucesso',
      });
    } catch (e) {
      if (this.umounted) return;
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: e || 'Erro ao cadastrar item',
      });
    }
  }

  async atualizarItemMerenda() {
    if (!this.validarCampos()) return;

    this.setState({
      buttonDisabled: true,
      showAlert: false,
      loaderModalText: 'Atualizando item...',
    });

    const itemMerenda = {
      id: this.state.itemMerendaSelecionado.id,
      nome: this.state.nome,
      quantidade: this.state.quantidade,
    };

    try {
      await this.escolasService.atualizarItemMerenda(itemMerenda);

      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Item atualizado com sucesso',
      });
    } catch (e) {
      if (this.umounted) return;
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: e || 'Erro ao atualizar item',
      });
    }
  }

  limparFormulario() {
    this.setState({
      nome: '',
      quantidade: '',

      nomeState: '',
      quantidadeState: '',

      buttonDisabled: false,
    });
  }

  onSubmit(e) {
    e.preventDefault();

    if (this.state.buttonDisabled) return;

    if (!this.state.itemMerendaSelecionado) {
      this.cadastrarItemMerenda();
    } else {
      this.atualizarItemMerenda();
    }
  }

  conteudoPagina() {
    return (!this.state.escola)
      ? <Card>
        <div align="center" style={{ margin: 50 }}>
          <Loader
            type="Oval"
            color="#053d7c"
            height="50"
            width="50" />
        </div>
      </Card> : <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to="/gestor/escolas">Escolas</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/escolas/${this.props.match.params.idEscola}`}>{this.state.escola.nome}</Link></BreadcrumbItem>
            <BreadcrumbItem active>{this.state.itemMerendaSelecionado ? 'Edição de item' : 'Cadastro de item'}</BreadcrumbItem>
          </Breadcrumb>
          <Form onSubmit={this.onSubmit}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Dados do item</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="5">
                    <FormGroup className={`has-label ${this.state.nomeState}`}>
                      <label>Nome *</label>
                      <Input
                        type="text"
                        maxLength="200"
                        value={this.state.nome}
                        onChange={(evt) => this.setState({ nome: evt.target.value, nomeState: 'has-success' })}
                      />
                      {this.state.nomeState === 'has-danger' ? (
                        <label className="error">
                          Informe o nome do item.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <FormGroup className={`has-label ${this.state.quantidadeState}`}>
                      <label>Quantidade *</label>
                      <Input
                        value={this.state.quantidade}
                        decimalSeparator={false}
                        allowNegative={false}
                        maxLength={5}
                        style={{ textAlign: 'right' }}
                        onValueChange={(val) => {
                          this.setState({ quantidade: val.formattedValue, quantidadeState: '' })
                        }}
                        tag={NumberFormat} />
                      {this.state.quantidadeState === 'has-danger' ? (
                        <label className="error">
                          Informe a quantidade do item.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
                <div className="category form-category">
                  * Campos obrigatórios
                </div>
              </CardBody>
              <CardFooter>
                <Row>
                  <Col md="6" className="text-left">
                    <Button
                      color="primary"
                      disabled={this.state.buttonDisabled}
                      onClick={() => this.props.history.push(`${this.props.layout}/escolas/${this.props.match.params.idEscola}`)} >
                      Voltar
                    </Button>
                  </Col>
                  <Col md="6" className="text-right">
                    <Button type="submit" color="primary" disabled={this.state.buttonDisabled} >
                      {!this.state.itemMerendaSelecionado ? 'Cadastrar' : 'Atualizar'}
                    </Button>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Form>
        </Col>
      </Row>;
  }

  render() {
    return (
      <div className="content">
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
          </div>
          </Card>
        }
        <LoaderModal
          isOpen={this.state.buttonDisabled}
          text={this.state.loaderModalText} />
      </div>
    );
  }
}

export default CadastroItemMerenda;
