/* eslint-disable max-len */
import React from 'react';

import {
  Row,
  Col,
  Button,
  Form,
  Table,
} from 'reactstrap';

import 'react-perfect-scrollbar/dist/css/styles.css';

import Loader from 'react-loader-spinner';
import Select from 'react-select';

import LoaderModal from 'views/components/LoaderModal';

import TurmasService from 'services/TurmasService';
import MateriasService from 'services/MateriasService';
import UsuarioService from 'services/UsuarioService';
import ProfessorAuxiliarService from 'services/ProfessorAuxiliarService';

import Papel from 'assets/csv/papeis.json';
import SituacaoDiario from 'assets/csv/situacoes-diario.json';
import CicloEnsino from 'assets/csv/ciclos-ensino.json';

class DiariosTurma extends React.Component {
  constructor(props) {
    super(props);

    this.situacoesDiario = [
      SituacaoDiario.ABERTO,
      SituacaoDiario.CONSOLIDADO,
    ];

    this.state = {
      turma: props.turma,
      escola: props.escola,
      anoLetivo: props.anoLetivo,
      loading: true,
    };

    this.turmasService = new TurmasService();
    this.materiasService = new MateriasService();
    this.usuarioService = new UsuarioService();
    this.professorAuxiliarService = new ProfessorAuxiliarService();

    this.atualizarProfessores = this.atualizarProfessores.bind(this);
  }

  async componentDidMount() {
    const materias = await this.materiasService
      .carregarMateriasPorEstruturaCurricular(this.state.turma.estruturaCurricular.id);

    Object.assign(this.state.turma.estruturaCurricular, { materias });

    const professores = await this.turmasService
      .carregarProfessores(this.props.match.params.idTurma);

    const data = await this.usuarioService
      .carregarUsuariosAtivos(Papel.PROFESSOR.value, 1, 99999, '');

    const professoresAuxiliares = await this.professorAuxiliarService
      .carregarProfessoresAuxiliares();

    data.usuarios.forEach((professor) => {
      const pa = professoresAuxiliares
        .find((pa) => pa.usuario.id === professor.id);
      
      if(pa) {
        professor.auxiliar = pa.auxiliar;
      }
    });

    const selectProfessor = [];
    data.usuarios.forEach((professor) => {
      const auxiliar = professor.auxiliar ? ' (Auxiliar)' : '';
      
      selectProfessor.push({
        value: professor.id,
        label: professor.nome
          ? `${professor.nome}${auxiliar}`
          : `${professor.credenciais.email}${auxiliar}`,
      });
    });

    professores.forEach((professor) => {
      const auxiliar = professoresAuxiliares
        .find((pa) => pa.usuario.id === professor.usuario.id);
      
      if (auxiliar && auxiliar.auxiliar) {
        professor.usuario.nome = `${professor.usuario.nome} (Auxiliar)`;
      }
    });

    this.state.turma.estruturaCurricular.materias.forEach((materia) => {
      const professoresSelecionados = professores
        .filter((professor) => professor.materia.id === materia.id)
        .map((professor) => ({
          value: professor.usuario.id,
          label: professor.usuario.nome ? professor.usuario.nome
            : professor.usuario.credenciais.email,
        }));
      Object.assign(materia, { selectProfessor, professoresSelecionados });
    });

    this.setState({
      professores,
      loading: false,
    });
  }

  async atualizarProfessores(e) {
    e.preventDefault();

    if (this.state.showLoaderModal) return;

    this.setState({
      showLoaderModal: true,
      loaderModalText: 'Atualizando...',
    });

    this.props.callbackMessage({
      showAlert: false,
    });
    try {
      const professores = [];
      this.state.turma.estruturaCurricular.materias.forEach((materia) => {
        if (materia.professoresSelecionados) {
          materia.professoresSelecionados.forEach((professor) => {
            professores.push({
              turma: { id: this.state.turma.id },
              usuario: { id: professor.value },
              materia: { id: materia.id },
            });
          });
        }
      });

      await this.turmasService.atualizarProfessores(this.state.turma.id, professores);

      this.setState({
        showLoaderModal: false,
      });

      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Professores atualizados com sucesso.',
      });
    } catch (msg) {
      this.setState({
        showLoaderModal: false,
      });

      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao atualizar professores da turma.',
      });
    }
  }

  render() {
    return this.state.loading ? <div align="center" style={{ padding: 30 }}>
      <Loader
        type="Oval"
        color="#053d7c"
        height="50"
        width="50" />
    </div> : <>
      <Form onSubmit={this.atualizarProfessores}>
        <Row>
          <Col md="12">
            <Table>
              <thead className="text-primary">
                <tr>
                  <th style={{ width: '20%' }}>
                    {this.state.turma.curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value
                      ? 'Campos de experiências' : 'Componentes curriculares'}</th>
                  <th>Professores</th>
                </tr>
              </thead>
              <tbody>
                {
                  this.state.turma.estruturaCurricular.materias
                    .map((materia, index) => (
                      <tr key={index}>
                        <td>{materia.nome}</td>
                        <td>
                          <Select
                            noOptionsMessage={() => 'Nenhuma entrada'}
                            className={'react-select primary'}
                            classNamePrefix="react-select"
                            placeholder="Professores..."
                            closeMenuOnSelect={false}
                            isMulti
                            isDisabled={this.props.role === Papel.COORDENADOR_PEDAGOGICO.value
                              || this.props.role === Papel.PROFESSOR.value}
                            value={materia.professoresSelecionados}
                            onChange={(event) => {
                              Object.assign(materia, {
                                professoresSelecionados: event,
                              });
                              this.forceUpdate();
                            }}
                            options={materia.selectProfessor}
                          />
                        </td>
                      </tr>
                    ))
                }
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col className="text-left">
            <Button
              color="primary"
              onClick={() => this.props.history
                .push(`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}`)} >
              Voltar
            </Button>
          </Col>
          {
            this.props.role !== Papel.COORDENADOR_PEDAGOGICO.value
              && this.props.role !== Papel.PROFESSOR.value && <Col className="text-right">
              <Button color="primary"
                type="submit"
                disabled={this.state.showLoaderModal}>
                Atualizar Professores
              </Button>
            </Col>
          }
        </Row>
      </Form>
      <LoaderModal
        isOpen={this.state.showLoaderModal}
        text={`${this.state.loaderModalText}`} />
    </>;
  }
}

export default DiariosTurma;
