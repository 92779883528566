/* eslint-disable max-len */
import React from 'react';

import {
  Row,
  Col,
  Button,
  Form,
  FormGroup,
  Input,
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import 'react-perfect-scrollbar/dist/css/styles.css';

import Loader from 'react-loader-spinner';
import NumberFormat from 'react-number-format';

import Utils from 'utils/Utils';

import LoaderModal from 'views/components/LoaderModal';

import TurmasService from 'services/TurmasService';
import DiariosService from 'services/DiariosService';

import Papel from 'assets/csv/papeis.json';
import SituacaoDiario from 'assets/csv/situacoes-diario.json';
import TipoAvaliacao from 'assets/csv/tipos-avaliacao.json';

class DiariosTurma extends React.Component {
  constructor(props) {
    super(props);

    this.situacoesDiario = [
      SituacaoDiario.ABERTO,
      SituacaoDiario.CONSOLIDADO,
    ];

    this.state = {
      turma: props.turma,
      escola: props.escola,
      anoLetivo: props.anoLetivo,
      loading: true,
    };

    this.turmasService = new TurmasService();
    this.diariosService = new DiariosService();

    this.atualizarDiarios = this.atualizarDiarios.bind(this);
    this.imprimirDiario = this.imprimirDiario.bind(this);
    this.imprimirAtaFinal = this.imprimirAtaFinal.bind(this);
    this.imprimirQuadroFrequencia = this.imprimirQuadroFrequencia.bind(this);
    this.imprimirListaAssinaturaAlunos = this.imprimirListaAssinaturaAlunos.bind(this);
  }

  async componentDidMount() {
    const diarios = this.props.role !== Papel.PROFESSOR.value
      ? await this.diariosService.carregarDiariosDaTurma(this.state.turma.id)
      : await this.diariosService.carregarDiariosDoProfessor(this.state.turma.id);

    diarios.forEach((diario) => {
      if (!diario.aulasALancar && diario.aulasALancar !== 0) {
        Object.assign(diario, { aulasALancar: '' });
      }
    });

    this.setState({
      diarios,
      loading: false,
    });
  }

  validarCampos() {
    let ret = true;
    this.state.diarios.forEach((diario) => {
      if (!diario.aulasALancar && diario.aulasALancar !== 0) {
        Object.assign(diario, { diarioState: 'has-danger' });
        ret = false;
      }
    });
    return ret;
  }

  async atualizarDiarios(e) {
    e.preventDefault();
    if (!this.validarCampos()) {
      this.forceUpdate();
      return;
    }

    if (this.state.showLoaderModal) return;

    this.setState({
      showLoaderModal: true,
      loaderModalText: 'Atualizando...',
    });

    this.props.callbackMessage({ showAlert: false });
    try {
      await this.turmasService.atualizarDiarios(this.state.turma.id, this.state.diarios);

      this.setState({
        showLoaderModal: false,
      });

      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Números de aulas dos diários atualizados com sucesso.',
      });
    } catch (msg) {
      this.setState({
        showLoaderModal: false,
      });
      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao atualizar números de aulas dos diários.',
      });
    }
  }

  async imprimirDiario(idDiario) {
    this.setState({
      showLoaderModal: true,
      loaderModalText: 'Imprimindo diário para pdf...',
    });
    this.props.callbackMessage({
      showAlert: false,
    });
    try {
      const pdf = await this.diariosService.imprimirDiario(idDiario);

      const file = Utils.base64ToBlob(pdf);
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);

      this.setState({
        showLoaderModal: false,
      });

      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Diário impresso com sucesso. Caso o documento não seja visualizado, desabilite o bloqueador de pop-up para este site',
      });
    } catch (msg) {
      if (this.umounted) return;
      this.setState({
        showLoaderModal: false,
      });
      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao imprimir diário',
      });
    }
  }

  async imprimirAtaFinal(idDiario) {
    this.setState({
      showLoaderModal: true,
      loaderModalText: 'Imprimindo ata final para pdf...',
    });
    this.props.callbackMessage({
      showAlert: false,
    });
    try {
      const pdf = await this.diariosService.imprimirAtaFinal(idDiario);

      const file = Utils.base64ToBlob(pdf);
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);

      this.setState({
        showLoaderModal: false,
      });

      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Ata final impressa com sucesso. Caso o documento não seja visualizado, desabilite o bloqueador de pop-up para este site',
      });
    } catch (msg) {
      if (this.umounted) return;
      this.setState({
        showLoaderModal: false,
      });
      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao imprimir ata final',
      });
    }
  }

  async imprimirQuadroFrequencia(idDiario) {
    this.setState({
      showLoaderModal: true,
      loaderModalText: 'Imprimindo quadro de frequência para pdf...',
    });

    this.props.callbackMessage({
      showAlert: false,
    });
    try {
      const pdf = await this.diariosService.imprimirQuadroFrequencia(idDiario);

      const file = Utils.base64ToBlob(pdf);
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);

      this.setState({
        showLoaderModal: false,
      });
      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Quadro de frequência impresso com sucesso. Caso o documento não seja visualizado, desabilite o bloqueador de pop-up para este site',
      });
    } catch (msg) {
      if (this.umounted) return;
      this.setState({
        showLoaderModal: false,
      });
      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao imprimir quadro de frequência',
      });
    }
  }

  async imprimirListaAssinaturaAlunos(idDiario) {
    this.setState({
      showLoaderModal: true,
      loaderModalText: 'Imprimindo lista de assinaturas para pdf...',
    });
    this.props.callbackMessage({
      showAlert: false,
    });
    try {
      const pdf = await this.diariosService.imprimirListaAssinaturaAlunos(idDiario);

      const file = Utils.base64ToBlob(pdf);
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);

      this.setState({
        showLoaderModal: false,
      });
      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Lista de assinaturas impressa com sucesso. Caso o documento não seja visualizado, desabilite o bloqueador de pop-up para este site',
      });
    } catch (msg) {
      if (this.umounted) return;
      this.setState({
        showLoaderModal: false,
      });
      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao imprimir lista de assinaturas',
      });
    }
  }

  diarioSelecionado(diario) {
    if (this.state.turma.tipoAvaliacao === TipoAvaliacao.NOTA.value) {
      this.props.history
        .push(`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`
          + `/escola/${this.state.escola.id}`
          + `/turma/${this.state.turma.id}`
          + `/diario/${diario.id}`);
    } else {
      this.props.history
        .push(`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`
          + `/escola/${this.state.escola.id}`
          + `/turma/${this.state.turma.id}`
          + `/diario/${diario.id}/alfabetizacao`);
    }
  }

  gerenciarDiarios() {
    this.props.history
        .push(`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`
          + `/escola/${this.state.escola.id}`
          + `/turma/${this.state.turma.id}/gerenciadiarios`);
  }

  render() {
    return this.state.loading ? <div align="center" style={{ padding: 30 }}>
      <Loader
        type="Oval"
        color="#053d7c"
        height="50"
        width="50" />
    </div> : <>
      <Form onSubmit={this.atualizarDiarios}>
        <Row>
          <Col md="12">
            <Table hover>
              <thead className="text-primary">
                <tr>
                  <th style={{ width: '40%' }}>Nome</th>
                  <th style={{ width: '30%' }}>Situação</th>
                  <th style={{ width: '10%' }}>Aulas à lançar</th>
                  <th style={{ width: '10%' }}></th>
                </tr>
              </thead>
              <tbody>
                {
                  this.state.diarios.map((diario, index) => (
                    <tr
                      className='tr-clickable'
                      key={index}
                      onClick={() => this.diarioSelecionado(diario)}>
                      <td>{diario.nome}</td>
                      <td>{this.situacoesDiario[diario.situacao].label}</td>
                      <td>
                        <FormGroup className={`has-label ${diario.diarioState}`}>
                          <Input
                            disabled={diario.situacao
                              === SituacaoDiario.CONSOLIDADO.value
                              || (this.props.role === Papel.COORDENADOR_PEDAGOGICO.value
                                || this.props.role === Papel.PROFESSOR.value)}
                            value={diario.aulasALancar}
                            decimalSeparator={false}
                            allowNegative={false}
                            style={{ textAlign: 'right' }}
                            onClick={(e) => e.stopPropagation()}
                            onValueChange={(val) => {
                              Object.assign(
                                diario, { aulasALancar: val.formattedValue, diarioState: '' },
                              );
                              this.forceUpdate();
                            }}
                            tag={NumberFormat} />
                          {diario.diarioState === 'has-danger' ? (
                            <label className="error">
                              Informe um número de aulas válido.
                            </label>
                          ) : null}
                        </FormGroup>
                      </td>
                      <td className="text-center">
                        <UncontrolledDropdown>
                          <DropdownToggle
                            onClick={(e) => e.stopPropagation()}
                            aria-haspopup={true}
                            color="default"
                            data-toggle="collapse"
                            id="navbarDropdownMenuLink"
                            nav>
                            <Button color="default" size="sm" type="button">
                              Opções <b className="caret" />
                            </Button>
                          </DropdownToggle>
                          <DropdownMenu aria-labelledby="navbarDropdownMenuLink" right>
                            {
                              this.props.role !== Papel.COORDENADOR_PEDAGOGICO.value
                              && <DropdownItem
                                onClick={(e) => {
                                  e.stopPropagation();
                                  this.imprimirDiario(diario.id);
                                }}>
                                Imprimir diário
                              </DropdownItem>
                            }
                            {
                              this.props.role !== Papel.COORDENADOR_PEDAGOGICO.value
                              && <DropdownItem
                                onClick={(e) => {
                                  e.stopPropagation();
                                  this.imprimirAtaFinal(diario.id);
                                }}>
                                Imprimir ata final
                              </DropdownItem>
                            }
                            {
                              this.props.role !== Papel.COORDENADOR_PEDAGOGICO.value
                              && <DropdownItem
                                onClick={(e) => {
                                  e.stopPropagation();
                                  this.imprimirQuadroFrequencia(diario.id);
                                }}>
                                Imprimir quadro de frequência
                              </DropdownItem>
                            }
                            {
                              this.props.role !== Papel.COORDENADOR_PEDAGOGICO.value
                              && <DropdownItem
                                onClick={(e) => {
                                  e.stopPropagation();
                                  this.imprimirListaAssinaturaAlunos(diario.id);
                                }}>
                                Imprimir lista de assinatura dos alunos
                              </DropdownItem>
                            }
                            {/*
                              this.props.role !== Papel.PROFESSOR.value && <DropdownItem
                                onClick={(e) => {
                                  e.stopPropagation();
                                  this.props.history
                                    .push(`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`
                                      + `/escola/${this.state.escola.id}`
                                      + `/turma/${this.state.turma.id}`
                                      + `/diario/${diario.id}/frequencia/ano`);
                                }}>
                                Ver faltas
                              </DropdownItem>
                            */}
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row>
          <Col className="text-left">
            <Button
              color="primary"
              onClick={() => this.props.history
                .push(`${this.props.layout}/anosletivos/${this.state.anoLetivo.id}/escola/${this.state.escola.id}`)} >
              Voltar
            </Button>
          </Col>
          {
            this.props.role !== Papel.COORDENADOR_PEDAGOGICO.value
            && this.props.role !== Papel.PROFESSOR.value && <Col className="text-right">
                <Button color="primary"
                type="button"
                onClick={() => this.gerenciarDiarios()}
                disabled={this.state.showLoaderModal}>
                Gerenciar diários
              </Button>
              <Button 
                color="primary"
                type="submit"
                disabled={this.state.showLoaderModal}>
                Atualizar Número de aulas
              </Button>
            </Col>
          }
        </Row>
      </Form>
      <LoaderModal
        isOpen={this.state.showLoaderModal}
        text={`${this.state.loaderModalText}`} />
    </>;
  }
}

export default DiariosTurma;
