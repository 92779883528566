import React from 'react';
import _ from 'lodash';

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';

import {
  Tabs,
  TabList,
  Tab,
  TabPanel,
} from 'react-tabs';

import { connect } from 'react-redux';

import Select from 'react-select';
import validator from 'validator';
import Loader from 'react-loader-spinner';

import Alert from 'views/components/Alert';
import AdaptiveTable from 'views/components/AdaptiveTable';
import ConfirmModal from 'views/components/ConfirmModal';
import LoaderModal from 'views/components/LoaderModal';

import Papel from 'assets/csv/papeis.json';

import ConvitesService from 'services/ConvitesService';
import LoginService from 'services/LoginService';
import UsuarioService from 'services/UsuarioService';
import EntesService from 'services/EntesService';
import EscolasService from 'services/EscolasService';

class ConvitesDiretores extends React.Component {
  constructor(props) {
    super(props);

    this.idPagina = `${this.props.dadosUsuario.idVinculo}-convites-gestor`;

    this.paginaSelecionada = sessionStorage.getItem(`${this.idPagina}-pg`) || 1;
    this.linhasPorPagina = sessionStorage.getItem(`${this.idPagina}-ln`) || 5;
    this.like = '';

    this.papeis2 = [
      Papel.GESTOR,
      Papel.DIRETOR,
      Papel.PROFESSOR,
      Papel.ADMINISTRADOR,
      Papel.ALUNO,
      Papel.PAIS,
      Papel.COORDENADOR_PEDAGOGICO,
      Papel.TREINAMENTO,
      Papel.SECRETARIO,
    ];

    const papeis = [];
    if (this.props.role === Papel.GESTOR.value) {
      papeis.push(Papel.PROFESSOR);
      papeis.push(Papel.DIRETOR);
      papeis.push(Papel.SECRETARIO);
      papeis.push(Papel.COORDENADOR_PEDAGOGICO);
    }

    this.carregarConvites = this.carregarConvites.bind(this);
    this.cadastrarConvite = this.cadastrarConvite.bind(this);
    this.removerConvite = this.removerConvite.bind(this);
    this.confirmarRemocaoConvite = this.confirmarRemocaoConvite.bind(this);
    this.validarCampos = this.validarCampos.bind(this);

    this.convitesService = new ConvitesService();
    this.loginService = new LoginService();
    this.usuarioService = new UsuarioService();
    this.entesService = new EntesService();
    this.escolasService = new EscolasService();

    this.state = {
      papeis,

      showAlert: false,

      emailState: '',
      papelState: '',
      alunoState: '',

      email: '',

      showConfirmModal: false,
      buttonDisabled: false,

      papelSelecionado: '',
      conviteSelecionado: undefined,

      carregandoAlunos: false,
      carregandoProfessores: false,
      alunoSelecionado: null,
      professorSelecionado: null,
      alunos: [],
      professores: [],
      filhosSelecionados: [],

      escolas: [],
      escolasSelecionadas: [],

      erro: null,
      carregando: true,

      dadosTabela: {
        header: [['Email', '60%'], ['Papel', '30%']],
        columnAlign: ['text-left', 'text-left'],
        keys: ['email', 'papelStr'],
        rows: [],
        total: undefined,
      },
    };
  }

  componentWillUnmount() {
    this.convitesService.abortPedingRequests();
    this.usuarioService.abortPedingRequests();
    this.entesService.abortPedingRequests();
    this.escolasService.abortPedingRequests();
    this.umounted = true;
  }

  async componentDidMount() {
    this.debouncedFunction = _.debounce((text) => {
      if (this.state.papelSelecionado.value === Papel.PAIS.value
        || this.state.papelSelecionado.value === Papel.ALUNO.value) {
        if (text === '') {
          this.setState({ carregandoAlunos: false, alunos: [] });
        } else {
          this.carregarAlunos(text);
        }
      } else if (this.state.papelSelecionado.value === Papel.DIRETOR.value
        || this.state.papelSelecionado.value === Papel.SECRETARIO.value
        || this.state.papelSelecionado.value === Papel.COORDENADOR_PEDAGOGICO.value) {
        if (text === '') {
          this.setState({ carregandoEscolas: false, escolas: [] });
        } else {
          this.carregarEscolas(text);
        }
      } else if (this.state.papelSelecionado.value === Papel.TREINAMENTO.value) {
        if (text === '') {
          this.setState({ carregandoProfessores: false, professores: [] });
        } else {
          this.carregarProfessores(text);
        }
      }
    }, 1500);

    try {
      await this.loginService.verificarPapel(this.props.role);

      const dadosEnte = await this.entesService.carregarDadosEnte();
      if (dadosEnte.acessoAlunos) {
        this.setState({ papeis: this.state.papeis.concat([Papel.ALUNO]) });
      }
      if (dadosEnte.acessoPais) {
        this.setState({ papeis: this.state.papeis.concat([Papel.PAIS]) });
      }
      if (dadosEnte.acessoAlunos && dadosEnte.turmaVirtual) {
        this.setState({ papeis: this.state.papeis.concat([Papel.TREINAMENTO]) });
      }

      this.carregarConvites(this.paginaSelecionada, this.linhasPorPagina, '');

      this.setState({ dadosEnte });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  validarCampos() {
    let ret = true;
    if (this.state.papelSelecionado.value !== Papel.TREINAMENTO.value
        && !validator.isEmail(this.state.email)) {
      this.setState({ emailState: 'has-danger' });
      ret = false;
    }
    if (!this.state.papelSelecionado) {
      this.setState({ papelState: 'danger' });
      ret = false;
    }
    if (this.state.papelSelecionado.value === Papel.ALUNO.value
      && !this.state.alunoSelecionado) {
      this.setState({ alunoState: 'danger' });
      ret = false;
    }
    if (this.state.papelSelecionado.value === Papel.TREINAMENTO.value
      && !this.state.professorSelecionado) {
      this.setState({ professorState: 'danger' });
      ret = false;
    }
    if (this.state.papelSelecionado.value === Papel.PAIS.value
      && (this.state.filhosSelecionados === null || this.state.filhosSelecionados.length === 0)) {
      this.setState({ filhosState: 'danger' });
      ret = false;
    }
    if ((this.state.papelSelecionado.value === Papel.DIRETOR.value
      || this.state.papelSelecionado.value === Papel.SECRETARIO.value
      || this.state.papelSelecionado.value === Papel.COORDENADOR_PEDAGOGICO.value)
      && (this.state.escolasSelecionadas === null || this.state.escolasSelecionadas.length === 0)) {
      this.setState({ escolasState: 'danger' });
      ret = false;
    }

    return ret;
  }

  async carregarConvites(pagina, linhasPorPagina, like) {
    try {
      const data = await this.convitesService
        .carregarConvitesDiretoresProfessoresCoordenadores(pagina, linhasPorPagina, like);

      data.convites.forEach((convite) => {
        Object.assign(convite, { papelStr: this.papeis2[convite.papel].label });
      });
      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela,
          { rows: data.convites, total: data.total }),
        carregando: false,
      });
    } catch (e) {
      if (this.umounted) return;
      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela, { total: -1 }),
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao buscar convites cadastrados',
      });
    }
    this.limparFormulario();
  }

  async cadastrarConvite(e) {
    e.preventDefault();

    if (this.state.buttonDisabled) return;
    if (!this.validarCampos()) return;

    this.setState({ buttonDisabled: true, showAlert: false });

    const convite = {
      email: this.state.email || this.state.professorSelecionado.email,
      papel: this.state.papelSelecionado.value,
      aluno: this.state.alunoSelecionado
        ? { id: this.state.alunoSelecionado.value } : undefined,
      professor: this.state.professorSelecionado
        ? { id: this.state.professorSelecionado.value } : undefined,
      filhos: this.state.filhosSelecionados
        ? this.state.filhosSelecionados.map((filho) => filho.value).toString() : undefined,
      escolas: this.state.escolasSelecionadas
        ? this.state.escolasSelecionadas.map((escola) => escola.value).toString() : undefined,
    };

    try {
      await this.convitesService
        .cadastrarConviteDiretorProfessorCoordenador(convite);

      this.limparFormulario();

      this.setState({
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Convite enviado com sucesso',
      });

      this.carregarConvites(this.paginaSelecionada, this.linhasPorPagina, this.like);
    } catch (msg) {
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao enviar convite',
      });
    }
  }

  confirmarRemocaoConvite(convite) {
    this.limparFormulario();
    this.setState({ conviteSelecionado: convite, showConfirmModal: true });
  }

  async removerConvite() {
    this.setState({ showAlert: false, buttonDisabled: true });

    try {
      await this.convitesService
        .removerConvite(this.state.conviteSelecionado.id);

      if (this.state.dadosTabela.total % this.linhasPorPagina === 1
        && this.paginaSelecionada > 1) {
        this.paginaSelecionada -= 1;
      }
      this.setState({
        showAlert: true,
        buttonDisabled: false,
        alertColor: 'success',
        alertMsg: 'Convite removido com sucesso',
      });
      this.carregarConvites(this.paginaSelecionada, this.linhasPorPagina, this.like);
    } catch (e) {
      this.setState({
        showAlert: true,
        buttonDisabled: false,
        alertColor: 'danger',
        alertMsg: 'Erro ao remover convite',
      });
    }
  }

  async carregarAlunos(like) {
    try {
      this.setState({ carregandoAlunos: true });

      let data;
      if (this.state.dadosEnte.alunosPorEscola && (this.props.role === Papel.DIRETOR.value
        || this.props.role === Papel.SECRETARIO.value)) {
        data = await this.usuarioService
          .carregarUsuariosVinculadosDiretor(Papel.ALUNO.value, 1, 9999999, like);
      } else {
        data = await this.usuarioService
          .carregarUsuarios(Papel.ALUNO.value, 1, 9999999, like);
      }

      const selectAlunos = [];
      data.usuarios.forEach((aluno) => {
        selectAlunos.push({ value: aluno.id, label: `${aluno.nome} (Mãe: ${aluno.nomeMae})` });
      });

      this.setState({
        carregandoAlunos: false,
        alunos: selectAlunos,
      });
    } catch (e) {
      if (this.umounted) return;
      this.setState({
        carregandoAlunos: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao buscar alunos cadastrados',
      });
    }
  }

  async carregarProfessores(like) {
    try {
      this.setState({ carregandoProfessores: true });

      const data = await this.usuarioService
        .carregarUsuarios(Papel.PROFESSOR.value, 1, 9999999, like);

      const selectProfessores = [];
      data.usuarios.forEach((professor) => {
        selectProfessores.push({
          value: professor.id,
          label: professor.nome,
          email: professor.credenciais.email,
        });
      });

      this.setState({
        carregandoProfessores: false,
        professores: selectProfessores,
      });
    } catch (e) {
      if (this.umounted) return;
      this.setState({
        carregandoProfessores: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao buscar professores cadastrados',
      });
    }
  }

  async carregarEscolas(like) {
    try {
      this.setState({ carregandoEscolas: true });

      const data = await this.escolasService
        .carregarEscolas(1, 9999999, like);

      const selectEscolas = [];
      data.escolas.forEach((escola) => {
        selectEscolas.push({ value: escola.id, label: escola.nome });
      });

      this.setState({
        carregandoEscolas: false,
        escolas: selectEscolas,
      });
    } catch (e) {
      if (this.umounted) return;
      this.setState({
        carregandoEscolas: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao buscar escolas cadastradas',
      });
    }
  }

  limparFormulario() {
    this.setState({
      emailState: '',
      papelState: '',
      alunoState: '',

      email: '',

      showConfirmModal: false,
      buttonDisabled: false,
      conviteSelecionado: undefined,

      carregandoAlunos: false,
      professorSelecionado: null,
      alunoSelecionado: null,
      alunos: [],
      professores: [],

      papelSelecionado: '',
    });
  }

  conteudoPagina() {
    return (this.state.carregando ? <Card>
      <div align="center" style={{ margin: 50 }}>
        <Loader
          type="Oval"
          color="#053d7c"
          height="50"
          width="50" />
      </div>
    </Card> : <>
      <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem active>Convites</BreadcrumbItem>
          </Breadcrumb>
          <Card>
            <CardBody>
              <Tabs>
                <TabList>
                  <Tab>Enviar convite</Tab>
                  <Tab>Convites enviados</Tab>
                </TabList>
                <TabPanel>
                  <br />
                  <Form onSubmit={this.cadastrarConvite}>
                    <Row>
                      <Col md="5">
                        <FormGroup className={`has-label ${this.state.emailState}`}>
                          <label>Email *</label>
                          <Input
                            disabled={this.state.papelSelecionado.value === Papel.TREINAMENTO.value}
                            value={this.state.email}
                            type="text"
                            maxLength="200"
                            onChange={(evt) => this.setState({
                              email: evt.target.value,
                              emailState: '',
                            })}
                          />
                          {this.state.emailState === 'has-danger' ? (
                            <label className="error">
                              Informe um email válido.
                            </label>
                          ) : null}
                        </FormGroup>
                      </Col>
                      <Col md="3">
                        <FormGroup className={'has-label has-danger'}>
                          <label>Papel *</label>
                          <Select
                            noOptionsMessage={() => 'Nenhuma entrada'}
                            className={`react-select primary ${this.state.papelState}`}
                            classNamePrefix="react-select"
                            value={this.state.papelSelecionado}
                            onChange={(event) => {
                              this.setState({ papelState: 'primary', papelSelecionado: event });
                              if (event.value === Papel.TREINAMENTO.value) {
                                this.setState({ email: '' });
                              }
                            }}
                            options={this.state.papeis}
                            placeholder="Função..."
                          />
                          {this.state.papelState === 'danger' ? (
                            <label className="error">
                              Informe um papel.
                            </label>
                          ) : null}
                        </FormGroup>
                      </Col>
                      {
                        this.state.papelSelecionado.value === Papel.ALUNO.value
                        && <Col md="4">
                          <FormGroup className={'has-label has-danger'}>
                            <label>Aluno *</label>
                            <Select
                              isLoading={this.state.carregandoAlunos}
                              loadingMessage={() => 'Carregando...'}
                              noOptionsMessage={() => 'Nenhuma entrada'}
                              className={`react-select primary ${this.state.alunoState}`}
                              classNamePrefix="react-select"
                              value={this.state.alunoSelecionado}
                              onInputChange={(text) => {
                                this.setState({ carregandoAlunos: text !== '', alunoState: 'primary' });
                                this.debouncedFunction(text);
                              }}
                              onChange={(event) => {
                                this.setState({ alunoState: 'primary', alunoSelecionado: event });
                              }}
                              options={this.state.alunos}
                              placeholder="Digite o nome de um aluno..."
                            />
                            {this.state.alunoState === 'danger' ? (
                              <label className="error">
                                Informe o nome de um aluno.
                              </label>
                            ) : <label></label>}
                          </FormGroup>
                        </Col>
                      }
                      {
                        this.state.papelSelecionado.value === Papel.TREINAMENTO.value && <Col md="4">
                          <FormGroup className={'has-label has-danger'}>
                            <label>Professor *</label>
                            <Select
                              isLoading={this.state.carregandoProfessores}
                              loadingMessage={() => 'Carregando...'}
                              noOptionsMessage={() => 'Nenhuma entrada'}
                              className={`react-select primary ${this.state.professorState}`}
                              classNamePrefix="react-select"
                              value={this.state.professorSelecionado}
                              onInputChange={(text) => {
                                this.setState({
                                  carregandoProfessores: text !== '',
                                  professorState: 'primary',
                                });
                                this.debouncedFunction(text);
                              }}
                              onChange={(event) => {
                                this.setState({ professorState: 'primary', professorSelecionado: event });
                              }}
                              options={this.state.professores}
                              placeholder="Digite o nome de um professor..."
                            />
                            {this.state.professorState === 'danger' ? (
                              <label className="error">
                                Informe o nome de um professor.
                              </label>
                            ) : <label></label>}
                          </FormGroup>
                        </Col>
                      }
                    </Row>
                    {this.state.papelSelecionado.value === Papel.PAIS.value
                      && <Row>
                        <Col md="12">
                          <FormGroup className={'has-label has-danger'}>
                            <label>Filhos *</label>
                            <Select
                              isLoading={this.state.carregandoAlunos}
                              loadingMessage={() => 'Carregando...'}
                              noOptionsMessage={() => 'Nenhuma entrada'}
                              className={`react-select primary ${this.state.filhosState}`}
                              classNamePrefix="react-select"
                              placeholder="Filhos..."
                              closeMenuOnSelect={false}
                              isMulti
                              value={this.state.filhosSelecionados}
                              onInputChange={(text) => {
                                this.setState({ carregandoAlunos: text !== '', filhosState: 'primary' });
                                this.debouncedFunction(text);
                              }}
                              onChange={(value) => this.setState({
                                filhosState: '',
                                filhosSelecionados: value,
                                alunos: [],
                              })}
                              options={this.state.alunos}
                            />
                            {this.state.filhosState === 'danger' ? (
                              <label className="error">
                                Informe ao menos um filho.
                              </label>
                            ) : null}
                          </FormGroup>
                        </Col>
                      </Row>
                    }
                    {(this.state.papelSelecionado.value === Papel.DIRETOR.value
                      || this.state.papelSelecionado.value === Papel.SECRETARIO.value
                      || this.state.papelSelecionado.value === Papel.COORDENADOR_PEDAGOGICO.value)
                      && <Row>
                        <Col md="12">
                          <FormGroup className={'has-label has-danger'}>
                            <label>Escolas *</label>
                            <Select
                              isLoading={this.state.carregandoEscolas}
                              loadingMessage={() => 'Carregando...'}
                              noOptionsMessage={() => 'Nenhuma entrada'}
                              className={`react-select primary ${this.state.escolasState}`}
                              classNamePrefix="react-select"
                              placeholder="Escolas..."
                              closeMenuOnSelect={false}
                              isMulti
                              value={this.state.escolasSelecionadas}
                              onInputChange={(text) => {
                                this.setState({ carregandoEscolas: text !== '', escolasState: 'primary' });
                                this.debouncedFunction(text);
                              }}
                              onChange={(value) => this.setState({
                                escolasState: '',
                                escolasSelecionadas: value,
                                escolas: [],
                              })}
                              options={this.state.escolas}
                            />
                            {this.state.escolasState === 'danger' ? (
                              <label className="error">
                                Informe ao menos uma escola.
                              </label>
                            ) : null}
                          </FormGroup>
                        </Col>
                      </Row>
                    }
                    <Row>
                      <Col md="12">
                        <div className="category form-category">
                          * Campos obrigatórios
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="text-right">
                        <Button type="submit" color="primary" disabled={this.state.buttonDisabled}>
                          Enviar
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </TabPanel>
                <TabPanel>
                  <br />
                  <AdaptiveTable
                    selectedPage={this.paginaSelecionada}
                    rowsPerPage={this.linhasPorPagina}
                    disablePrintAction
                    removeButtonCallback={(convite) => this.confirmarRemocaoConvite(convite)}
                    rowsPerPageCallback={(info) => {
                      this.paginaSelecionada = 1;
                      sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                      this.linhasPorPagina = info.rowsPerPage;
                      sessionStorage.setItem(`${this.idPagina}-ln`, this.linhasPorPagina);
                      this.carregarConvites(this.paginaSelecionada, info.rowsPerPage, this.like);
                    }}
                    likeCallback={(text) => {
                      this.like = text;
                      this.paginaSelecionada = 1;
                      sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                      this.carregarConvites(this.paginaSelecionada, this.linhasPorPagina, text);
                    }}
                    paginatorCallback={(page) => {
                      this.paginaSelecionada = page;
                      sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                      this.carregarConvites(this.paginaSelecionada,
                        this.linhasPorPagina, this.like);
                    }}
                    data={this.state.dadosTabela}
                    disableEditAction={true} />
                </TabPanel>
              </Tabs>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <ConfirmModal
        isOpen={this.state.showConfirmModal}
        callback={(confirm) => {
          this.setState({ showConfirmModal: false });
          if (confirm) {
            this.removerConvite();
          }
        }}
        text='Confirme exclusão do convite' />
      <LoaderModal
        isOpen={this.state.buttonDisabled}
        text='Enviando convite...' />
    </>
    );
  }

  render() {
    return (
      <div className="content">
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
          </div>
          </Card>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ dadosUsuario: state.dadosUsuario });

export default connect(mapStateToProps)(ConvitesDiretores);
