/* eslint-disable max-len */
import React from 'react';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';

// reactstrap components
import {
  Row,
  Col,
  Card,
  CardBody,
  CardFooter,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  FormGroup,
  Input,
} from 'reactstrap';

import {
  Tabs,
  TabList,
  Tab,
  TabPanel,
} from 'react-tabs';

import Loader from 'react-loader-spinner';
import TextareaAutosize from 'react-textarea-autosize';

import Alert from 'views/components/Alert';
import AdaptiveTable from 'views/components/AdaptiveTable';

import LoginService from 'services/LoginService';
import AnosLetivosService from 'services/AnosLetivosService';
import EscolasService from 'services/EscolasService';
import TurmasService from 'services/TurmasService';
import DiariosService from 'services/DiariosService';
import MateriasService from 'services/MateriasService';
import TarefasService from 'services/TarefasService';

import Turno from 'assets/csv/turnos.json';
import TipoAvaliacao from 'assets/csv/tipos-avaliacao.json';

class Tarefa extends React.Component {
  constructor(props) {
    super(props);

    this.idPagina = `${this.props.dadosUsuario.idVinculo}-alunos-que-fizeram-envios-${this.props.match.params.idTarefa}`;
    this.paginaSelecionada = sessionStorage.getItem(`${this.idPagina}-pg`) || 1;
    this.linhasPorPagina = sessionStorage.getItem(`${this.idPagina}-ln`) || 5;
    this.like = '';

    this.idPagina2 = `${this.props.dadosUsuario.idVinculo}-alunos-que-nao-fizeram-envios-${this.props.match.params.idTarefa}`;
    this.paginaSelecionada2 = sessionStorage.getItem(`${this.idPagina}-pg`) || 1;
    this.linhasPorPagina2 = sessionStorage.getItem(`${this.idPagina}-ln`) || 5;
    this.like2 = '';

    this.turnos = [
      Turno.MATUTINO,
      Turno.VESPERTINO,
      Turno.NOTURNO,
      Turno.INTEGRAL,
    ];

    this.loginService = new LoginService();
    this.anosLetivosService = new AnosLetivosService();
    this.escolasService = new EscolasService();
    this.turmasService = new TurmasService();
    this.diariosService = new DiariosService();
    this.materiasService = new MateriasService();
    this.tarefasService = new TarefasService();

    this.state = {
      showAlert: false,
      erro: false,

      dadosTabela: {
        header: [['Nome', '90%']],
        columnAlign: ['text-left'],
        keys: ['nome'],
        rows: [],
        total: undefined,
      },
      dadosTabela2: {
        header: [['Nome', '90%']],
        columnAlign: ['text-left'],
        keys: ['nome'],
        rows: [],
        total: undefined,
      },
    };
  }

  componentWillUnmount() {
    this.anosLetivosService.abortPedingRequests();
    this.escolasService.abortPedingRequests();
    this.turmasService.abortPedingRequests();
    this.diariosService.abortPedingRequests();
    this.tarefasService.abortPedingRequests();
    this.umounted = true;
  }

  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);

    try {
      const anoLetivo = this.props.match.params.idAnoLetivo
        ? await this.anosLetivosService.carregarAnoLetivo(this.props.match.params.idAnoLetivo) : null;

      const escola = this.props.match.params.idAnoLetivo
        ? await this.escolasService.carregarEscola(this.props.match.params.idEscola) : null;

      const turma = this.props.match.params.idAnoLetivo
        ? await this.turmasService.carregarTurma(this.props.match.params.idTurma) : null;

      const diario = await this.diariosService
        .carregarDiario(this.props.match.params.idDiario);

      const materia = await this.materiasService
        .carregarMateria(this.props.match.params.idMateria);

      const tarefa = await this.tarefasService
        .carregarTarefa(this.props.match.params.idTarefa);

      this.carregarAlunosQueEnviaramTarefa(diario.idTurma, this.paginaSelecionada, this.linhasPorPagina, this.like);

      this.carregarAlunosQueNaoEnviaramTarefa(diario.idTurma, this.paginaSelecionada2,
        this.linhasPorPagina2, this.like2);

      this.setState({
        anoLetivo,
        escola,
        turma,
        diario,
        materia,
        tarefa,
      });

      this.setState({ ready: true });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  async carregarAlunosQueEnviaramTarefa(idTurma, pagina, linhasPorPagina, like) {
    try {
      const data = await this.tarefasService
        .carregarAlunosQueEnviaramTarefa(this.props.match.params.idDiario,
          this.props.match.params.idTarefa, idTurma,
          pagina, linhasPorPagina, like);

      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela,
          { rows: data.alunos, total: data.total }),
      });
    } catch (e) {
      if (this.umounted) return;
      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela, { total: -1 }),
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao carregar alunos que enviaram tarefas',
      });
    }
  }

  async carregarAlunosQueNaoEnviaramTarefa(idTurma, pagina, linhasPorPagina, like) {
    try {
      const data = await this.tarefasService
        .carregarAlunosQueNaoEnviaramTarefa(this.props.match.params.idDiario,
          this.props.match.params.idTarefa, idTurma,
          pagina, linhasPorPagina, like);

      this.setState({
        dadosTabela2: Object.assign(this.state.dadosTabela2,
          { rows: data.alunos, total: data.total }),
      });
    } catch (e) {
      if (this.umounted) return;
      this.setState({
        dadosTabela2: Object.assign(this.state.dadosTabela2, { total: -1 }),
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao carregar alunos que não enviaram tarefas',
      });
    }
  }

  voltar() {
    if (this.state.turma.tipoAvaliacao === TipoAvaliacao.NOTA.value) {
      return `${this.props.layout}`
        + `/anosletivos/${this.props.match.params.idAnoLetivo}`
        + `/escola/${this.state.escola.id}`
        + `/turma/${this.state.turma.id}`
        + `/diario/${this.state.diario.id}`;
    }
    return `${this.props.layout}`
      + `/anosletivos/${this.props.match.params.idAnoLetivo}`
      + `/escola/${this.state.escola.id}`
      + `/turma/${this.state.turma.id}`
      + `/diario/${this.state.diario.id}/alfabetizacao`;
  }

  alunoSelecionado(aluno) {
    if (this.props.match.params.idAnoLetivo) {
      this.props.history.push(`${this.props.layout}`
        + `/anosletivos/${this.props.match.params.idAnoLetivo}`
        + `/escola/${this.state.escola.id}`
        + `/turma/${this.state.turma.id}`
        + `/diario/${this.state.diario.id}`
        + `/materia/${this.props.match.params.idMateria}/turmavirtual`
        + `/tarefa/${this.props.match.params.idTarefa}`
        + `/envios/aluno/${aluno.id}`);
    } else {
      this.props.history.push(`${this.props.layout}/ava/turmasvirtuais`
        + `/turma/${this.props.match.params.idTurma}`
        + `/diario/${this.props.match.params.idDiario}`
        + `/materia/${this.props.match.params.idMateria}`
        + `/tarefa/${this.props.match.params.idTarefa}`
        + `/envios/aluno/${aluno.id}`);
    }
  }

  conteudoPagina() {
    return this.state.ready ? <>
      <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            {
              this.state.anoLetivo ? <>
                <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos`}>Anos letivos</Link></BreadcrumbItem>
                <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`}>Ano letivo {this.state.anoLetivo.ano}</Link></BreadcrumbItem>
                <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}`}>{this.state.escola.nome}</Link></BreadcrumbItem>
                <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/${this.state.turma.id}`}>{this.state.turma.nome} - {this.turnos[this.state.turma.turno].label}</Link></BreadcrumbItem>
                <BreadcrumbItem><Link to={this.voltar()}>{this.state.diario.nome}</Link></BreadcrumbItem>
                <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/${this.state.turma.id}/diario/${this.state.diario.id}/materia/${this.props.match.params.idMateria}/turmavirtual`}>Turma virtual</Link></BreadcrumbItem>
                <BreadcrumbItem active>Tarefa</BreadcrumbItem>
              </> : <>
                <BreadcrumbItem><Link to={`${this.props.layout}/ava`}>Ambiente Virtual</Link></BreadcrumbItem>
                <BreadcrumbItem><Link to={`${this.props.layout}/ava/turmasvirtuais`}>Turmas Virtuais</Link></BreadcrumbItem>
                <BreadcrumbItem><Link to={`${this.props.layout}/ava/turmasvirtuais/turma/${this.props.match.params.idTurma}/diario/${this.props.match.params.idDiario}/materia/${this.props.match.params.idMateria}`}>{this.state.diario.ano} - {this.state.diario.nome} - {this.state.diario.nomeTurma} - {this.state.diario.escola} - {this.state.materia.nome}</Link></BreadcrumbItem>
                <BreadcrumbItem active>Tarefa</BreadcrumbItem>
              </>
            }
          </Breadcrumb>
          <Card>
            <CardBody>
              <Tabs>
                <TabList>
                  <Tab>Informações da Tarefa</Tab>
                  <Tab>Alunos que realizaram envio</Tab>
                  <Tab>Alunos que não realizaram envio</Tab>
                </TabList>
                <TabPanel>
                  <Row>
                    <Col md="8">
                      <FormGroup>
                        <label>Nome *</label>
                        <Input
                          disabled
                          type="text"
                          value={this.state.tarefa.nome}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="2">
                      <FormGroup>
                        <label>Data de início *</label>
                        <Input
                          disabled
                          type="text"
                          value={this.state.tarefa.dataInicio}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="2">
                      <FormGroup>
                        <label>Data de fim *</label>
                        <Input
                          disabled
                          type="text"
                          value={this.state.tarefa.dataFim}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <label>Descrição *</label>
                        <br />
                        <TextareaAutosize
                          className="autoheight"
                          disabled
                          value={this.state.tarefa.descricao} />
                      </FormGroup>
                    </Col>
                  </Row>
                </TabPanel>
                <TabPanel>
                  <br />
                  <AdaptiveTable
                    selectedPage={this.paginaSelecionada}
                    rowsPerPage={this.linhasPorPagina}
                    disableActions
                    clickRows
                    rowsPerPageCallback={(info) => {
                      this.paginaSelecionada = 1;
                      sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                      this.linhasPorPagina = info.rowsPerPage;
                      sessionStorage.setItem(`${this.idPagina}-ln`, this.linhasPorPagina);
                      this.carregarAlunosQueEnviaramTarefa(
                        this.state.diario.idTurma, this.paginaSelecionada, info.rowsPerPage, this.like,
                      );
                    }}
                    likeCallback={(text) => {
                      this.like = text;
                      this.paginaSelecionada = 1;
                      sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                      this.carregarAlunosQueEnviaramTarefa(
                        this.state.diario.idTurma, this.paginaSelecionada, this.linhasPorPagina, text,
                      );
                    }}
                    paginatorCallback={(page) => {
                      this.paginaSelecionada = page;
                      sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                      this.carregarAlunosQueEnviaramTarefa(
                        this.state.diario.idTurma, this.paginaSelecionada, this.linhasPorPagina, this.like,
                      );
                    }}
                    rowCallback={(aluno) => this.alunoSelecionado(aluno)}
                    data={this.state.dadosTabela} />
                </TabPanel>
                <TabPanel>
                  <br />
                  <AdaptiveTable
                    selectedPage={this.paginaSelecionada2}
                    rowsPerPage={this.linhasPorPagina2}
                    disableActions
                    rowsPerPageCallback={(info) => {
                      this.paginaSelecionada2 = 1;
                      sessionStorage.setItem(`${this.idPagina2}-pg`, this.paginaSelecionada2);
                      this.linhasPorPagina2 = info.rowsPerPage;
                      sessionStorage.setItem(`${this.idPagina2}-ln`, this.linhasPorPagina2);
                      this.carregarAlunosQueNaoEnviaramTarefa(
                        this.state.diario.idTurma, this.paginaSelecionada2, info.rowsPerPage, this.like2,
                      );
                    }}
                    likeCallback={(text) => {
                      this.like2 = text;
                      this.paginaSelecionada2 = 1;
                      sessionStorage.setItem(`${this.idPagina2}-pg`, this.paginaSelecionada2);
                      this.carregarAlunosQueNaoEnviaramTarefa(
                        this.state.diario.idTurma, this.paginaSelecionada2, this.linhasPorPagina2, text,
                      );
                    }}
                    paginatorCallback={(page) => {
                      this.paginaSelecionada2 = page;
                      sessionStorage.setItem(`${this.idPagina2}-pg`, this.paginaSelecionada2);
                      this.carregarAlunosQueNaoEnviaramTarefa(
                        this.state.diario.idTurma, this.paginaSelecionada2, this.linhasPorPagina2, this.like2,
                      );
                    }}
                    data={this.state.dadosTabela2} />
                </TabPanel>
              </Tabs>
            </CardBody>
            <CardFooter>
              <Row>
                <Col md="6" className="text-left">
                  <Button color="primary"
                    onClick={() => this.props.history.goBack()}>
                    Voltar
                  </Button>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </> : <Card>
      <div align="center" style={{ margin: 50 }}>
        <Loader
          type="Oval"
          color="#053d7c"
          height="50"
          width="50" />
      </div>
    </Card>;
  }

  render() {
    return (
      <div className='content'>
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ dadosUsuario: state.dadosUsuario });

export default connect(mapStateToProps)(Tarefa);
