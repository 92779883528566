import React from 'react';
import _ from 'lodash';

import {
  Row,
  Col,
  Table,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
  UncontrolledTooltip,
  Label,
} from 'reactstrap';

import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';

import Loader from 'react-loader-spinner';
import Select from 'react-select';

import Paginator from './Paginator';

class AdaptiveTable extends React.Component {
  constructor(props) {
    super(props);
    this.content = this.content.bind(this);

    this.options = [
      { value: 5, label: '5 Linhas' },
      { value: 10, label: '10 Linhas' },
      { value: 20, label: '20 Linhas' },
      { value: 30, label: '30 Linhas' },
    ];

    const selectedOption = this.options.find((option) => option.value
      === parseInt(this.props.rowsPerPage, 10));

    this.state = {
      rowsPerPage: selectedOption || { value: 5, label: '5 Linhas' },
      data: this.props.data,
      disableActions: this.props.disableActions,
      showCheckBox: this.props.showCheckBox,
      showCheckBox2: this.props.showCheckBox2,
      disablePrintAction: this.props.disablePrintAction,
      disableEditAction: this.props.disableEditAction,
      disableRemoveAction: this.props.disableRemoveAction,
      customActions: this.props.customActions,
      printTooltip: this.props.printTooltip ? this.props.printTooltip : 'Imprimir',
      clickRows: this.props.clickRows,
    };

    this.paginatorCallback = this.paginatorCallback.bind(this);
  }

  componentDidMount() {
    this.debouncedFunction = _.debounce((text) => {
      this.setState({
        data: { ...this.state.data, loading: true },
      });
      this.props.likeCallback(text.trim());
    }, 1500);
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.data !== nextProps.data) {
      this.setState({ data: nextProps.data });
    }
    if (this.state.clickRows !== nextProps.clickRows) {
      this.setState({ clickRows: nextProps.clickRows });
    }
    if (this.state.selectedPage !== nextProps.selectedPage) {
      this.setState({ selectedPage: nextProps.selectedPage });
    }
  }

  paginatorCallback(page) {
    this.setState({
      data: { ...this.state.data, loading: true },
    });
    this.props.paginatorCallback(page);
  }

  content() {
    if (this.state.data.total === undefined || this.state.data.loading) {
      return (
        <div align="center" style={{ padding: 30 }}>
          <Loader
            type="Oval"
            color="#053d7c"
            height="50"
            width="50" />
        </div>
      );
    } if (this.state.data.total === -1) {
      return (<div align="center" style={{ marginTop: 20, marginBottom: 20 }}>Falha ao obter dados</div>);
    } if (this.state.data.total === 0) {
      return (<div align="center" style={{ marginTop: 20, marginBottom: 20 }}>Nenhum dado cadastrado</div>);
    }
    return (
      <div>
        <PerfectScrollbar>
        <Table hover>
          <thead className="text-primary">
            <tr>
              {
                this.state.data.header.map(
                  (content, index) => (
                    <th style={{ width: content[1] }} key={index}
                      className={this.state.data.columnAlign[index]}>{content[0]}</th>
                  ),
                )
              }
              {!this.state.disableActions
                && <th className="text-right">Ações</th>
              }
              {this.state.showCheckBox
                && <th className="text-center">{this.props.checkBoxTitle}</th>
              }
              {this.state.showCheckBox2
                && <th className="text-center">{this.props.checkBoxTitle2}</th>
              }
            </tr>
          </thead>
          <tbody>
            {
              this.state.data.rows.map(
                (row, index) => (
                  <tr key={index}
                    className={this.state.clickRows ? 'tr-clickable' : null}
                    onClick={this.state.clickRows ? (evt) => {
                      evt.stopPropagation(); this.props.rowCallback(row);
                    } : null}>
                    {
                      this.state.data.keys.map(
                        (key, index2) => (
                          <td
                            key={index2}
                            className={this.state.data.columnAlign[index2]}>{row[key]}
                          </td>
                        ),
                      )
                    }
                    {!this.state.disableActions
                      && <td className="td-actions text-right">
                        <div>
                          {!this.state.disablePrintAction
                            && <>
                              <Button
                                id={`btnImprimirItem${index}`}
                                className="btn-icon"
                                color="primary"
                                size="sm"
                                onClick={(evt) => {
                                  evt.stopPropagation();
                                  this.props.printButtonCallback(row);
                                }}
                                type="button">
                                <i className="fa fa-print" />
                              </Button>
                              <UncontrolledTooltip placement="bottom" target={`btnImprimirItem${index}`} delay={0}>
                                {this.state.printTooltip}
                              </UncontrolledTooltip>
                            </>}
                          {!this.state.disableEditAction
                            && <>
                              {' '}
                              <Button
                                id={`btnEditarItem${index}`}
                                className="btn-icon"
                                color="success"
                                size="sm"
                                onClick={(evt) => {
                                  evt.stopPropagation();
                                  this.props.editButtonCallback(row);
                                }}
                                type="button">
                                <i className="fa fa-edit" />
                              </Button>
                              <UncontrolledTooltip placement="bottom" target={`btnEditarItem${index}`} delay={0}>
                                Editar
                              </UncontrolledTooltip>
                            </>}
                          {!this.state.disableRemoveAction
                            && <>
                              {' '}
                              <Button
                                id={`btnRemoverItem${index}`}
                                className="btn-icon"
                                color="danger"
                                size="sm"
                                onClick={(evt) => {
                                  evt.stopPropagation();
                                  this.props.removeButtonCallback(row);
                                }}
                                type="button">
                                <i className="fa fa-times" />
                              </Button>
                              <UncontrolledTooltip placement="bottom" target={`btnRemoverItem${index}`} delay={0}>
                                Excluir
                              </UncontrolledTooltip>
                            </>}
                          {this.state.customActions
                            && this.state.customActions.map((customAction, idx) => <React.Fragment key={idx}>
                              {' '}
                              <Button
                                disabled={customAction.disabled(row)}
                                id={`btnCustomAction${idx}`}
                                className="btn-icon"
                                color={customAction.color}
                                size="sm"
                                onClick={(evt) => {
                                  evt.stopPropagation();
                                  customAction.callback(row);
                                }}
                                type="button">
                                <i className={customAction.icon} />
                              </Button>
                              <UncontrolledTooltip placement="bottom" target={`btnCustomAction${idx}`} delay={0}>
                                {customAction.tooltip}
                              </UncontrolledTooltip>
                            </React.Fragment>)
                          }
                        </div>
                      </td>
                    }
                    {
                      this.state.showCheckBox && <td className="text-center">
                        <FormGroup check>
                          <Label onClick={(e) => e.stopPropagation()} className="form-check-label">
                            <Input
                              className="form-check-input"
                              type="checkbox"
                              checked={row.checked}
                              onChange={(e) => {
                                this.props.checkBoxCallback(row, e.target.checked);
                              }} />
                            <span className="form-check-sign">
                              <span className="check"></span>
                            </span>
                          </Label>
                        </FormGroup>
                      </td>
                    }
                    {
                      this.state.showCheckBox2 && <td className="text-center">
                        <FormGroup check>
                          <Label onClick={(e) => e.stopPropagation()} className="form-check-label">
                            <Input
                              className="form-check-input"
                              type="checkbox"
                              checked={row.checked2}
                              onChange={(e) => {
                                this.props.checkBoxCallback2(row, e.target.checked);
                              }} />
                            <span className="form-check-sign">
                              <span className="check"></span>
                            </span>
                          </Label>
                        </FormGroup>
                      </td>
                    }
                  </tr>
                ),
              )
            }
          </tbody>
        </Table>
        <div className="col-md-4 offset-md-4" style={{ marginTop: 20 }}>
          <Paginator
            callback={this.paginatorCallback}
            selectedPage={this.props.selectedPage}
            rowsPerPage={this.state.rowsPerPage.value}
            total={this.state.data.total} />
        </div>
        </PerfectScrollbar>
      </div>
    );
  }

  render() {
    return (
      <>
        <Row>
          <Col md={8}>
            {
              !this.props.disableSearchField && <form>
                <FormGroup>
                  <InputGroup >
                    <Input placeholder="Buscar..." type="text" onChange={(event) => {
                      this.debouncedFunction(event.target.value);
                    }} />
                    <InputGroupAddon addonType="append">
                      <InputGroupText >
                        <i className="nc-icon nc-zoom-split" />
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                </FormGroup>
              </form>
            }
          </Col>
          <Col md={{ offset: 1, size: 3 }}>
            <Select
              noOptionsMessage={() => 'Nenhuma entrada'}
              className="react-select primary"
              classNamePrefix="react-select"
              value={this.state.rowsPerPage}
              onChange={(event) => {
                this.setState({
                  data: { ...this.state.data, loading: true },
                  rowsPerPage: event,
                });
                this.props.rowsPerPageCallback({
                  rowsPerPage: event.value, total: this.state.data.total,
                });
              }}
              options={this.options}
              placeholder="Número de linhas"
            />
          </Col>
        </Row>
        {this.content()}
      </>
    );
  }
}

export default AdaptiveTable;
