import React from 'react';
import { Link } from 'react-router-dom';

import {
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Button,
  Table,
  FormGroup,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from 'reactstrap';

import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';

import Select from 'react-select';
import Loader from 'react-loader-spinner';
import uniqid from 'uniqid';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';

import LoginService from 'services/LoginService';
import EntesService from 'services/EntesService';
import AnosLetivosService from 'services/AnosLetivosService';
import EscolasService from 'services/EscolasService';
import TurmasService from 'services/TurmasService';
import DiariosService from 'services/DiariosService';
import HabilidadesCompetenciasEspecificasService from 'services/HabilidadesCompetenciasEspecificasService';
import ObjetivosAprendizagemCampoExperienciaService from 'services/ObjetivosAprendizagemCampoExperienciaService';
import ProfessorAuxiliarService from 'services/ProfessorAuxiliarService';

import Turno from 'assets/csv/turnos.json';
import SituacaoHabilidade from 'assets/csv/situacoes-habilidades';
import SituacaoObjetivo from 'assets/csv/situacoes-objetivo';
import Papel from 'assets/csv/papeis.json';
import CicloEnsino from 'assets/csv/ciclos-ensino.json';
import Serie from 'assets/csv/series2.json';

class SituacoesHabilidades extends React.Component {
  constructor(props) {
    super(props);

    this.situacoes = [
      SituacaoHabilidade.TRABALHADA,
      SituacaoHabilidade.TRABALHADA_PARCIALMENTE,
      SituacaoHabilidade.NAO_TRABALHADA,
      SituacaoHabilidade.NAO_INFORMADA,
    ];

    this.state = {
      carregandoHabilidades: true,
      idDiario: props.idDiario,
      idMateria: props.idMateria,
    }

    this.entesService = new EntesService();
    this.professorAuxiliarService = new ProfessorAuxiliarService();

    this.salvarHabilidadesTrabalhadas = this.salvarHabilidadesTrabalhadas.bind(this);

    this.habilidadesCompetenciasEspecificasService = new
      HabilidadesCompetenciasEspecificasService();
  }

  async componentWillReceiveProps(nextProps) {
    if (this.state.idDiario !== nextProps.idDiario) {
      this.setState({ idDiario: nextProps.idDiario });
    }
    if (this.state.idMateria !== nextProps.idMateria) {
      this.setState({ idMateria: nextProps.idMateria });
    }
    await this.carregarSituacaoHabilidadeNoDiarioEMateria(
      nextProps.idDiario,
      nextProps.idMateria,
    );
  }

  async componentDidMount() {
    const dadosEnte = await this.entesService.carregarDadosEnte();

    const professoreAuxiliar = await this.professorAuxiliarService
        .carregarMeuProfessorAuxiliar();

    await this.carregarSituacaoHabilidadeNoDiarioEMateria(
      this.state.idDiario,
      this.state.idMateria,
    );

    this.setState({
      auxiliarBloqueado: this.props.role === Papel.PROFESSOR.value && professoreAuxiliar.auxiliar && dadosEnte.bloquearAlteracaoDiarioProfessorAuxiliar,
    })
  }

  async carregarSituacaoHabilidadeNoDiarioEMateria(idDiario, idMateria) {
    this.setState({ carregandoHabilidades: true });

    const habilidades = await this.habilidadesCompetenciasEspecificasService
      .carregarHabilidadesPorMateria(idMateria);

    const fn = (habilidade) => new Promise((resolve) => resolve(
      this.habilidadesCompetenciasEspecificasService
        .carregarSituacaoHabilidadeNoDiarioEMateria(habilidade.id,
          idDiario, idMateria),
    ));

    const actions = habilidades.map(fn);

    const situacoesHabilidades = await Promise.all(actions);

    this.setState({
      situacoesHabilidades,
      carregandoHabilidades: false,
    });
  }

  async salvarHabilidadesTrabalhadas() {
    this.props.callbackMessage({
      showAlert: false,
      showLoaderModal: true,
      buttonDisabled: true,
    });

    try {
      await this.habilidadesCompetenciasEspecificasService.salvarHabilidadesTrabalhadas(
        this.state.idDiario,
        this.state.idMateria,
        this.state.situacoesHabilidades,
      );

      await this.carregarSituacaoHabilidadeNoDiarioEMateria(
        this.state.idDiario,
        this.state.idMateria,
      );

      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Habilidades trabalhadas salvas com sucesso',
        buttonDisabled: false,
      });
    } catch (msg) {
      if (this.umounted) return;

      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao salvar habilidades trabalhadas',
        buttonDisabled: false,
      });
    }
  }

  render() {
    return (
      this.state.carregandoHabilidades
        ? <div align="center" style={{ padding: 30 }}>
          <Loader
            type="Oval"
            color="#053d7c"
            height="50"
            width="50" />
        </div>
        : <>
          <PerfectScrollbar className="sisa-perfect-scrollbar">
            <Table>
              <thead className="text-primary">
                <tr>
                  <th style={{ width: '80%' }}>Descrição</th>
                  <th className="text-center" style={{ width: '20%' }}>Situação</th>
                </tr>
              </thead>
              <tbody>
                {
                  this.state.situacoesHabilidades.map((situacaoHabilidade) => <tr
                    key={uniqid()}>
                    <td><div className="preserve-format">{situacaoHabilidade.habilidade.descricao}</div></td>
                    <td className="text-center">
                      <UncontrolledDropdown>
                        <DropdownToggle
                          aria-haspopup={true}
                          color={this.situacoes[situacaoHabilidade.situacao].color}
                          data-toggle="collapse"
                          id="acoesHabilidadesDropdownMenuLink"
                          onClick={(evt) => evt.stopPropagation()}
                          nav>
                          <Button color={this.situacoes[situacaoHabilidade.situacao].color}>
                            {this.situacoes[situacaoHabilidade.situacao].label} <b className="caret" />
                          </Button>
                        </DropdownToggle>
                        <DropdownMenu aria-labelledby="acoesHabilidadesDropdownMenuLink" right>
                          <DropdownItem
                            onClick={() => {
                              if (this.props.role !== Papel.PROFESSOR.value) return;
                              Object.assign(situacaoHabilidade, {
                                ...situacaoHabilidade,
                                situacao: SituacaoHabilidade.NAO_INFORMADA.value
                              });
                              this.forceUpdate();
                            }}>
                            {SituacaoHabilidade.NAO_INFORMADA.label}
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              if (this.props.role !== Papel.PROFESSOR.value) return;
                              Object.assign(situacaoHabilidade, {
                                ...situacaoHabilidade,
                                situacao: SituacaoHabilidade.TRABALHADA.value
                              });
                              this.forceUpdate();
                            }}>
                            {SituacaoHabilidade.TRABALHADA.label}
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              if (this.props.role !== Papel.PROFESSOR.value) return;
                              Object.assign(situacaoHabilidade, {
                                ...situacaoHabilidade,
                                situacao: SituacaoHabilidade.TRABALHADA_PARCIALMENTE.value
                              });
                              this.forceUpdate();
                            }}>
                            {SituacaoHabilidade.TRABALHADA_PARCIALMENTE.label}
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              if (this.props.role !== Papel.PROFESSOR.value) return;
                              Object.assign(situacaoHabilidade, {
                                ...situacaoHabilidade,
                                situacao: SituacaoHabilidade.NAO_TRABALHADA.value
                              });
                              this.forceUpdate();
                            }}>
                            {SituacaoHabilidade.NAO_TRABALHADA.label}
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </td>
                  </tr>)
                }
              </tbody>
            </Table>
          </PerfectScrollbar>
          <Row>
            <Col className="text-left">
              <Button
                color="primary"
                onClick={() => this.props.callbackVoltar()} >
                Voltar
              </Button>
            </Col>
            <Col className="text-right">
              {
                this.props.role === Papel.PROFESSOR.value && !this.state.auxiliarBloqueado && <Button
                  color="primary"
                  disabled={this.state.buttonDisabled}
                  onClick={this.salvarHabilidadesTrabalhadas} >
                  Salvar habilidades trabalhadas
                </Button>
              }
            </Col>
          </Row>
        </>
    );
  }
}

class SituacoesObjetivos extends React.Component {
  constructor(props) {
    super(props);

    this.situacoes = [
      SituacaoObjetivo.TRABALHADO,
      SituacaoObjetivo.TRABALHADO_PARCIALMENTE,
      SituacaoObjetivo.NAO_TRABALHADO,
      SituacaoObjetivo.NAO_INFORMADO,
    ];

    this.state = {
      carregandoObjetivos: true,
      idDiario: props.idDiario,
      idMateria: props.idMateria,
      serie: props.serie,
    }

    this.salvarObjetivosTrabalhados = this.salvarObjetivosTrabalhados.bind(this);

    this.entesService = new EntesService();
    this.professorAuxiliarService = new ProfessorAuxiliarService();
    this.objetivosAprendizagemCampoExperienciaService = new
      ObjetivosAprendizagemCampoExperienciaService();
  }

  async componentWillReceiveProps(nextProps) {
    if (this.state.idDiario !== nextProps.idDiario) {
      this.setState({ idDiario: nextProps.idDiario });
    }
    if (this.state.idMateria !== nextProps.idMateria) {
      this.setState({ idMateria: nextProps.idMateria });
    }
    if (this.state.serie !== nextProps.serie) {
      this.setState({ serie: nextProps.serie });
    }
    await this.carregarSituacaoObjetivoNoDiarioEMateria(
      nextProps.idDiario,
      nextProps.idMateria,
      nextProps.serie,
    );
  }

  async componentDidMount() {
    const dadosEnte = await this.entesService.carregarDadosEnte();

    const professoreAuxiliar = await this.professorAuxiliarService
        .carregarMeuProfessorAuxiliar();

    await this.carregarSituacaoObjetivoNoDiarioEMateria(
      this.state.idDiario,
      this.state.idMateria,
      this.state.serie,
    );

    this.setState({
      auxiliarBloqueado: this.props.role === Papel.PROFESSOR.value && professoreAuxiliar.auxiliar && dadosEnte.bloquearAlteracaoDiarioProfessorAuxiliar,
    })
  }

  async carregarSituacaoObjetivoNoDiarioEMateria(idDiario, idMateria, serie) {
    this.setState({ carregandoObjetivos: true });

    const objetivosAprendizagem = await this.objetivosAprendizagemCampoExperienciaService
      .carregarObjetivosAprendizagemPorMateria(idMateria);

    const fn = (objetivo) => new Promise((resolve) => resolve(
      this.objetivosAprendizagemCampoExperienciaService
        .carregarSituacaoObjetivoAprendizagemDiarioMateria(objetivo.id,
          idDiario, idMateria),
    ));

    for (const objetivoAprendizagem of objetivosAprendizagem) {
      const actions = objetivoAprendizagem.objetivosEspecificos.map(fn);

      const situacoesObjetivos = await Promise.all(actions);
      objetivoAprendizagem.objetivosEspecificos.forEach((objetivoEspecifico, idx) => {
        Object.assign(
          objetivoEspecifico, { situacao: situacoesObjetivos[idx] },
        );
      });
    }

    let anos1;
    let anos2;
    if (serie === Serie.BERCARIO_1.value || serie === Serie.BERCARIO_2.value) {
      anos1 = '0 anos';
      anos2 = '1 anos';
    } else if (serie === Serie.CRECHE_1.value || serie === Serie.CRECHE_2.value) {
      anos1 = '2 anos';
      anos2 = '3 anos';
    } else {
      anos1 = '4 anos';
      anos2 = '5 anos';
    }

    this.setState({
      objetivosAprendizagem,
      carregandoObjetivos: false,
      anos1,
      anos2,
    });
  }

  async salvarObjetivosTrabalhados() {
    this.props.callbackMessage({
      buttonDisabled: true,
      showAlert: false,
      showLoaderModal: true,
    });

    try {
      await this.objetivosAprendizagemCampoExperienciaService.salvarObjetivosAprendizagemTrabalhados(
        this.state.idDiario,
        this.state.idMateria,
        this.state.objetivosAprendizagem,
      );

      await this.carregarSituacaoObjetivoNoDiarioEMateria(
        this.state.idDiario,
        this.state.idMateria,
        this.state.serie,
      );

      this.props.callbackMessage({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Objetivos de aprendizagem salvos com sucesso',
      });
    } catch (msg) {
      if (this.umounted) return;
      this.props.callbackMessage({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao salvar objetivos de aprendizagem',
      });
    }
  }

  render() {
    return this.state.carregandoObjetivos
      ? <div align="center" style={{ padding: 30 }}>
        <Loader
          type="Oval"
          color="#053d7c"
          height="50"
          width="50" />
      </div>
      : <>
        <PerfectScrollbar className="sisa-perfect-scrollbar">
          <Table>
            <thead className="text-primary">
              <tr>
                <th rowSpan="2" className="text-center" style={{ width: '20%' }}>Objetivo de aprendizagem e desenvolvimento</th>
                <th colSpan="2" className="text-center" style={{ width: '60%' }}>Objetivo de específico</th>
                <th rowSpan="2" className="text-center" style={{ width: '20%' }}>Situação</th>
              </tr>
              <tr>
                <th className="text-center">{this.state.anos1}</th>
                <th className="text-center">{this.state.anos2}</th>
              </tr>
            </thead>
            <tbody>
              {
                this.state.objetivosAprendizagem.map((objetivoAprendizagem, index1) => <React.Fragment key={index1}>
                  {
                    objetivoAprendizagem.objetivosEspecificos.map((objetivoEspecifico, index2) => <tr key={index2}>
                      {
                        index2 === 0 && <td style={{ width: '20%' }} rowSpan={objetivoAprendizagem.objetivosEspecificos.length}>
                          <div className="preserve-format">{objetivoAprendizagem.descricao}</div>
                        </td>
                      }
                      <td style={{ width: '30%' }}>
                        <div className="preserve-format">{objetivoEspecifico.descricao}</div>
                      </td>
                      <td style={{ width: '30%' }}>
                        <div className="preserve-format">{objetivoEspecifico.descricao2}</div>
                      </td>
                      <td style={{ width: '20%' }} className="text-center">
                        <Button
                          color={this.situacoes[objetivoEspecifico.situacao.situacao].color}
                          onClick={() => {
                            if (this.props.role !== Papel.PROFESSOR.value) return;
                            Object.assign(objetivoEspecifico.situacao, {
                              situacao: (objetivoEspecifico.situacao.situacao + 1)
                                % this.situacoes.length,
                            });
                            this.forceUpdate();
                          }}>
                          {this.situacoes[objetivoEspecifico.situacao.situacao].label}
                        </Button>
                      </td>
                    </tr>)
                  }
                </React.Fragment>)
              }
            </tbody>
          </Table>
        </PerfectScrollbar>
        <Row>
          <Col className="text-left">
            <Button
              color="primary"
              onClick={() => this.props.callbackVoltar()} >
              Voltar
            </Button>
          </Col>
          <Col className="text-right">
            {
              this.props.role === Papel.PROFESSOR.value && !this.state.auxiliarBloqueado && <Button
                color="primary"
                disabled={this.state.buttonDisabled}
                onClick={this.salvarObjetivosTrabalhados} >
                Salvar Objetivos trabalhados
              </Button>
            }
          </Col>
        </Row>
      </>;
  }
}

class ObjetivosAprendizagemOuHabilidadesTrabalhadasMultiseriado extends React.Component {
  constructor(props) {
    super(props);

    this.turnos = [
      Turno.MATUTINO,
      Turno.VESPERTINO,
      Turno.NOTURNO,
      Turno.INTEGRAL,
    ];

    this.situacoes = [
      SituacaoHabilidade.TRABALHADA,
      SituacaoHabilidade.TRABALHADA_PARCIALMENTE,
      SituacaoHabilidade.NAO_TRABALHADA,
      SituacaoHabilidade.NAO_INFORMADA,
    ];

    this.state = {
      showAlert: false,
      showLoaderModal: false,
      buttonDisabled: false,

      error: null,
    };

    this.loginService = new LoginService();
    this.anosLetivosService = new AnosLetivosService();
    this.escolasService = new EscolasService();
    this.turmasService = new TurmasService();
    this.diariosService = new DiariosService();
  }

  componentWillUnmount() {
    this.loginService.abortPedingRequests();
    this.anosLetivosService.abortPedingRequests();
    this.escolasService.abortPedingRequests();
    this.turmasService.abortPedingRequests();
    this.diariosService.abortPedingRequests();
    this.umounted = true;
  }

  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);
    try {
      const anoLetivo = await this.anosLetivosService
        .carregarAnoLetivo(this.props.match.params.idAnoLetivo);

      const escola = await this.escolasService
        .carregarEscola(this.props.match.params.idEscola);

      const turma = await this.turmasService
        .carregarTurmaMultiseriado(this.props.match.params.idTurma);

      const diarios = await this.diariosService
        .carregarDiariosDaTurmaMultiseriado(this.props.match.params.idTurma);

      diarios.sort((d1, d2) => d1.serie - d2.serie);

      const selectDiarios = [];
      diarios.forEach((diario) => {
        selectDiarios.push({
          label: diario.curso,
          value: diario.id,
          cicloEnsino: diario.cicloEnsino,
          nome: diario.nome,
          serie: diario.serie,
        });
      });

      const diarioSelecionado = {
        label: selectDiarios[0].label,
        value: selectDiarios[0].value,
        cicloEnsino: selectDiarios[0].cicloEnsino,
        nome: selectDiarios[0].nome,
        serie: selectDiarios[0].serie,
      };

      await this.carregarMateriasCurso(
        diarioSelecionado
      );

      this.setState({
        anoLetivo,
        escola,
        turma,
        selectDiarios,
        diarioSelecionado,
      });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  async carregarMateriasCurso(diarioSelecionado) {
    const materias = await this.diariosService
      .carregarMateriasDoProfessor(diarioSelecionado.value);

    const selectMaterias = [];
    materias.forEach((materia) => {
      selectMaterias.push({
        label: materia.nome,
        value: materia.id,
      });
    });

    const materiaSelecionada = {
      label: selectMaterias[0].label,
      value: selectMaterias[0].value,
    };

    this.setState({
      selectMaterias,
      materiaSelecionada,
      diarioSelecionado,
    })
  }

  voltar() {
    this.props.history.push(`${this.props.layout}`
      + `/anosletivos/${this.props.match.params.idAnoLetivo}`
      + `/escola/${this.state.escola.id}`
      + `/turma/multiseriado/${this.state.turma.id}/diario`);
  }

  conteudoPagina() {
    let objHab;
    if (this.state.escola) {
      objHab = this.state.diarioSelecionado.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value
        ? 'Objetivos de aprendizagem trabalhados em' : 'Habilidades trabalhadas em';
    }

    return this.state.escola ? <>
      <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos`}>Anos letivos</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`}>Ano letivo {this.state.anoLetivo.ano}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}`}>{this.state.escola.nome}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/multiseriado/${this.state.turma.id}`}>{this.state.turma.nome} - {this.turnos[this.state.turma.turno].label}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/multiseriado/${this.state.turma.id}/diario`}>{this.state.diarioSelecionado.nome}</Link></BreadcrumbItem>
            <BreadcrumbItem active>{objHab} {this.state.materiaSelecionada.label}</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">
                {objHab} {this.state.diarioSelecionado.label} em {this.state.materiaSelecionada.label}
              </CardTitle>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="4">
                  <FormGroup className={'has-label has-danger'}>
                    <label>Curso</label>
                    <Select
                      noOptionsMessage={() => 'Nenhuma entrada'}
                      className={'react-select primary'}
                      classNamePrefix="react-select"
                      value={this.state.diarioSelecionado}
                      onChange={async (event) => {
                        await this.carregarMateriasCurso(event);
                      }}
                      options={this.state.selectDiarios}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup className={'has-label has-danger'}>
                    <label>Componente curricular</label>
                    <Select
                      noOptionsMessage={() => 'Nenhuma entrada'}
                      className={'react-select primary'}
                      classNamePrefix="react-select"
                      value={this.state.materiaSelecionada}
                      onChange={async (event) => {
                        this.setState({ materiaSelecionada: event })
                      }}
                      options={this.state.selectMaterias}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  {
                    this.state.diarioSelecionado.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value
                      ? <SituacoesObjetivos
                        idDiario={this.state.diarioSelecionado.value}
                        idMateria={this.state.materiaSelecionada.value}
                        role={this.props.role}
                        serie={this.state.diarioSelecionado.serie}
                        callbackMessage={(msg) => this.setState(msg)}
                        callbackVoltar={() => this.voltar()}
                      /> : <SituacoesHabilidades
                        idDiario={this.state.diarioSelecionado.value}
                        idMateria={this.state.materiaSelecionada.value}
                        role={this.props.role}
                        callbackMessage={(msg) => this.setState(msg)}
                        callbackVoltar={() => this.voltar()}
                      />
                  }
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </> : <Card>
      <div align="center" style={{ margin: 50 }}>
        <Loader
          type="Oval"
          color="#053d7c"
          height="50"
          width="50" />
      </div>
    </Card>;
  }

  render() {
    return (
      <div className='content'>
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
        <LoaderModal
          isOpen={this.state.buttonDisabled}
          text="Salvando habilidades trabalhadas..." />
      </div>
    );
  }
}

export default ObjetivosAprendizagemOuHabilidadesTrabalhadasMultiseriado;
