import React from 'react';
import { Link } from 'react-router-dom';

import {
  Row,
  Col,
  Button,
  Form,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  FormGroup,
  CardFooter,
  Breadcrumb,
  BreadcrumbItem,
  Input,
} from 'reactstrap';

import Loader from 'react-loader-spinner';
import Select from 'react-select';
import TextareaAutosize from 'react-textarea-autosize';

import Utils from 'utils/Utils';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';

import LoginService from 'services/LoginService';
import AnosLetivosService from 'services/AnosLetivosService';
import EscolasService from 'services/EscolasService';
import UsuarioService from 'services/UsuarioService';
import EntesService from 'services/EntesService';

import TipoAssinatura from 'assets/csv/tipos-assinatura.json';

class ImprimirDeclaracaoGeral extends React.Component {
  constructor(props) {
    super(props);

    this.tiposAssinatura = [
      TipoAssinatura.MANUAL,
      TipoAssinatura.AUTOMATICA,
    ];

    this.loginService = new LoginService();
    this.anosLetivosService = new AnosLetivosService();
    this.escolasService = new EscolasService();
    this.usuarioService = new UsuarioService();
    this.entesService = new EntesService();

    this.state = {
      showAlert: false,
      error: null,

      escolaSelecionada: null,
      titulo: '',
      texto: '',

      tipoAssinaturaSelecionado: TipoAssinatura.MANUAL,
    };

    this.imprimirDeclaracaoGeral = this.imprimirDeclaracaoGeral.bind(this);
  }

  componentWillUnmount() {
    this.loginService.abortPedingRequests();
    this.anosLetivosService.abortPedingRequests();
    this.escolasService.abortPedingRequests();
    this.usuarioService.abortPedingRequests();
    this.umounted = true;
  }

  async componentDidMount() {
    try {
      const dadosEnte = await this.entesService.carregarDadosEnte();

      const data = await this.escolasService
        .carregarEscolas(1, 999999, '');

      const selectEscolas = [];
      data.escolas.forEach((escola) => selectEscolas.push({
        value: escola.id,
        label: escola.nome,
      }));

      this.setState({
        selectEscolas,
        dadosEnte,
      });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  validarCampos() {
    let ret = true;

    if (!this.state.escolaSelecionada) {
      this.setState({ escolaState: 'danger' });
      ret = false;
    }

    if (!this.state.titulo) {
      this.setState({ tituloState: 'has-danger' });
      ret = false;
    }

    if (!this.state.texto) {
      this.setState({ textoState: 'has-danger' });
      ret = false;
    }

    return ret;
  }

  limparFormulario() {
    this.setState({
      escolaState: '',
      escolaSelecionada: null,
    });
  }

  async imprimirDeclaracaoGeral(e) {
    e.preventDefault();

    if (this.state.buttonDisabled) return;
    if (!this.validarCampos()) return;

    this.setState({
      showAlert: false,
      buttonDisabled: true,
      loaderModalText: 'Imprimindo declaração de geral para pdf...',
    });

    try {
      const pdf = await this.escolasService
        .imprimirDeclaracaoGeral(this.state.escolaSelecionada.value,
          this.state.tipoAssinaturaSelecionado.value, this.state.titulo, this.state.texto);

      const file = Utils.base64ToBlob(pdf);
      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);

      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Declaração geral impressa com sucesso. Caso o documento não seja visualizado, desabilite o bloqueador de pop-up para este site',
      });
    } catch (msg) {
      if (this.umounted) return;
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao imprimir declaração de geral',
      });
    }
  }

  conteudoPagina() {
    return this.state.selectEscolas ? <>
      <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.props.layout}/documentos`}>Documentos</Link></BreadcrumbItem>
            <BreadcrumbItem active>Imprimir Declaração Geral</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Form onSubmit={this.imprimirDeclaracaoGeral}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Imprimir Declaração Geral</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="4">
                    <FormGroup className={'has-label has-danger'}>
                      <label>Escola *</label>
                      <Select
                        noOptionsMessage={() => 'Nenhuma entrada'}
                        className={`react-select primary ${this.state.escolaState}`}
                        classNamePrefix="react-select"
                        value={this.state.escolaSelecionada}
                        onChange={(event) => {
                          this.setState({ escolaState: '', escolaSelecionada: event });
                        }}
                        options={this.state.selectEscolas}
                        placeholder="Escola..."
                      />
                      {this.state.escolaState === 'danger' ? (
                        <label className="error">
                          Informe a escola.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <FormGroup className={'has-label has-danger'}>
                      <label>Assinatura *</label>
                      <Select
                        noOptionsMessage={() => 'Nenhuma entrada'}
                        className={`react-select primary ${this.state.tipoAssinaturaState}`}
                        classNamePrefix="react-select"
                        value={this.state.tipoAssinaturaSelecionado}
                        onChange={(event) => {
                          this.setState({ tipoAssinaturaState: '', tipoAssinaturaSelecionado: event });
                        }}
                        options={this.tiposAssinatura}
                        placeholder="Tipo de assinatura..."
                      />
                      {this.state.tipoAssinaturaState === 'danger' ? (
                        <label className="error">
                          Informe o tipo da assinatura do documento.
                        </label>
                      ) : <label></label>}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup className={`has-label ${this.state.tituloState}`}>
                      <label>Título da declaração *</label>
                      <Input
                        type="text"
                        value={this.state.titulo}
                        onChange={(evt) => this.setState({ 
                          titulo: evt.target.value,
                          tituloState: 'has-success' ,
                        })}
                      />
                      {this.state.tituloState === 'has-danger' ? (
                        <label className="error">
                          Informe o título da declaração
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup className={`has-label ${this.state.textoState}`}>
                      <label>Texto *</label>
                      <TextareaAutosize
                        className={`autoheight ${this.state.textoState}`}
                        value={this.state.texto}
                        onChange={(event) => {
                          this.setState({
                            texto: event.target.value,
                            textoState: 'primary',
                          });
                        }} />
                      {this.state.textoState === 'has-danger' ? (
                        <label className="error">
                          Informe o texto da declaração.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Row>
                  <Col className="text-left">
                    <Button
                      color="primary"
                      onClick={() => this.props.history
                        .push(`${this.props.layout}/documentos`)} >
                      Voltar
                    </Button>
                  </Col>
                  <Col className="text-right">
                    <Button
                      type="submit"
                      disabled={this.state.buttonDisabled}
                      color="primary">
                      Imprimir
                    </Button>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Form>
        </Col >
      </Row >
    </>
      : <Card>
        <div align="center" style={{ margin: 50 }}>
          <Loader
            type="Oval"
            color="#053d7c"
            height="50"
            width="50" />
        </div>
      </Card>;
  }

  render() {
    return (
      <div className='content'>
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
        <LoaderModal
          isOpen={this.state.buttonDisabled}
          text={this.state.loaderModalText} />
      </div>
    );
  }
}

export default ImprimirDeclaracaoGeral;
