import React from 'react';
import { Link } from 'react-router-dom';

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Button,
} from 'reactstrap';

import {
  Tabs,
  TabList,
  Tab,
  TabPanel,
} from 'react-tabs';

import Loader from 'react-loader-spinner';

import LoaderModal from 'views/components/LoaderModal';
import Alert from 'views/components/Alert';
import RelatorioInformacoesMatriculasGrupos from 'views/components/RelatorioInformacoesMatriculasGrupos';

import LoginService from 'services/LoginService';
import AnosLetivosService from 'services/AnosLetivosService';

import GrupoMatricula from 'assets/csv/grupos-matricula.json';

class RelatorioInformacoesMatriculas extends React.Component {
  constructor(props) {
    super(props);

    this.grupos = [
      GrupoMatricula.MATRICULADOS,
      GrupoMatricula.APROVADOS,
      GrupoMatricula.REPROVADOS,
      GrupoMatricula.DESISTENTES,
      GrupoMatricula.TRANSFERIDOS,
      GrupoMatricula.IDADE_REGULAR,
      GrupoMatricula.FORA_DE_FAIXA,
      GrupoMatricula.PNE,
      GrupoMatricula.FALECIDOS,
    ];

    this.loginService = new LoginService();
    this.anosLetivosService = new AnosLetivosService();

    this.state = {
      buttonDisabled: false,
      showAlert: false,

      anoLetivo: null,
      loading: true,

      error: null,
    };
  }

  componentWillUnmount() {
    this.loginService.abortPedingRequests();
    this.anosLetivosService.abortPedingRequests();
  }

  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);

    this.setState({
      showAlert: false,
      buttonDisabled: true,
    });

    try {
      const anoLetivo = await this.anosLetivosService
        .carregarAnoLetivo(this.props.match.params.idAnoLetivo);

      this.setState({
        buttonDisabled: false,
        anoLetivo,
        loading: false,
      });
    } catch (e) {
      this.setState({ erro: true, buttonDisabled: false });
    }
  }

  conteudoPagina() {
    return !this.state.loading ? <>
      <div className="content">
        <Alert
          color={this.state.alertColor}
          isOpen={this.state.showAlert}
          toggle={() => { this.setState({ showAlert: false }); }}>
          {this.state.alertMsg}
        </Alert>
        <Breadcrumb>
          <BreadcrumbItem><Link to={`${this.props.layout}/dashboard`}>Dashboard</Link></BreadcrumbItem>
          <BreadcrumbItem active>
            Informações sobre matrículas {this.state.anoLetivo.ano}
          </BreadcrumbItem>
        </Breadcrumb>
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">
                  Informações sobre matrículas {this.state.anoLetivo.ano}
                </CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="12">
                    <Tabs>
                      <TabList>
                        {
                          this.grupos.map((grupo, idx) => <Tab key={idx}>
                            {grupo.label}
                          </Tab>)
                        }
                      </TabList>
                      {
                        this.grupos.map((grupo, idx) => <TabPanel key={idx}>
                          <RelatorioInformacoesMatriculasGrupos
                            {...this.props}
                            idAnoLetivo={this.props.match.params.idAnoLetivo}
                            idEscola={this.props.match.params.idEscola}
                            idDiario={this.props.match.params.idDiario}
                            grupo={grupo.value}
                          />
                        </TabPanel>)
                      }
                    </Tabs>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Row>
                  <Col md="6" className="text-left">
                    <Button
                      disabled={this.state.buttonDisabled}
                      color="primary"
                      type="button"
                      onClick={() => this.props.history.goBack()}>
                      Voltar
                    </Button>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </div>
    </> : <Card>
        <div align="center" style={{ margin: 50 }}>
          <Loader
            type="Oval"
            color="#053d7c"
            height="50"
            width="50" />
        </div>
      </Card>;
  }

  render() {
    return (
      <div className='content'>
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
        <LoaderModal
          isOpen={this.state.buttonDisabled}
          text="Carregando..." />
      </div>
    );
  }
}

export default RelatorioInformacoesMatriculas;
