/* eslint-disable max-len */
import React from 'react';

import {
  Row,
  Col,
  Button,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  UncontrolledTooltip,
} from 'reactstrap';

import Loader from 'react-loader-spinner';

import TextoModal from 'views/components/TextoModal';
import LoaderModal from 'views/components/LoaderModal';
import ConfirmModal from 'views/components/ConfirmModal';

import TurmasService from 'services/TurmasService';

import Turno from 'assets/csv/turnos.json';
import SituacaoTurma from 'assets/csv/situacoes-turma.json';
import Papel from 'assets/csv/papeis.json';
import CicloEnsino from 'assets/csv/ciclos-ensino.json';
import TipoAvaliacao from 'assets/csv/tipos-avaliacao.json';

class InformacoesTurma extends React.Component {
  constructor(props) {
    super(props);
    this.situacoesTurma = [
      SituacaoTurma.ABERTA,
      SituacaoTurma.CONSOLIDADA,
    ];

    this.turnos = [
      Turno.MATUTINO,
      Turno.VESPERTINO,
      Turno.NOTURNO,
      Turno.INTEGRAL,
    ];

    this.tiposAvaliacao = [
      TipoAvaliacao.NOTA,
      TipoAvaliacao.RELATORIO,
    ];

    this.ciclosEnsino = [
      CicloEnsino.ENSINO_INFANTIL,
      CicloEnsino.ENSINO_FUNDAMENTAL_1,
      CicloEnsino.ENSINO_FUNDAMENTAL_2,
      CicloEnsino.ENSINO_MEDIO,
      CicloEnsino.ENSINO_TECNICO,
      CicloEnsino.EJA,
      CicloEnsino.OUTRO,
      CicloEnsino.CORRECAO_FLUXO,
    ];

    this.state = {
      loading: true,
      turma: props.turma,
      anoLetivo: props.anoLetivo,
      escola: props.escola,
    };

    this.turmasService = new TurmasService();

    this.consolidarTurma = this.consolidarTurma.bind(this);
    this.confirmarConsolidacaoTurma = this.confirmarConsolidacaoTurma.bind(this);
    this.reabrirTurma = this.reabrirTurma.bind(this);
    this.confirmarReaberturaTurma = this.confirmarReaberturaTurma.bind(this);
    this.processarTurma = this.processarTurma.bind(this);
  }

  async componentDidMount() {
    let tituloTextoModal = '';
    if (this.state.turma.curso) {
      if (this.state.turma.curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value) {
        if (this.state.turma.curso.serie === 0 || this.state.turma.curso.serie === 1) { // Berçário 0
          tituloTextoModal = 'Direitos de aprendizagem e desenvolvimento - Bebês com 0 e 1 ano';
        } else if (this.state.turma.curso.serie === 2 || this.state.turma.curso.serie === 3) { // Berçário 0
          tituloTextoModal = 'Direitos de aprendizagem e desenvolvimento - Crianças bem pequenas com 2 e 3 anos';
        } else { // PRÉ1 E PRÉ2
          tituloTextoModal = 'Direitos de aprendizagem e desenvolvimento - Crianças pequenas com 4 e 5 anos';
        }
      } else { // ENSINO FUNDAMENTAL 1
        tituloTextoModal = `Competências gerais propostas para os estudantes do ${this.state.turma.curso.nome}`;
      }
    }

    let conteudoTextoModal = '';
    if (this.state.turma.curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value) {
      conteudoTextoModal = !this.state.turma.estruturaCurricular.competenciasGerais
        ? 'Direitos de aprendizagem não informados' : this.state.turma.estruturaCurricular.competenciasGerais;
    } else {
      conteudoTextoModal = !this.state.turma.estruturaCurricular.competenciasGerais
        ? 'Competências gerais não informadas' : this.state.turma.estruturaCurricular.competenciasGerais;
    }

    const totalMatriculas = await this.turmasService.carregarTotalMatriculasAtivas(this.state.turma.id);

    this.setState({
      loading: false,
      tituloTextoModal,
      conteudoTextoModal,
      totalMatriculas,
    });
  }

  verCompetenciasGerais() {
    this.setState({
      showTextoModal: true,
      tituloTextoModal: this.state.tituloTextoModal,
      conteudoTextoModal: this.state.conteudoTextoModal,
    });
  }

  confirmarConsolidacaoTurma() {
    this.setState({
      showConfirmModal: true,
      confirmModalText: 'Confirme a consolidação da turma',
      confirmModalButtonText: 'Consolidar',
      confirmModalAction: this.consolidarTurma,
    });
  }

  async consolidarTurma() {
    this.setState({
      showLoaderModal: true,
      loaderModalText: 'Consolidando turma...',
    });
    this.props.callbackMessage({
      showAlert: false,
    });
    try {
      await this.turmasService.consolidarTurma(this.state.turma.id);

      Object.assign(this.state.turma, { situacao: SituacaoTurma.CONSOLIDADA.value });
      this.setState({
        showLoaderModal: false,
      });

      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Turma consolidada com sucesso',
      });
    } catch (msg) {
      if (this.umounted) return;
      this.setState({
        showLoaderModal: false,
      });

      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao consolidar turma',
      });
    }
  }

  confirmarReaberturaTurma() {
    this.setState({
      showConfirmModal: true,
      confirmModalText: 'Confirme a reabertura da turma',
      confirmModalButtonText: 'Reabrir',
      confirmModalAction: this.reabrirTurma,
    });
  }

  async reabrirTurma() {
    this.setState({
      showLoaderModal: true,
      loaderModalText: 'Reabrindo turma...',
    });
    this.props.callbackMessage({
      showAlert: false,
    });
    try {
      await this.turmasService.reabrirTurma(this.state.turma.id);

      Object.assign(this.state.turma, { situacao: SituacaoTurma.ABERTA.value });
      this.setState({
        showLoaderModal: false,
      });

      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Turma reaberta com sucesso',
      });
    } catch (msg) {
      if (this.umounted) return;
      this.setState({
        showLoaderModal: false,
      });

      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao reabrir turma',
      });
    }
  }

  async processarTurma() {
    this.setState({
      showLoaderModal: true,
      loaderModalText: 'Processando resultado da turma...',
    });

    this.props.callbackMessage({
      showAlert: false,
    });
    try {
      await this.turmasService.processarTurma(this.state.turma.id);

      this.setState({
        showLoaderModal: false,
      });

      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Resultado da turma processado com sucesso',
      });
    } catch (msg) {
      if (this.umounted) return;
      this.setState({
        showLoaderModal: false,
      });

      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao processar resultado da turma',
      });
    }
  }

  render() {
    return this.state.loading ? <div align="center" style={{ padding: 30 }}>
      <Loader
        type="Oval"
        color="#053d7c"
        height="50"
        width="50" />
    </div> : <>
    <Row className="align-items-end">
        <Col md="2">
          <FormGroup className="has-label">
            <label>Situação</label>
            <Input
              disabled
              type="text"
              value={this.situacoesTurma[this.state.turma.situacao].label}
            />
          </FormGroup>
        </Col>
        {this.state.turma.situacao === SituacaoTurma.ABERTA.value
          && this.props.role !== Papel.COORDENADOR_PEDAGOGICO.value
          && this.props.role !== Papel.PROFESSOR.value && <Col>
            <Button color="primary" onClick={() => this.confirmarConsolidacaoTurma()}>
              Consolidar turma
            </Button>
          </Col>
        }
        {this.state.turma.situacao === SituacaoTurma.CONSOLIDADA.value
          && this.props.role !== Papel.COORDENADOR_PEDAGOGICO.value && <Col>
            <Button color="primary" onClick={() => this.confirmarReaberturaTurma()}>
              Reabrir turma
            </Button>
          </Col>
        }
      </Row>
      <Row>
        <Col md="4">
          <FormGroup className="has-label">
            <label>Escola</label>
            <Input
              disabled
              type="text"
              value={this.state.turma.escola.nome}
            />
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup className="has-label">
            <label>Etapa de ensino</label>
            <Input
              disabled
              type="text"
              value={this.ciclosEnsino[this.state.turma.curso.cicloEnsino].label}
            />
          </FormGroup>
        </Col>
        <Col md="2">
          <FormGroup className="has-label">
            <label>Nome</label>
            <Input
              disabled
              type="text"
              value={this.state.turma.nome}
            />
          </FormGroup>
        </Col>
        <Col md="2">
          <FormGroup className="has-label">
            <label>Turno</label>
            <Input
              disabled
              type="text"
              value={this.turnos[this.state.turma.turno].label}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="4">
          <FormGroup className="has-label">
            <label>Curso</label>
            <InputGroup>
              <Input
                disabled
                type="text"
                value={this.state.turma.curso.nome}
              />
              <InputGroupAddon
                id="btnVerCompetenciasGerais"
                addonType="append"
                className='clickable'
                onClick={() => this.verCompetenciasGerais()}>
                <InputGroupText>
                  <i className="far fa-file-alt clickable-icon" />
                </InputGroupText>
              </InputGroupAddon>
              <UncontrolledTooltip placement="bottom" target='btnVerCompetenciasGerais' delay={0}>
                {this.state.tituloTextoModal}
              </UncontrolledTooltip>
            </InputGroup>
          </FormGroup>
        </Col>
        <Col md="4">
          <FormGroup className="has-label">
            <label>Tipo da avaliação</label>
            <Input
              disabled
              type="text"
              value={this.tiposAvaliacao[this.state.turma.tipoAvaliacao].label}
            />
          </FormGroup>
        </Col>
        <Col md="2">
          <FormGroup className="has-label">
            <label>Número de vagas</label>
            <Input
              disabled
              type="text"
              value={this.state.turma.vagas || 'Não definido'}
            />
          </FormGroup>
        </Col>
        <Col md="2">
          <FormGroup className="has-label">
            <label>Total de matrículas</label>
            <Input
              disabled
              type="text"
              value={this.state.totalMatriculas}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col className="text-left">
          <Button
            color="primary"
            onClick={() => this.props.history
              .push(`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}`)} >
            Voltar
          </Button>
        </Col>
        <Col className="text-right">
          {
            this.props.role !== Papel.COORDENADOR_PEDAGOGICO.value
             && this.props.role !== Papel.PROFESSOR.value && <>
              <Button
                color="primary"
                onClick={() => this.props.history
                  .push(`${this.props.layout}/anosletivos/${this.state.anoLetivo.id}/escola/${this.state.escola.id}/turma/${this.state.turma.id}/matricula/aluno`)} >
                Matricular aluno
              </Button>
            </>
          }
        </Col>
      </Row>
      <TextoModal
        isOpen={this.state.showTextoModal}
        titulo={this.state.tituloTextoModal}
        conteudo={this.state.conteudoTextoModal}
        closeCallback={() => this.setState({ showTextoModal: false })} />
      <ConfirmModal
        isOpen={this.state.showConfirmModal}
        callback={(confirm) => {
          this.setState({ showConfirmModal: false });
          if (confirm) {
            this.state.confirmModalAction();
          } else {
            this.setState({ matriculaSelecionada: null });
          }
        }}
        text={this.state.confirmModalText}
        confirmText={this.state.confirmModalButtonText} />
      <LoaderModal
        isOpen={this.state.showLoaderModal}
        text={`${this.state.loaderModalText}`} />
    </>;
  }
}

export default InformacoesTurma;
