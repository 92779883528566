import React from 'react';

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Label,
} from 'reactstrap';

import NumberFormat from 'react-number-format';
import Select from 'react-select';
import Loader from 'react-loader-spinner';
import InputMask from 'react-input-mask';
import { cnpj } from 'cpf-cnpj-validator';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';
import ImageCroppModal from 'views/components/ImageCroppModal';

import EntesService from 'services/EntesService';
import LoginService from 'services/LoginService';

import Serie from 'assets/csv/series2.json';

class DadosEnte extends React.Component {
  constructor(props) {
    super(props);

    this.nomeOrgaoGestorAdministracaoRef = React.createRef();
    this.nomeOrgaoGestorEducacaoRef = React.createRef();
    this.paginaNoticiasRef = React.createRef();
    this.fileInputRef = React.createRef();
    this.cnpjRef = React.createRef();

    this.atualizarDadosEnte = this.atualizarDadosEnte.bind(this);
    this.validarCampos = this.validarCampos.bind(this);

    this.entesService = new EntesService();
    this.loginService = new LoginService();

    this.series = [
      Serie.ENSINO_FUNDAMENTAL_6,
      Serie.ENSINO_FUNDAMENTAL_7,
      Serie.ENSINO_FUNDAMENTAL_8,
      Serie.ENSINO_FUNDAMENTAL_9,
    ];

    this.state = {
      showAlert: false,
      showImageCropperModal: false,

      nomeOrgaoGestorAdministracaoState: '',
      nomeOrgaoGestorEducacaoState: '',
      fotoState: '',

      buttonDisabled: false,
      loaderModalText: '',

      erro: null,

      foto: null,
    };
  }

  componentWillUnmount() {
    this.loginService.abortPedingRequests();
    this.entesService.abortPedingRequests();
    this.umounted = true;
  }

  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);

    try {
      const dadosEnte = await this.entesService.carregarDadosEnte();

      const foto = await this.entesService.carregarFoto(dadosEnte.id);

      this.setState({
        foto,
        dadosEnte,
        bloquearCadastroAlunos: dadosEnte.bloquearCadastroAlunos,
        bloquearFrequenciaFutura: dadosEnte.bloquearFrequenciaFutura,
        habilitarDependencia: dadosEnte.habilitarDependencia,
        serieDependenciaSelecionada: this.series[dadosEnte.serieDependencia - Serie.ENSINO_FUNDAMENTAL_6.value],
        reprovacoesDependencia: dadosEnte.reprovacoesDependencia,
      });

      this.nomeOrgaoGestorAdministracaoRef.current.value = dadosEnte.nomeOrgaoGestorAdministracao;
      this.paginaNoticiasRef.current.value = dadosEnte.paginaNoticias || '';
      this.nomeOrgaoGestorEducacaoRef.current.value = dadosEnte.nomeOrgaoGestorEducacao;
      this.cnpjRef.current.value = dadosEnte.cnpj || '';

      this.forceUpdate();
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  validarCampos() {
    const regex = /^([a-z]+([a-z]|-)*)*[a-z]+$/g;

    let ret = true;
    if (this.nomeOrgaoGestorAdministracaoRef.current.value === '') {
      this.setState({ nomeOrgaoGestorAdministracaoState: 'has-danger' });
      ret = false;
    }
    if (this.nomeOrgaoGestorEducacaoRef.current.value === '') {
      this.setState({ nomeOrgaoGestorEducacaoState: 'has-danger' });
      ret = false;
    }
    if (!this.state.foto) {
      this.setState({ fotoState: 'has-danger' });
      ret = false;
    }
    if (this.cnpjRef.current.value !== '' && !cnpj.isValid(this.cnpjRef.current.value)) {
      this.setState({ cnpjState: 'has-danger' });
      ret = false;
    }
    if (this.paginaNoticiasRef.current.value !== ''
      && !regex.test(this.paginaNoticiasRef.current.value)) {
      this.setState({ paginaNoticiasState: 'has-danger' });
      ret = false;
    }
    if(this.state.habilitarDependencia) {
      if (!this.state.serieDependenciaSelecionada) {
        this.setState({ serieDependenciaSelecionadaState: 'danger' });
        ret = false;
      }
      if(!this.state.reprovacoesDependencia) {
        this.setState({ reprovacoesDependenciaState: 'has-danger' });
        ret = false;
      }
    }
    return ret;
  }

  async atualizarDadosEnte() {
    if (!this.validarCampos()) return;

    this.setState({
      buttonDisabled: true,
      showAlert: false,
      loaderModalText: 'Atualizando dados do ente...',
    });

    const dadosEnte = {
      nomeOrgaoGestorAdministracao: this.nomeOrgaoGestorAdministracaoRef.current.value,
      paginaNoticias: this.paginaNoticiasRef.current.value,
      nomeOrgaoGestorEducacao: this.nomeOrgaoGestorEducacaoRef.current.value,
      cnpj: this.cnpjRef.current.value,
      foto: this.state.foto,
      bloquearCadastroAlunos: this.state.bloquearCadastroAlunos,
      bloquearFrequenciaFutura: this.state.bloquearFrequenciaFutura,
      habilitarDependencia: this.state.habilitarDependencia,
      serieDependencia: this.state.habilitarDependencia ? this.state.serieDependenciaSelecionada.value : undefined,
      reprovacoesDependencia: this.state.habilitarDependencia ? this.state.reprovacoesDependencia : undefined,
    };

    try {
      await this.entesService.atualizarDadosEnte(dadosEnte);

      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Dados do ente atualizado com sucesso',
      });
    } catch (e) {
      if (this.umounted) return;
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao atualizar dados do Ente',
      });
    }
  }

  handleImageChange(e) {
    this.setState({ showAlert: false });
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    if (file) {
      reader.onloadend = () => {
        if (file.size > 1000000) {
          this.setState({
            showAlert: true,
            alertColor: 'danger',
            alertMsg: 'Erro: Imagem muito grande. O tamanho máximo para imagem é 1MB',
          });
        } else {
          const novaFoto = reader.result.substring(reader.result.indexOf(',') + 1, reader.result.length);
          this.setState({ novaFoto, showImageCropperModal: true });
        }
      };
      reader.readAsDataURL(file);
    }
  }

  conteudoPagina() {
    return !this.state.foto
      ? <Card>
        <div align="center" style={{ margin: 50 }}>
          <Loader
            type="Oval"
            color="#053d7c"
            height="50"
            width="50" />
        </div>
      </Card> : <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem active>Dados do Ente</BreadcrumbItem>
          </Breadcrumb>
          <Form>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Dados do Ente</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col style={{ marginBottom: 20 }}>
                    <FormGroup className={`has-label  ${this.state.fotoState}`}>
                      <div>
                        <div className="fileinput text-center">
                          <input
                            type="file"
                            accept="image/jpeg, image/png"
                            onChange={this.handleImageChange.bind(this)}
                            ref={this.fileInputRef} />
                        </div>
                        <a href="#foto" onClick={(e) => e.preventDefault()}>
                          <img className="img-err img-profile"
                            alt="..."
                            src={`data:image/jpeg;base64,${this.state.foto}`}
                            width={100}
                            height={100}
                            onClick={() => this.fileInputRef.current.click()}
                          />
                        </a>
                      </div>
                      {this.state.fotoState !== '' ? (
                        <label className="error">
                          Forneça uma imagem de timbre.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="5">
                    <FormGroup className={`has-label ${this.state.nomeOrgaoGestorAdministracaoState}`}>
                      <label>Nome do Órgão Gestor da Administração *</label>
                      <Input
                        innerRef={this.nomeOrgaoGestorAdministracaoRef}
                        type="text"
                        maxLength="200"
                        onChange={() => this.setState({ nomeOrgaoGestorAdministracaoState: 'has-success' })}
                      />
                      {this.state.nomeOrgaoGestorAdministracaoState === 'has-danger' ? (
                        <label className="error">
                          Informe o nome do Órgão Gestor da Administração.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup className={`has-label ${this.state.nomeOrgaoGestorEducacaoState}`}>
                      <label>Nome do Órgão Gestor da Educação *</label>
                      <Input
                        innerRef={this.nomeOrgaoGestorEducacaoRef}
                        type="text"
                        maxLength="200"
                        onChange={() => this.setState({ nomeOrgaoGestorEducacaoState: 'has-success' })}
                      />
                      {this.state.nomeOrgaoGestorEducacaoState === 'has-danger' ? (
                        <label className="error">
                          Informe o nome do Órgão Gestor da Educação.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup className={`has-label ${this.state.cnpjState}`}>
                      <label>CNPJ</label>
                      <Input
                        innerRef={this.cnpjRef}
                        type="text"
                        mask="99.999.999/9999-99"
                        maskChar=""
                        onChange={() => this.setState({ cnpjState: 'has-success' })}
                        tag={InputMask}
                      />
                      {this.state.cnpjState === 'has-danger' ? (
                        <label className="error">
                          Informe um CNPJ válido.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="3">
                    <FormGroup className={`has-label ${this.state.paginaNoticiasState}`}>
                      <label>Página de notícias públicas *</label>
                      <Input
                        innerRef={this.paginaNoticiasRef}
                        type="text"
                        maxLength={50}
                        onChange={() => this.setState({ paginaNoticiasState: 'has-success' })}
                      />
                      {this.state.paginaNoticiasState === 'has-danger' ? (
                        <label className="error">
                          A página de notícias deve conter apenas caracteres minúsculos
                          sem acento e -
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="3">
                    <FormGroup check style={{ marginTop: '20px' }}>
                      <Label className="form-check-label">
                        <Input
                          className="form-check-input"
                          type="checkbox"
                          checked={this.state.bloquearCadastroAlunos}
                          onChange={(e) => this.setState({
                            bloquearCadastroAlunos: e.target.checked,
                          })} />
                        Bloquear cadastro e alteração de alunos pelos diretores
                        <span className="form-check-sign">
                          <span className="check"></span>
                        </span>
                      </Label>
                    </FormGroup>
                    <label></label>
                  </Col>
                </Row>
                <Row>
                  <Col md="3">
                    <FormGroup check>
                      <Label className="form-check-label">
                        <Input
                          className="form-check-input"
                          type="checkbox"
                          checked={this.state.bloquearFrequenciaFutura}
                          onChange={(e) => this.setState({
                            bloquearFrequenciaFutura: e.target.checked,
                          })} />
                        Bloquear lançamento de frequência em datas futuras
                        <span className="form-check-sign">
                          <span className="check"></span>
                        </span>
                      </Label>
                    </FormGroup>
                    <label></label>
                  </Col>
                </Row>
                <Row>
                  <Col md="3">
                    <FormGroup check>
                      <Label className="form-check-label">
                        <Input
                          className="form-check-input"
                          type="checkbox"
                          checked={this.state.habilitarDependencia}
                          onChange={(e) => this.setState({
                            habilitarDependencia: e.target.checked,
                          })} />
                        Habilitar aprovação com dependência
                        <span className="form-check-sign">
                          <span className="check"></span>
                        </span>
                      </Label>
                    </FormGroup>
                    <label></label>
                  </Col>
                </Row>
                {
                  this.state.habilitarDependencia && <Row>
                    <Col md="3">
                      <FormGroup className={'has-label has-danger'}>
                        <label>A partir da série *</label>
                        <Select
                          noOptionsMessage={() => 'Nenhuma entrada'}
                          className={`react-select primary ${this.state.serieDependenciaSelecionadaState}`}
                          classNamePrefix="react-select"
                          value={this.state.serieDependenciaSelecionada}
                          onChange={(event) => {
                            this.setState({ 
                              serieDependenciaSelecionadaState: '', 
                              serieDependenciaSelecionada: event 
                            });
                          }}
                          options={this.series}
                          placeholder="Série..."
                        />
                        {this.state.serieDependenciaSelecionadaState === 'danger' ? (
                          <label className="error">
                            Informe a série a partir da qual haverá aprovação com dependência.
                          </label>
                        ) : null}
                      </FormGroup>
                    </Col>
                    <Col md="2">
                      <FormGroup className={`has-label ${this.state.reprovacoesDependenciaState}`}>
                        <label>Máximo de reprovações *</label>
                        <Input
                          value={this.state.reprovacoesDependencia}
                          decimalSeparator={false}
                          allowNegative={false}
                          style={{ textAlign: 'right' }}
                          onValueChange={(val) => {
                            this.setState({ 
                              reprovacoesDependencia: parseInt(val.formattedValue), 
                              reprovacoesDependenciaState: '',
                            });
                          }}
                          tag={NumberFormat} />
                        {this.state.reprovacoesDependenciaState === 'has-danger' ? (
                          <label className="error">
                            Informe o número máximo de reprovações para aprovação com dependência
                          </label>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                }
                <div className="category form-category">
                  * Campos obrigatórios
                </div>
              </CardBody>
              <CardFooter className="text-right">
                <Button
                  color="primary"
                  disabled={this.state.buttonDisabled}
                  onClick={this.atualizarDadosEnte}>
                  Atualizar
                </Button>
              </CardFooter>
            </Card>
          </Form>
        </Col>
      </Row >;
  }

  render() {
    return (
      <div className="content">
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
        <ImageCroppModal
          isOpen={this.state.showImageCropperModal}
          aspect={1}
          callback={(foto) => {
            this.setState({ showImageCropperModal: false });
            if (foto) {
              this.setState({
                foto,
                fotoState: '',
              });
              this.fileInputRef.current.value = '';
            } else if (foto === null) {
              this.setState({
                showAlert: true,
                alertColor: 'danger',
                alertMsg: 'Ocorreu um erro ao recortar imagem.',
              });
            } else {
              this.fileInputRef.current.value = '';
            }
          }}
          src={`data:image/jpeg;base64,${this.state.novaFoto}`} />
        <LoaderModal
          isOpen={this.state.buttonDisabled}
          text={this.state.loaderModalText} />
      </div>
    );
  }
}

export default DadosEnte;
