import React from 'react';
import { Link } from 'react-router-dom';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';

import validarCpf from 'validar-cpf';
import { cnpj } from 'cpf-cnpj-validator';
import InputMask from 'react-input-mask';

import Select from 'react-select';
import Loader from 'react-loader-spinner';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';

import SituacaoTransporte from 'assets/csv/situacoes-transporte.json';

import TransportesService from 'services/TransportesService';
import UsuarioService from 'services/UsuarioService';
import LoginService from 'services/LoginService';

class CadastroTransporte extends React.Component {
  constructor(props) {
    super(props);

    this.situacoes = [
      SituacaoTransporte.ATIVO,
      SituacaoTransporte.INATIVO,
    ];

    this.transportesService = new TransportesService();
    this.usuarioService = new UsuarioService();
    this.loginService = new LoginService();

    this.state = {
      showAlert: false,
      buttonDisabled: false,

      modeloState: '',
      motoristaState: '',
      anoState: '',
      placaState: '',
      proprietarioState: '',
      cpfProprietarioState: '',
      itinerarioState: '',

      modelo: '',
      motorista: '',
      ano: '',
      placa: '',
      proprietario: '',
      cpfProprietario: '',
      itinerario: '',
      situacaoSelecionada: this.situacoes[0],

      loaderModalText: '',

      transporteSelecionado: null,
      erro: null,
    };

    this.cadastrarTransporte = this.cadastrarTransporte.bind(this);
    this.atualizarTransporte = this.atualizarTransporte.bind(this);
    this.validarCampos = this.validarCampos.bind(this);
    this.limparFormulario = this.limparFormulario.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentWillUnmount() {
    this.loginService.abortPedingRequests();
    this.transportesService.abortPedingRequests();
    this.usuarioService.abortPedingRequests();

    this.umounted = true;
  }

  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);

    try {
      if (this.props.match.params.idTransporte !== undefined) {
        const transporte = await this.transportesService
          .carregarTransporte(this.props.match.params.idTransporte);

        this.setState({
          transporteSelecionado: transporte,
        });

        this.contentLoad();
      }
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  contentLoad() {
    this.setState({
      modelo: this.state.transporteSelecionado.modelo,
      motorista: this.state.transporteSelecionado.motorista,
      ano: this.state.transporteSelecionado.ano,
      placa: this.state.transporteSelecionado.placa,
      proprietario: this.state.transporteSelecionado.proprietario,
      cpfProprietario: this.state.transporteSelecionado.cpfProprietario,
      itinerario: this.state.transporteSelecionado.itinerario,
      situacaoSelecionada: this.situacoes[this.state.transporteSelecionado.situacao],
    });
  }

  validarCampos() {
    let ret = true;
    if (this.state.modelo === '') {
      this.setState({ modeloState: 'has-danger' });
      ret = false;
    }
    /*if (this.state.motorista === '') {
      this.setState({ motoristaState: 'has-danger' });
      ret = false;
    }
    if (this.state.ano === '') {
      this.setState({ anoState: 'has-danger' });
      ret = false;
    }
    if (this.state.placa === '') {
      this.setState({ placaState: 'has-danger' });
      ret = false;
    }
    if (this.state.proprietario === '') {
      this.setState({ proprietarioState: 'has-danger' });
      ret = false;
    }*/
    if (this.state.cpfProprietario) {
      if (!cnpj.isValid(this.state.cpfProprietario) && !validarCpf(this.state.cpfProprietario)) {
        this.setState({ cpfProprietarioState: 'has-danger' });
        ret = false;
      }
    }
    if (this.state.itinerario === '') {
      this.setState({ itinerarioState: 'has-danger' });
      ret = false;
    }
    return ret;
  }

  async cadastrarTransporte() {
    if (!this.validarCampos()) return;

    this.setState({
      buttonDisabled: true,
      showAlert: false,
      loaderModalText: 'Cadastrando transporte...',
    });

    const transporte = {
      modelo: this.state.modelo,
      motorista: this.state.motorista,
      ano: this.state.ano,
      placa: this.state.placa,
      proprietario: this.state.proprietario,
      cpfProprietario: this.state.cpfProprietario,
      itinerario: this.state.itinerario,
      situacao: this.state.situacaoSelecionada.value,
    };

    try {
      await this.transportesService.cadastrarTransporte(transporte);
      this.limparFormulario();
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Transporte cadastrado com sucesso',
      });
    } catch (e) {
      if (this.umounted) return;
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: e || 'Erro ao cadastrar transporte',
      });
    }
  }

  async atualizarTransporte() {
    if (!this.validarCampos()) return;

    this.setState({
      buttonDisabled: true,
      showAlert: false,
      loaderModalText: 'Atualizando transporte...',
    });

    const transporte = {
      id: this.state.transporteSelecionado.id,
      modelo: this.state.modelo,
      motorista: this.state.motorista,
      ano: this.state.ano,
      placa: this.state.placa,
      proprietario: this.state.proprietario,
      cpfProprietario: this.state.cpfProprietario,
      itinerario: this.state.itinerario,
      situacao: this.state.situacaoSelecionada.value,
    };

    try {
      await this.transportesService.atualizarTransporte(transporte);

      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Transporte atualizado com sucesso',
      });
    } catch (e) {
      if (this.umounted) return;
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: e || 'Erro ao atualizar transporte',
      });
    }
  }

  limparFormulario() {
    this.setState({
      modeloState: '',
      motoristaState: '',
      anoState: '',
      placaState: '',
      proprietarioState: '',
      cpfProprietarioState: '',
      itinerarioState: '',

      modelo: '',
      motorista: '',
      ano: '',
      placa: '',
      proprietario: '',
      cpfProprietario: '',
      itinerario: '',
      situacaoSelecionada: this.situacoes[0],

      buttonDisabled: false,
    });
  }

  onSubmit(e) {
    e.preventDefault();

    if (this.state.buttonDisabled) return;

    if (!this.state.transporteSelecionado) {
      this.cadastrarTransporte();
    } else {
      this.atualizarTransporte();
    }
  }

  conteudoPagina() {
    return (this.props.match.params.idTransporte !== undefined
      && this.state.transporteSelecionado === null)
      ? <Card>
        <div align="center" style={{ margin: 50 }}>
          <Loader
            type="Oval"
            color="#053d7c"
            height="50"
            width="50" />
        </div>
      </Card> : <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to="/gestor/transportes">Transportes</Link></BreadcrumbItem>
            <BreadcrumbItem active>{this.state.transporteSelecionado ? this.state.transporteSelecionado.placa : 'Cadastro'}</BreadcrumbItem>
          </Breadcrumb>
          <Form onSubmit={this.onSubmit}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Dados da rota</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="5">
                    <FormGroup className={`has-label ${this.state.modeloState}`}>
                      <label>Modelo do veículo*</label>
                      <Input
                        value={this.state.modelo}
                        type="text"
                        maxLength="200"
                        onChange={(evt) => this.setState({
                          modelo: evt.target.value,
                          modeloState: 'has-success',
                        })}
                      />
                      {this.state.modeloState === 'has-danger' ? (
                        <label className="error">
                          Informe o modelo do transporte.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <FormGroup className={`has-label ${this.state.anoState}`}>
                      <label>Ano de fabricação do veículo</label>
                      <Input
                        value={this.state.ano}
                        type="text"
                        mask="9999"
                        maskChar=""
                        tag={InputMask}
                        onChange={(evt) => this.setState({
                          ano: evt.target.value,
                          anoState: 'has-success',
                        })}
                      />
                      {this.state.anoState === 'has-danger' ? (
                        <label className="error">
                          Informe o ano de fabricação.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <FormGroup className={`has-label ${this.state.placaState}`}>
                      <label>Placa do veículo</label>
                      <Input
                        value={this.state.placa}
                        type="text"
                        maxLength="20"
                        onChange={(evt) => this.setState({
                          placa: evt.target.value,
                          placaState: 'has-success',
                        })}
                      />
                      {this.state.placaState === 'has-danger' ? (
                        <label className="error">
                          Informe a placa do veículo.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup className={'has-label has-danger'}>
                      <label>Situação</label>
                      <Select
                        noOptionsMessage={() => 'Nenhuma entrada'}
                        className={`react-select primary ${this.state.situacaoState}`}
                        classNamePrefix="react-select"
                        value={this.state.situacaoSelecionada}
                        onChange={(event) => {
                          this.setState({ situacaoState: '', situacaoSelecionada: event });
                        }}
                        options={this.situacoes}
                        placeholder="Situação..."
                        isDisabled={this.state.transporteSelecionado === null}
                      />
                      {this.state.situacaoState === 'danger' ? (
                        <label className="error">
                          Informe a situação do transporte.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="5">
                    <FormGroup className={`has-label ${this.state.proprietarioState}`}>
                      <label>Nome/Razão social do proprietário do veículo</label>
                      <Input
                        value={this.state.proprietario}
                        type="text"
                        maxLength="200"
                        onChange={(evt) => this.setState({
                          proprietario: evt.target.value,
                          proprietarioState: 'has-success',
                        })}
                      />
                      {this.state.proprietarioState === 'has-danger' ? (
                        <label className="error">
                          Informe o no do proprietário.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <FormGroup className={`has-label ${this.state.cpfProprietarioState}`}>
                      <label>CPF/CNPJ do proprietário do veículo</label>
                      <Input
                        value={this.state.cpfProprietario}
                        type="text"
                        maxLength="50"
                        onChange={(evt) => this.setState({
                          cpfProprietario: evt.target.value,
                          cpfProprietarioState: 'has-success',
                        })}
                      />
                      {this.state.cpfProprietarioState === 'has-danger' ? (
                        <label className="error">
                          Informe um CPF/CNPJ válido.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                  <Col md="5">
                    <FormGroup className={`has-label ${this.state.motoristaState}`}>
                      <label>Motorista da rota</label>
                      <Input
                        value={this.state.motorista}
                        type="text"
                        maxLength="200"
                        onChange={(evt) => this.setState({
                          motorista: evt.target.value,
                          motoristaState: 'has-success',
                        })}
                      />
                      {this.state.motoristaState === 'has-danger' ? (
                        <label className="error">
                          Informe o motorista do transporte.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <FormGroup className={`has-label ${this.state.itinerarioState}`}>
                      <label>Itinerário da rota*</label>
                      <Input
                        value={this.state.itinerario}
                        rows={4}
                        type="textarea"
                        maxLength="200"
                        onChange={(evt) => this.setState({
                          itinerario: evt.target.value,
                          itinerarioState: 'has-success',
                        })}
                      />
                      {this.state.itinerarioState === 'has-danger' ? (
                        <label className="error">
                          Informe o itinerário do veículo.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
                <div className="category form-category">
                  * Campos obrigatórios
                </div>
              </CardBody>
              <CardFooter>
                <Row>
                  <Col md="6" className="text-left">
                    <Button color="primary" disabled={this.state.buttonDisabled} onClick={() => this.props.history.push('/gestor/transportes')} >
                      Voltar
                    </Button>
                  </Col>
                  <Col md="6" className="text-right">
                    {
                      !this.state.transporteSelecionado
                        ? <Button type="submit" color="primary" disabled={this.state.buttonDisabled} >
                          Cadastrar
                        </Button>
                        : <Button type="submit" color="primary" disabled={this.state.buttonDisabled}>
                          Atualizar
                        </Button>
                    }
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Form>
        </Col>
      </Row>;
  }

  render() {
    return (
      <div className="content">
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
        <LoaderModal
          isOpen={this.state.buttonDisabled}
          text={this.state.loaderModalText} />
      </div>
    );
  }
}

export default CadastroTransporte;
