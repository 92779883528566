import React from 'react';
import { Link } from 'react-router-dom';

import {
  Row,
  Col,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  FormGroup,
  Input,
} from 'reactstrap';

import { connect } from 'react-redux';

import Loader from 'react-loader-spinner';

import Alert from 'views/components/Alert';
import AdaptiveTable from 'views/components/AdaptiveTable';
import ConfirmModal from 'views/components/ConfirmModal';
import LoaderModal from 'views/components/LoaderModal';

import LoginService from 'services/LoginService';
import CursosService from 'services/CursosService';
import EstruturasCurricularesService from 'services/EstruturasCurricularesService';
import MateriasService from 'services/MateriasService';

import SituacaoEstruturaCurricular from 'assets/csv/situacoes-estruturas-curriculares.json';
import CicloEnsino from 'assets/csv/ciclos-ensino.json';

class Curso extends React.Component {
  constructor(props) {
    super(props);

    this.ciclosEnsino = [
      CicloEnsino.ENSINO_INFANTIL,
      CicloEnsino.ENSINO_FUNDAMENTAL_1,
      CicloEnsino.ENSINO_FUNDAMENTAL_2,
      CicloEnsino.ENSINO_MEDIO,
      CicloEnsino.ENSINO_TECNICO,
      CicloEnsino.EJA,
      CicloEnsino.OUTRO,
      CicloEnsino.CORRECAO_FLUXO,
    ];

    this.situacoes = [
      SituacaoEstruturaCurricular.ATIVA,
      SituacaoEstruturaCurricular.INATIVA,
    ];

    this.idPagina = `${this.props.dadosUsuario.idVinculo}-curso-${this.props.match.params.idCurso}`;
    this.paginaSelecionada = sessionStorage.getItem(`${this.idPagina}-pg`) || 1;
    this.linhasPorPagina = sessionStorage.getItem(`${this.idPagina}-ln`) || 5;
    this.like = '';

    this.loginService = new LoginService();
    this.cursosService = new CursosService();
    this.estruturasCurricularesService = new EstruturasCurricularesService();
    this.materiasService = new MateriasService();

    this.state = {
      estruturaCurricularSelecionada: null,

      showAlert: false,
      showLoaderModal: false,
      showConfirmModal: false,

      dadosTabela: {
        header: [['Nome', '20%'], ['Matérias', '65%'], ['Situação', '5%']],
        columnAlign: ['text-left', 'text-left', 'text-left'],
        keys: ['nome', 'materias_str', 'situacao_str'],
        rows: [],
        total: undefined,
      },
    };

    this.carregarCurso = this.carregarCurso.bind(this);
  }

  componentWillUnmount() {
    this.loginService.abortPedingRequests();
    this.cursosService.abortPedingRequests();
    this.estruturasCurricularesService.abortPedingRequests();
    this.materiasService.abortPedingRequests();
    this.umounted = true;
  }

  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);
    this.carregarCurso();
  }

  async carregarCurso() {
    try {
      const curso = await this.cursosService
        .carregarCurso(this.props.match.params.idCurso);

      this.state.dadosTabela.header[1][0] = curso.cicloEnsino === CicloEnsino.ENSINO_INFANTIL.value
        ? 'Campos de experiências' : 'Componentes curriculares';

      const data = await this.estruturasCurricularesService
        .carregarEstruturasCurriculares(this.props.match.params.idCurso,
          this.paginaSelecionada, this.linhasPorPagina, this.like);

      const fn = (estruturaCurricular) => new Promise((resolve) => resolve(this.materiasService
        .carregarMateriasPorEstruturaCurricular(estruturaCurricular.id)));

      const actions = data.estruturasCurriculares.map(fn);

      const materias = await Promise.all(actions);

      data.estruturasCurriculares.forEach(async (estruturaCurricular, idx) => {
        Object.assign(estruturaCurricular,
          { situacao_str: this.situacoes[estruturaCurricular.situacao].label });

        Object.assign(estruturaCurricular, { materias_str: '' });

        materias[idx].forEach((materia) => {
          Object.assign(estruturaCurricular, { materias_str: `${estruturaCurricular.materias_str} ${materia.nome}, ` });
        });

        Object.assign(
          estruturaCurricular,
          {
            materias_str: estruturaCurricular.materias_str
              .substring(0, estruturaCurricular.materias_str.length - 2),
          },
        );
      });

      this.setState({
        curso,
        dadosTabela: Object.assign(
          this.state.dadosTabela,
          { rows: data.estruturasCurriculares, total: data.total },
        ),
      });
    } catch (e) {
      if (this.umounted) return;

      this.setState({ erro: true });
    }
  }

  editarEstruturaCurricular(estruturaCurricular) {
    this.props.history.push({
      pathname: `/gestor/cursos/${this.props.match.params.idCurso}/estruturascurriculares/edicao/${estruturaCurricular.id}`,
    });
  }

  cadastrarCompetencias(estruturaCurricular) {
    this.props.history.push(`/gestor/cursos/${this.props.match.params.idCurso}/estruturascurriculares/${estruturaCurricular.id}`);
  }

  confirmarRemocaoEstruturaCurricular(estruturaCurricular) {
    this.setState({ estruturaCurricularSelecionada: estruturaCurricular, showConfirmModal: true });
  }

  async removerEstruturaCurricular() {
    this.setState({ showLoaderModal: true });

    try {
      await this.estruturasCurricularesService
        .removerEstruturaCurricular(this.state.estruturaCurricularSelecionada.id);

      if (this.state.dadosTabela.total % this.linhasPorPagina === 1
        && this.paginaSelecionada > 1) {
        this.paginaSelecionada -= 1;
      }

      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Estrutura curricular removida com sucesso',
      });

      this.carregarCurso();
    } catch (msg) {
      if (this.umounted) return;

      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao remover estrutura curricular',
      });
    }
  }

  conteudoPagina() {
    return this.state.curso
      ? <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to="/gestor/cursos">Cursos</Link></BreadcrumbItem>
            <BreadcrumbItem active>Curso</BreadcrumbItem>
          </Breadcrumb>
          <Card>
            <CardHeader>
              <CardTitle tag="h4">Curso</CardTitle>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="4">
                  <FormGroup className="has-label">
                    <label>Curso</label>
                    <Input
                      disabled
                      type="text"
                      value={this.state.curso.nome}
                    />
                  </FormGroup>
                </Col>
                <Col md="4">
                  <FormGroup className="has-label">
                    <label>Etapa de ensino</label>
                    <Input
                      disabled
                      type="text"
                      value={this.ciclosEnsino[this.state.curso.cicloEnsino].label}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <br /><br />
              <Row>
                <Col md="12">
                  <h5>Estruturas curriculares</h5>
                  <AdaptiveTable
                    clickRows
                    selectedPage={this.paginaSelecionada}
                    rowsPerPage={this.linhasPorPagina}
                    disablePrintAction
                    editButtonCallback={(estruturaCurricular) => (
                      this.editarEstruturaCurricular(estruturaCurricular)
                    )}
                    removeButtonCallback={(estruturaCurricular) => (
                      this.confirmarRemocaoEstruturaCurricular(estruturaCurricular)
                    )}
                    rowsPerPageCallback={(info) => {
                      this.paginaSelecionada = 1;
                      sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                      this.linhasPorPagina = info.rowsPerPage;
                      sessionStorage.setItem(`${this.idPagina}-ln`, this.linhasPorPagina);
                      this.carregarCurso();
                    }}
                    likeCallback={(text) => {
                      this.like = text;
                      this.paginaSelecionada = 1;
                      sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                      this.carregarCurso();
                    }}
                    paginatorCallback={(page) => {
                      this.paginaSelecionada = page;
                      sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
                      this.carregarCurso();
                    }}
                    rowCallback={(estruturaCurricular) => (
                      this.cadastrarCompetencias(estruturaCurricular)
                    )}
                    data={this.state.dadosTabela} />
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <Row>
                <Col md="6" className="text-left">
                  <Button color="primary" onClick={() => this.props.history.push('/gestor/cursos')} >
                    Voltar
                  </Button>
                </Col>
                <Col md="6" className="text-right">
                  <Button color="primary" onClick={() => this.props.history.push(`/gestor/cursos/${this.props.match.params.idCurso}/estruturascurriculares/cadastro`)} >
                    Cadastrar estrutura curricular
                  </Button>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
        <ConfirmModal
          isOpen={this.state.showConfirmModal}
          callback={(confirm) => {
            this.setState({ showConfirmModal: false });
            if (confirm) {
              this.removerEstruturaCurricular();
            } else {
              this.setState({ estruturaCurricularSelecionada: null });
            }
          }}
          text='Confirme a exclusão do turma' />
        <LoaderModal
          isOpen={this.state.showLoaderModal}
          text='Excluindo estrutura curricular...' />
      </Row> : <Card>
        <div align="center" style={{ margin: 50 }}>
          <Loader
            type="Oval"
            color="#053d7c"
            height="50"
            width="50" />
        </div>
      </Card>;
  }

  render() {
    return (
      <div className="content">
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações do curso
          </div>
          </Card>
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ dadosUsuario: state.dadosUsuario });

export default connect(mapStateToProps)(Curso);
