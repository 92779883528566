import React from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';

import {
  Card,
  CardBody,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  FormGroup,
  Input,
  Button,
  Table,
  UncontrolledTooltip,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from 'reactstrap';

import {
  Tabs,
  TabList,
  Tab,
  TabPanel,
} from 'react-tabs';

import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';

import { connect } from 'react-redux';
import Loader from 'react-loader-spinner';
import Select from 'react-select';
import TextareaAutosize from 'react-textarea-autosize';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';
import ConfirmModal from 'views/components/ConfirmModal';
import HistoricoMatricula from 'views/components/HistoricoMatricula';

import UsuarioService from 'services/UsuarioService';
import TurmasService from 'services/TurmasService';
import EntesService from 'services/EntesService';
import RegistroSituacaoMatriculaService from 'services/RegistroSituacaoMatriculaService';
import AnosLetivosService from 'services/AnosLetivosService';
import FrequenciaService from 'services/FrequenciaService';
import HistoricosService from 'services/HistoricosService';
import DependenciasService from 'services/DependenciasService';
import VinculoService from 'services/VinculoService';
import TransportesService from 'services/TransportesService';
import MChatsService from 'services/MChatsService';

import Sexo from 'assets/csv/sexos.json';
import Etnia from 'assets/csv/etnias.json';
import TipoSanguineo from 'assets/csv/tipos-sanguineos.json';
import Papel from 'assets/csv/papeis.json';
import TipoMatricula from 'assets/csv/tipos-matricula.json';
import SituacaoMatricula from 'assets/csv/situacoes-matricula.json';
import Dependencia from 'assets/csv/situacoes-dependencia.json';
import SituacaoVinculo from 'assets/csv/situacoes-vinculo.json';
import SituacaoMChatUsuario from 'assets/csv/situacoes-mchat-usuario.json';

class Informacoes extends React.Component {
  constructor(props) {
    super(props);

    this.sexos = [
      Sexo.MASCULINO,
      Sexo.FEMININO,
    ];

    this.etnias = [
      Etnia.BRANCA,
      Etnia.PRETA,
      Etnia.PARDA,
      Etnia.AMARELA,
      Etnia.NAO_DECLARADA,
      Etnia.INDIGENA,
    ];

    this.tiposSanguineos = [
      TipoSanguineo.A_POSITIVO,
      TipoSanguineo.A_NEGATIVO,
      TipoSanguineo.B_POSITIVO,
      TipoSanguineo.B_NEGATIVO,
      TipoSanguineo.AB_POSITIVO,
      TipoSanguineo.AB_NEGATIVO,
      TipoSanguineo.O_POSITIVO,
      TipoSanguineo.O_NEGATIVO,
    ];

    this.pnes = [
      { label: 'Não', value: false },
      { label: 'Sim', value: true },
    ];

    this.opcoesBolsaFamilia = [
      { label: 'Não', value: false },
      { label: 'Sim', value: true },
    ];

    this.opcoesUtilizaTransporte = [
      { label: 'Não', value: false },
      { label: 'Sim', value: true },
    ];

    this.opcoesTemAlergias = [
      { label: 'Não', value: false },
      { label: 'Sim', value: true },
    ];

    this.state = {
      alunoSelecionado: props.alunoSelecionado,

      nis: props.alunoSelecionado.nis || '',
      inep: props.alunoSelecionado.inep || '',
      telefone: props.alunoSelecionado.credenciais.telefone || '',
      naturalidade: props.alunoSelecionado.naturalidade,
      nome: props.alunoSelecionado.nome,
      nomeSocial: props.alunoSelecionado.nomeSocial,
      dataNascimento: props.alunoSelecionado.dataNascimento,
      cpf: props.alunoSelecionado.cpf || '',
      cartaoSUS: props.alunoSelecionado.cartaoSUS || '',
      nomeMae: props.alunoSelecionado.nomeMae,
      profissaoMae: props.alunoSelecionado.profissaoMae || '',
      rgMae: props.alunoSelecionado.rgMae || '',
      nomePai: props.alunoSelecionado.nomePai || '',
      profissaoPai: props.alunoSelecionado.profissaoPai || '',
      rgPai: props.alunoSelecionado.rgPai || '',
      endereco: props.alunoSelecionado.endereco || '',
      referencia: props.alunoSelecionado.referencia || '',
      alergias: props.alunoSelecionado.alergias || '',
      registro: props.alunoSelecionado.registro || '',
      titulo: props.alunoSelecionado.titulo || '',
      militar: props.alunoSelecionado.militar || '',
      rg: props.alunoSelecionado.rg || '',
      tamanhoCalca: props.alunoSelecionado.tamanhoCalca || '',
      tamanhoCamisa: props.alunoSelecionado.tamanhoCamisa || '',
      tamanhoCalcado: props.alunoSelecionado.tamanhoCalcado || '',
      tipoPne: props.alunoSelecionado.tipoPne || '',
      laudoPne: props.alunoSelecionado.laudoPne || '',
      sexo: this.sexos[props.alunoSelecionado.sexo].label,
      etnia: this.etnias[props.alunoSelecionado.etnia].label,
      tipoSanguineo: props.alunoSelecionado.tipoSanguineo ? this.tiposSanguineos[props.alunoSelecionado.tipoSanguineo].label : '',
      pne: this.pnes[props.alunoSelecionado.pne ? 1 : 0].label,
      bolsaFamilia: this.opcoesBolsaFamilia[props.alunoSelecionado.bolsaFamilia ? 1 : 0].label,
      utilizaTransporte: this.opcoesUtilizaTransporte[props.alunoSelecionado.utilizaTransporte ? 1 : 0].label,
      temAlergias: this.opcoesTemAlergias[props.alunoSelecionado.temAlergias ? 1 : 0].label,
    }
  }

  render() {
    return <>
      <div className="grupo-info">
        <Row>
          <Col>
            <h5>Dados do Estudante</h5>
          </Col>
        </Row>
        <Row>
          <Col md="5">
            <FormGroup>
              <label>Nome</label>
              <Input
                disabled
                value={this.state.nome}
                type="text"
              />
            </FormGroup>
          </Col>
          <Col md="2">
            <FormGroup>
              <label>Telefone</label>
              <Input
                disabled
                value={this.state.telefone}
                type="text"
              />
            </FormGroup>
          </Col>
          <Col md="2">
            <FormGroup>
              <label>Data de nascimento</label>
              <Input
                disabled
                value={this.state.dataNascimento}
                type="text"
              />
            </FormGroup>
          </Col>
          <Col md="3">
            <FormGroup>
              <label>Naturalidade </label>
              <Input
                disabled
                value={this.state.naturalidade}
                type="text"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <FormGroup>
              <label>Endereço</label>
              <Input
                disabled
                value={this.state.endereco}
                name="enderecoAluno"
                type="text"
              />
            </FormGroup>
          </Col>
          <Col md="2">
            <FormGroup>
              <label>Sexo</label>
              <Input
                disabled
                value={this.state.sexo}
                type="text"
              />
            </FormGroup>
          </Col>
          <Col md="2">
            <FormGroup>
              <label>Etnia</label>
              <Input
                disabled
                value={this.state.etnia}
                type="text"
              />
            </FormGroup>
          </Col>
          <Col md="2">
            <FormGroup>
              <label>NEE</label>
              <Input
                disabled
                value={this.state.pne}
                type="text"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md="3">
            <FormGroup>
              <label>ID Censo</label>
              <Input
                disabled
                value={this.state.inep}
                type="text"
              />
            </FormGroup>
          </Col>
          <Col md="3">
            <FormGroup>
              <label>NIS</label>
              <Input
                disabled
                value={this.state.nis}
                type="text"
              />
            </FormGroup>
          </Col>
          <Col md="4">
            <FormGroup>
              <label>Cartão SUS</label>
              <Input
                disabled
                value={this.state.cartaoSUS}
                type="text"
              />
            </FormGroup>
          </Col>
          <Col md="2">
            <FormGroup>
              <label>Bolsa família</label>
              <Input
                disabled
                value={this.state.bolsaFamilia}
                type="text"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md="5">
            <FormGroup>
              <label>Ponto de referência</label>
              <Input
                disabled
                value={this.state.referencia}
                type="text"
              />
            </FormGroup>
          </Col>
          <Col md="5">
            <FormGroup>
              <label>Nome social</label>
              <Input
                disabled
                value={this.state.nomeSocial}
                type="text"
              />
            </FormGroup>
          </Col>
          <Col md="2">
            <FormGroup>
              <label>Utiliza transporte</label>
              <Input
                disabled
                value={this.state.utilizaTransporte}
                type="text"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md="2">
            <FormGroup>
              <label>Tipo sanguíneo</label>
              <Input
                disabled
                value={this.state.tipoSanguineo}
                type="text"
              />
            </FormGroup>
          </Col>
          <Col md="2">
            <FormGroup>
              <label>Tem alergias</label>
              <Input
                disabled
                value={this.state.temAlergias}
                type="text"
              />
            </FormGroup>
          </Col>
          {
            this.props.alunoSelecionado.temAlergias && <Col md="8">
              <FormGroup>
                <label>Alergias</label>
                <Input
                  disabled
                  value={this.state.alergias}
                  type="text"
                />
              </FormGroup>
            </Col>
          }
        </Row>
        {
          this.state.alunoSelecionado.ehAlunoAtivo && <Row>
            <Col md="3">
              <FormGroup>
                <label>Login</label>
                <Input
                  disabled
                  value={this.state.alunoSelecionado.credenciais.login}
                  type="text"
                />
              </FormGroup>
            </Col>
            <Col md="9">
              <FormGroup>
                <label>Email</label>
                <Input
                  disabled
                  value={this.state.alunoSelecionado.credenciais.email}
                  type="text"
                />
              </FormGroup>
            </Col>
          </Row>
        }
      </div>
      <br />
      <div className="grupo-info">
        <Row>
          <Col>
            <h5>Filiação</h5>
          </Col>
        </Row>
        <Row>
          <Col md="5">
            <FormGroup>
              <label>Nome da mãe</label>
              <Input
                disabled
                value={this.state.nomeMae}
                type="text"
              />
            </FormGroup>
          </Col>
          <Col md="2">
            <FormGroup>
              <label>Profissão da mãe</label>
              <Input
                disabled
                value={this.state.profissaoMae}
                type="text"
              />
            </FormGroup>
          </Col>
          <Col md="5">
            <FormGroup>
              <label>RG da mãe</label>
              <Input
                disabled
                value={this.state.rgMae}
                type="text"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md="5">
            <FormGroup>
              <label>Pai</label>
              <Input
                disabled
                value={this.state.nomePai}
                type="text"
              />
            </FormGroup>
          </Col>
          <Col md="2">
            <FormGroup>
              <label>Profissão do pai</label>
              <Input
                disabled
                value={this.state.profissaoPai}
                type="text"
              />
            </FormGroup>
          </Col>
          <Col md="5">
            <FormGroup>
              <label>RG do pai</label>
              <Input
                disabled
                value={this.state.rgPai}
                type="text"
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
      <br />
      <div className="grupo-info">
        <Row>
          <Col>
            <h5>Documentos do Estudante</h5>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <FormGroup>
              <label>Registro de nascimento/casamento</label>
              <Input
                disabled
                value={this.state.registro}
                type="text"
              />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <label>Título de eleitor</label>
              <Input
                disabled
                value={this.state.titulo}
                type="text"
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col md="6">
            <FormGroup>
              <label>Situacao Militar</label>
              <Input
                disabled
                value={this.state.militar}
                type="text"
              />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <label>RG</label>
              <Input
                disabled
                value={this.state.rg}
                type="text"
              />
            </FormGroup>
          </Col>
          <Col md="3">
            <FormGroup>
              <label>CPF</label>
              <Input
                disabled
                value={this.state.cpf}
                type="text"
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
      <br />
      <div className="grupo-info">
        <Row>
          <Col>
            <h5>Informações de fardamento</h5>
          </Col>
        </Row>
        <Row>
          <Col md="2">
            <FormGroup>
              <label>Tamanho da calça</label>
              <Input
                disabled
                value={this.state.tamanhoCalca}
                type="text"
              />
            </FormGroup>
          </Col>
          <Col md="2">
            <FormGroup>
              <label>Tamanho da camisa</label>
              <Input
                disabled
                value={this.state.tamanhoCamisa}
                type="text"
              />
            </FormGroup>
          </Col>
          <Col md="2">
            <FormGroup>
              <label>Tamanho do calçado</label>
              <Input
                disabled
                value={this.state.tamanhoCalcado}
                type="text"
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
      <br />
      <Row>
        <Col md="6" className="text-left">
          <Button color="primary" onClick={() => this.props.history.goBack()} >
            Voltar
          </Button>
        </Col>
      </Row>
    </>
  }
}

class Escolas extends React.Component {
  constructor(props) {
    super(props);

    this.state = {}

    this.usuarioService = new UsuarioService();
  }

  async componentDidMount() {
    try {
      const escolas = await this.usuarioService
        .carregarEscolasUsuario(this.props.idAluno, Papel.ALUNO.value);

      this.setState({
        escolas,
      });
    } catch (e) {
      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao buscar escolas do aluno.',
      });
    }
  }

  render() {
    return this.state.escolas ? <>
      <Row>
        <Col>
          <PerfectScrollbar className="sisa-perfect-scrollbar">
            <Table>
              <thead className="text-primary">
                <tr>
                  <th style={{ width: '50%' }}>Nome</th>
                  <th style={{ width: '50%' }}>Endereço</th>
                </tr>
              </thead>
              <tbody>
                {
                  this.state.escolas
                    .map((escola, idx) => <tr key={idx}>
                      <td>{escola.nome}</td>
                      <td>{escola.endereco}</td>
                    </tr>)
                }
              </tbody>
            </Table>
          </PerfectScrollbar>
        </Col>
      </Row>
      <Row>
        <Col md="6" className="text-left">
          <Button color="primary" onClick={() => this.props.history.goBack()} >
            Voltar
          </Button>
        </Col>
      </Row>
    </> : <div align="center" style={{ margin: 50 }}>
      <Loader
        type="Oval"
        color="#053d7c"
        height="50"
        width="50" />
    </div>
  }
}

class Matriculas extends React.Component {
  constructor(props) {
    super(props);

    this.tiposMatricula = [
      TipoMatricula.REGULAR,
      TipoMatricula.DEPENDENCIA,
    ];

    this.situacoesMatricula = [
      SituacaoMatricula.MATRICULADO,
      SituacaoMatricula.TRANSFERIDO,
      SituacaoMatricula.DESISTENTE,
      SituacaoMatricula.APROVADO,
      SituacaoMatricula.APROVADO_COM_DEPENDENCIA,
      SituacaoMatricula.REPROVADO_POR_NOTA,
      SituacaoMatricula.REPROVADO_POR_FREQUENCIA,
      SituacaoMatricula.REPROVADO_POR_FREQUENCIA_E_NOTA,
      SituacaoMatricula.NAO_MATRICULADO,
      SituacaoMatricula.FALECIDO,
    ];

    this.state = {}

    this.turmasService = new TurmasService();
  }

  async componentDidMount() {
    try {
      const matriculasAtivas = await this.turmasService
        .carregarTodasMatriculasDoAluno(this.props.idAluno);

      await this.carregarTurma(matriculasAtivas);

      this.setState({
        matriculasAtivas,
      });
    } catch (e) {
      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao buscar matrículas do aluno.',
      });
    }
  }

  async carregarTurma(matriculas) {
    const fn = (matricula) => new Promise((resolve) => resolve(this.turmasService
      .carregarTurmaPorMatricula(matricula.id)));

    const actions = matriculas.map(fn);

    const turmas = await Promise.all(actions);

    const fn1 = (turma) => new Promise((resolve) => resolve(this.turmasService
      .carregarTurmaPermissaoAcessoTurma(turma.id)));

    const actions1 = turmas.map(fn1);

    const permissao = await Promise.all(actions1);

    matriculas.forEach((matricula, idx) => {
      Object.assign(matricula, {
        turma: turmas[idx],
        acesso: permissao[idx],
      });
    });
  }

  linkParaTurma(matricula) {
    return `${this.props.layout}/anosletivos/${matricula.turma.anoLetivo.id}`
      + `/escola/${matricula.turma.escola.id}/turma/${matricula.turma.id}`;
  }

  render() {
    return this.state.matriculasAtivas ? <>
      <Row>
        <Col>
          {
            this.state.matriculasAtivas.length ? <PerfectScrollbar className="sisa-perfect-scrollbar">
              <Table>
                <thead className="text-primary">
                  <tr>
                    <th style={{ width: '10%' }}>Ano letivo</th>
                    <th style={{ width: '20%' }}>Escola</th>
                    <th style={{ width: '20%' }}>Curso</th>
                    <th style={{ width: '10%' }} className="text-center">Tipo</th>
                    <th style={{ width: '10%' }} className="text-center">Situação</th>
                    <th style={{ width: '30%' }} className="text-center">Observações</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    this.state.matriculasAtivas
                      .map((matricula, idx) => <tr key={idx}>
                        <td>{matricula.turma.anoLetivo.ano}</td>
                        <td>{matricula.escola}</td>
                        <td>{matricula.acesso ? <Link to={this.linkParaTurma(matricula)}>{matricula.curso}</Link> : matricula.curso}</td>
                        <td className="text-center">{this.tiposMatricula[matricula.tipo].label}</td>
                        <td className="text-center">{this.situacoesMatricula[matricula.situacao].label}</td>
                        <td className="text-center">{matricula.observacoes}</td>
                      </tr>)
                  }
                </tbody>
              </Table>
            </PerfectScrollbar> : <><div style={{ margin: 50 }} className="text-center">Nenhuma matrícula ativa</div><br /></>
          }
        </Col>
      </Row>
      <Row>
        <Col md="6" className="text-left">
          <Button color="primary" onClick={() => this.props.history.goBack()} >
            Voltar
          </Button>
        </Col>
      </Row>
    </> : <div align="center" style={{ margin: 50 }}>
      <Loader
        type="Oval"
        color="#053d7c"
        height="50"
        width="50" />
    </div>
  }
}

class Observacoes extends React.Component {
  constructor(props) {
    super(props);

    this.state = {}

    this.usuarioService = new UsuarioService();
  }

  async componentDidMount() {
    try {
      const observacoesAluno = await this.usuarioService
        .carregarObservacoesAluno(this.props.idAluno);

      this.setState({
        observacoesAluno,
      });
    } catch (e) {
      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao buscar observações do aluno.',
      });
    }
  }

  async atualizarObservacoesAluno() {
    this.props.callbackMessage({
      showAlert: false,
      loaderModalText: 'Atualizando observações do aluno...',
      showLoaderModal: true,
    });
    try {

      await this.usuarioService
        .atualizarObservacoesAluno(this.props.idAluno, this.state.observacoesAluno);

      this.props.callbackMessage({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Observações do aluno atualizadas com sucesso',
      });
    } catch (e) {
      this.props.callbackMessage({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao atualizar observações do aluno',
      });
    }
  }

  render() {
    return this.state.observacoesAluno ? <>
      <Row>
        <Col>
          <FormGroup className={`has-label ${this.state.observacoesState}`}>
            <label>Observações</label>
            <Input
              value={this.state.observacoesAluno.observacoes}
              onChange={(evt) => {
                this.setState({
                  observacoesAluno: {
                    ...this.state.observacoesAluno,
                    observacoes: evt.target.value,
                  }
                });
              }}
              type="textarea"
              style={{ height: '500px' }} />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="6" className="text-left">
          <Button color="primary" onClick={() => this.props.history.goBack()} >
            Voltar
          </Button>
        </Col>
        <Col md="6" className="text-right">
          <Button color="primary" onClick={() => this.atualizarObservacoesAluno()} >
            Atualizar Observações
          </Button>
        </Col>
      </Row>
    </> : <div align="center" style={{ margin: 50 }}>
      <Loader
        type="Oval"
        color="#053d7c"
        height="50"
        width="50" />
    </div>
  }
}

class Historico extends React.Component {
  constructor(props) {
    super(props);

    this.idPagina = `${this.props.idVinculo}-aluno-${this.props.idAluno}`;

    this.state = {
      rowsPerPage: JSON.parse(sessionStorage.getItem(`${this.idPagina}-ln`))
        || { value: 10, label: '10 Linhas' },
    }

    this.turmasService = new TurmasService();
    this.registroSituacaoMatriculaService = new RegistroSituacaoMatriculaService();
    this.anosLetivosService = new AnosLetivosService();
    this.frequenciaService = new FrequenciaService();
    this.historicosService = new HistoricosService();

    this.editarHistorico = this.editarHistorico.bind(this);
    this.confirmarRemocaoHistorico = this.confirmarRemocaoHistorico.bind(this);
    this.removerHistorico = this.removerHistorico.bind(this);
  }

  async componentDidMount() {
    try {
      let matriculas = await this.turmasService
        .carregarMatriculasDoAluno(this.props.idAluno);

      const historicos = await this.historicosService
        .carregarHistoricoPorAluno(this.props.idAluno);

      await this.carregarTurma(matriculas);

      await this.carregarUltimoRegistroSituacaoMatricula(matriculas);

      await this.carregarDadosAvaliacoes(matriculas);

      await this.carregarCargasHorarias(matriculas);

      await this.carregarFrequencia(matriculas);

      await this.carregarMateriasHistorico(historicos);

      matriculas = matriculas.concat(historicos);

      matriculas.sort(this.ordenarMatriculas);

      this.setState({
        matriculas,
      });
    } catch (e) {
      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao buscar histórico do aluno.',
      });
    }
  }

  async carregarTurma(matriculas) {
    const fn = (matricula) => new Promise((resolve) => resolve(this.turmasService
      .carregarTurmaPorMatricula(matricula.id)));

    const actions = matriculas.map(fn);

    const turmas = await Promise.all(actions);

    matriculas.forEach((matricula, idx) => {
      Object.assign(matricula, { turma: turmas[idx] });
    });
  }

  async carregarUltimoRegistroSituacaoMatricula(matriculas) {
    matriculas.forEach((matricula) => {
      Object.assign(matricula, {
        situacao: matricula.situacao,
      });
    });
  }

  async carregarDadosAvaliacoes(matriculas) {
    const fn = (matricula) => new Promise((resolve) => resolve(this.turmasService
      .carregarDadosAvaliacoes(matricula.id)));

    const actions = matriculas.map(fn);

    const dadosAvaliacoes = await Promise.all(actions);

    matriculas.forEach((matricula, idx) => {
      Object.assign(matricula, { dadosAvaliacoes: dadosAvaliacoes[idx] });
    });
  }

  async carregarCargasHorarias(matriculas) {
    const fn = (matricula) => new Promise((resolve) => resolve(
      this.anosLetivosService
        .carregarCargaHorariaPorMatricula(matricula.id),
    ));

    const actions = matriculas.map(fn);

    const cargasHoraria = await Promise.all(actions);

    matriculas.forEach((matricula, idx) => {
      const diasLetivos = cargasHoraria[idx].diasLetivos;
      const cargaHoraria = cargasHoraria[idx].cargaHoraria;
      Object.assign(matricula, {
        diasLetivos,
        cargaHoraria
      });
    });
  }

  async carregarFrequencia(matriculas) {
    const fn = (matricula) => new Promise((resolve) => resolve(this.frequenciaService
      .carregarFrequenciaPorMatricula(matricula.id)));

    const actions = matriculas.map(fn);

    const frequencias = await Promise.all(actions);

    matriculas.forEach((matricula, idx) => {
      Object.assign(matricula, { frequencia: frequencias[idx] });
    });
  }

  async carregarMateriasHistorico(historicos) {
    const fn = (historico) => new Promise((resolve) => resolve(this.historicosService
      .carregarMateriasHistorico(historico.id)));

    const actions = historicos.map(fn);

    const materiasHistorico = await Promise.all(actions);
    historicos.forEach((historico, idx) => {
      Object.assign(historico, { dadosAvaliacoes: materiasHistorico[idx] });
    });
  }

  ordenarMatriculas = (m1, m2) => {
    if (parseInt(m1.turma.anoLetivo.ano) !== parseInt(m2.turma.anoLetivo.ano)) {
      return parseInt(m1.turma.anoLetivo.ano) - parseInt(m2.turma.anoLetivo.ano);
    }
    if (m1.turma.curso.cicloEnsino !== m2.turma.curso.cicloEnsino) {
      return m1.turma.curso.cicloEnsino - m2.turma.curso.cicloEnsino;
    }
    if (m1.turma.curso.serie !== m2.turma.curso.serie) {
      return m1.turma.curso.serie - m2.turma.curso.serie;
    }
    return 0;
  }

  editarHistorico(idHistorico) {
    this.props.history.push(`${this.props.layout}/alunos/historico/${this.props.idAluno}/edicao/${idHistorico}`);
  }

  confirmarRemocaoHistorico(idHistorico) {
    this.setState({
      idHistoricoRemocao: idHistorico,
    });

    this.props.callbackMessage({
      showConfirmModal: true,
      textoRemocao: 'Confirme a exclusão do histórico',
      funcaoRemocao: this.removerHistorico,
      idHistoricoRemocao: idHistorico,
    })
  }

  async removerHistorico() {
    this.props.callbackMessage({
      showAlert: false,
      showLoaderModal: true,
    });

    try {
      await this.historicosService
        .removerHistorico(this.state.idHistoricoRemocao);

      this.setState({
        matriculas: this.state.matriculas
          .filter((matricula) => matricula.id !== this.state.idHistoricoRemocao),
      });

      this.props.callbackMessage({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Histórico removido com sucesso',
      })
    } catch (msg) {
      this.props.callbackMessage({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao remover histórico',
      });
    }
  }

  render() {
    return this.state.matriculas ? <>
      <Row>
        <Col style={{ marginTop: -9 }} md="10">
          <Button
            color="primary"
            onClick={() => this.props.history
              .push(`${this.props.layout}/alunos/historico/${this.props.idAluno}/cadastro`)
            } >
            Cadastrar histórico
          </Button>
        </Col>
        <Col md="2">
          <Select
            noOptionsMessage={() => 'Nenhuma entrada'}
            className="react-select primary"
            classNamePrefix="react-select"
            value={this.state.rowsPerPage}
            onChange={(event) => {
              sessionStorage.setItem(`${this.idPagina}-pg`, 1);
              sessionStorage.setItem(`${this.idPagina}-ln`, JSON.stringify(event));
              this.setState({ rowsPerPage: event });
            }}
            options={[
              { value: 1, label: '1 Linha' },
              { value: 2, label: '2 Linhas' },
              { value: 5, label: '5 Linhas' },
              { value: 10, label: '10 Linhas' },
            ]}
            placeholder="Número de linhas"
          />
        </Col>
      </Row>
      <Row style={{ marginTop: 20 }}>
        <Col md="12">
          {
            this.state.matriculas.length
              ? <HistoricoMatricula
                layout={this.props.layout}
                matriculas={this.state.matriculas}
                rowsPerPage={this.state.rowsPerPage.value}
                editarHistorico={this.editarHistorico}
                excluirHistorico={this.confirmarRemocaoHistorico}
                idAluno={this.props.idAluno}
                role={this.props.role} 
                dadosEnte={this.props.dadosEnte}
                />
              : <div align="center" style={{ margin: 50 }}>
                Nenhuma matrícula concluída ou histórico cadastrado
              </div>
          }
        </Col>
      </Row>
      <Row>
        <Col md="6" className="text-left">
          <Button color="primary" onClick={() => this.props.history.goBack()} >
            Voltar
          </Button>
        </Col>
      </Row>
    </> : <div align="center" style={{ margin: 50 }}>
      <Loader
        type="Oval"
        color="#053d7c"
        height="50"
        width="50" />
    </div>
  }
}

class Pais extends React.Component {
  constructor(props) {
    super(props);

    this.state = {}

    this.usuarioService = new UsuarioService();

    this.confirmarRemocaoPais = this.confirmarRemocaoPais.bind(this);
    this.removerPais = this.removerPais.bind(this);
  }

  async componentDidMount() {
    this.debouncedFunction = _.debounce((text) => {
      if (text === '') {
        this.setState({ carregandoPais: false, selectPais: [] });
      } else {
        this.carregarPaisEnte(text);
      }
    }, 1500);

    try {
      await this.carregarPaisAluno()
    } catch (e) {
      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao buscar pais do aluno.',
      });
    }
  }

  async carregarPaisAluno() {
    const pais = await this.usuarioService
      .carregarPaisAluno(this.props.idAluno);

    this.setState({ pais });
  }

  async carregarPaisEnte(like) {
    try {
      const data = await this.usuarioService
        .carregarUsuarios(Papel.PAIS.value, 1, 99999, like);

      const selectPais = [];
      data.usuarios.forEach((pais) => {
        selectPais.push({ value: pais.id, label: `${pais.nome} (Mãe: ${pais.nomeMae || 'Não informado'})` });
      });

      this.setState({
        carregandoPais: false,
        selectPais,
      });
    } catch (e) {
      if (this.umounted) return;
      this.setState({
        carregandoPais: false,
      });
      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao buscar pais cadastrados',
      });
    }
  }

  validarCampos() {
    let ret = true;

    if (!this.state.paisSelecionado) {
      this.setState({ paisState: 'danger' });
      ret = false;
    }

    return ret;
  }

  async vincularPaisAluno() {
    if (!this.validarCampos()) return;

    try {
      this.props.callbackMessage({
        showLoaderModal: true,
        loaderModalText: 'Vinculando pais a aluno...',
        showAlert: false,
      });

      await this.usuarioService
        .vincularPaisAluno(this.props.idAluno, this.state.paisSelecionado.value);

      this.carregarPaisAluno();

      this.setState({
        paisSelecionado: '',
      });

      this.props.callbackMessage({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Vínculo adicionado com sucesso.',
      })
    } catch (e) {
      if (this.umounted) return;
      this.props.callbackMessage({
        showAlert: true,
        showLoaderModal: false,
        alertColor: 'danger',
        alertMsg: e || 'Erro ao vincular pais ao aluno',
      })
    }
  }

  confirmarRemocaoPais(idPais) {
    this.setState({
      idPaisRemocao: idPais,
    });

    this.props.callbackMessage({
      showConfirmModal: true,
      textoRemocao: 'Confirme a exclusão da mãe/pai',
      funcaoRemocao: this.removerPais,
    });
  }

  async removerPais() {
    try {
      this.props.callbackMessage({
        showLoaderModal: true,
        loaderModalText: 'Removendo pais de aluno...',
        showAlert: false,
      });

      await this.usuarioService
        .removerPaisAluno(this.props.idAluno, this.state.idPaisRemocao);

      this.carregarPaisAluno();

      this.props.callbackMessage({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Pais removidos com sucesso',
      });
    } catch (msg) {
      this.props.callbackMessage({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao remover pais',
      });
    }
  }

  render() {
    return this.state.pais ? <>
      <Row>
        <Col md="4">
          <FormGroup className={'has-label has-danger'}>
            <label>Pais *</label>
            <Select
              isLoading={this.state.carregandoPais}
              loadingMessage={() => 'Carregando...'}
              noOptionsMessage={() => 'Nenhuma entrada'}
              className={`react-select primary ${this.state.paisState}`}
              classNamePrefix="react-select"
              value={this.state.paisSelecionado}
              onInputChange={(text) => {
                this.setState({ carregandoPais: text !== '', paisState: 'primary' });
                this.debouncedFunction(text);
              }}
              onChange={(event) => {
                this.setState({ paisState: 'primary', paisSelecionado: event });
              }}
              options={this.state.selectPais}
              placeholder="Digite o nome da mãe ou pai do aluno..."
            />
            {this.state.paisState === 'danger' ? (
              <label className="error">
                Informe o nome da mãe ou pai do aluno.
              </label>
            ) : <label></label>}
          </FormGroup>
        </Col>
        <Col md="2">
          <Button
            style={{ marginTop: '25px' }}
            color="primary"
            onClick={() => this.vincularPaisAluno()}>
            Vincular
          </Button>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          {
            this.state.pais.length ? <PerfectScrollbar className="sisa-perfect-scrollbar">
              <Table hover>
                <thead className="text-primary">
                  <tr>
                    <th style={{ width: '95%' }}>Nome</th>
                    <th className="text-center">Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    this.state.pais.map((pai, idx) => <tr key={idx}>
                      <td>{pai.nome}</td>
                      <td className="text-center">
                        <Button
                          id={`btnRemover${idx}`}
                          className="btn-icon"
                          color="danger"
                          size="sm"
                          onClick={() => {
                            this.confirmarRemocaoPais(pai.id);
                          }}
                          type="button">
                          <i className="fa fa-times" />
                        </Button>
                        <UncontrolledTooltip placement="bottom" target={`btnRemover${idx}`} delay={0}>
                          Excluir
                        </UncontrolledTooltip>
                      </td>
                    </tr>)
                  }
                </tbody>
              </Table>
            </PerfectScrollbar> : <><div style={{ margin: 50 }} className="text-center">Nenhum pai ou mãe vinculado</div><br /></>
          }
        </Col>
      </Row>
      <Row>
        <Col md="6" className="text-left">
          <Button color="primary" onClick={() => this.props.history.goBack()} >
            Voltar
          </Button>
        </Col>
      </Row>
    </> : <div align="center" style={{ margin: 50 }}>
      <Loader
        type="Oval"
        color="#053d7c"
        height="50"
        width="50" />
    </div>
  }
}

class Dependencias extends React.Component {
  constructor(props) {
    super(props);

    this.situacoesDependencia = [
      Dependencia.ABERTA,
      Dependencia.CUMPRIDA,
    ];

    this.state = {}

    this.dependenciasService = new DependenciasService();

    this.atualizarSituacaoDependencia = this.atualizarSituacaoDependencia.bind(this);
  }

  async componentDidMount() {
    try {
      const dependencias = await this.dependenciasService
        .carregarDependencias(this.props.idAluno);

      this.setState({
        dependencias,
      });
    } catch (e) {
      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao buscar dependências do aluno.',
      });
    }
  }

  async atualizarSituacaoDependencia(dependencia) {
    this.props.callbackMessage({
      showAlert: false,
      showLoaderModal: true,
    });

    try {
      await this.dependenciasService
        .atualizarSituacaoDependencia(dependencia.id, dependencia.situacao);

      this.props.callbackMessage({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Situação da dependência atualizada com sucesso',
      });
    } catch (msg) {
      if (this.umounted) return;
      this.props.callbackMessage({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao atualizar situação da dependência',
      });
    }
  }

  render() {
    return this.state.dependencias ? <>
      <Row>
        <Col md="12">
          {
            this.state.dependencias.length > 0 ? <PerfectScrollbar className="sisa-perfect-scrollbar">
              <Table>
                <thead className="text-primary">
                  <tr>
                    <th className="text-center" style={{ width: '10%' }}>Ano</th>
                    <th className="text-center" style={{ width: '25%' }}>Escola</th>
                    <th className="text-center" style={{ width: '25%' }}>Curso</th>
                    <th className="text-center" style={{ width: '10%' }}>Materia</th>
                    <th className="text-center" style={{ width: '10%' }}>Turma</th>
                    <th className="text-center" style={{ width: '15%' }}>Situação</th>
                    <th className="text-center" style={{ width: '5%' }}>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    this.state.dependencias.map((dependencia, idx) => <tr key={idx}>
                      <td className="text-center">{dependencia.ano}</td>
                      <td className="text-center">{dependencia.escola}</td>
                      <td className="text-center">{dependencia.curso}</td>
                      <td className="text-center">{dependencia.materia.nome}</td>
                      <td className="text-center">{dependencia.turma}</td>
                      <td className="text-center">
                        <Select
                          noOptionsMessage={() => 'Nenhuma entrada'}
                          className={'react-select primary'}
                          classNamePrefix="react-select"
                          value={this.situacoesDependencia[dependencia.situacao]}
                          onChange={(event) => {
                            Object.assign(dependencia, { situacao: event.value });
                            this.forceUpdate();
                          }}
                          options={this.situacoesDependencia}
                          placeholder="Situação..."
                        />
                      </td>
                      <td className='td-actions text-center'>
                        <div>
                          <Button
                            id={`btnSalvarItem${idx}`}
                            className="btn-icon"
                            color="success"
                            size="sm"
                            onClick={() => this.atualizarSituacaoDependencia(dependencia)}
                            type="button">
                            <i className="fa fa-save" />
                          </Button>
                          <UncontrolledTooltip placement="bottom" target={`btnSalvarItem${idx}`} delay={0}>
                            Salvar
                          </UncontrolledTooltip>
                        </div>
                      </td>
                    </tr>)
                  }
                </tbody>
              </Table>
            </PerfectScrollbar>
              : <div align="center" style={{ margin: 50 }}>
                Nenhuma dependência cadastrada
              </div>
          }
        </Col>
      </Row>
      <Row>
        <Col md="6" className="text-left">
          <Button color="primary" onClick={() => this.props.history.goBack()} >
            Voltar
          </Button>
        </Col>
      </Row>
    </> : <div align="center" style={{ margin: 50 }}>
      <Loader
        type="Oval"
        color="#053d7c"
        height="50"
        width="50" />
    </div>
  }
}

class Acesso extends React.Component {
  constructor(props) {
    super(props);

    this.papeis = [
      Papel.GESTOR,
      Papel.DIRETOR,
      Papel.PROFESSOR,
      Papel.ADMINISTRADOR,
      Papel.ALUNO,
      Papel.PAIS,
      Papel.COORDENADOR_PEDAGOGICO,
      Papel.TREINAMENTO,
      Papel.SECRETARIO,
    ];

    this.situacoes = [
      SituacaoVinculo.ATIVO,
      SituacaoVinculo.INATIVO,
    ];

    this.state = {
      aluno: props.aluno,
    }

    this.vinculoService = new VinculoService();

    this.atualizarVinculo = this.atualizarVinculo.bind(this);

  }

  async atualizarVinculo(vinculo) {
    this.props.callbackMessage({ showAlert: false });
    try {
      await this.vinculoService.atualizarVinculo(vinculo);

      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Vínculo salvo com sucesso',
      });
    } catch (e) {
      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao salvar vínculo',
      });
    }
  }

  render() {
    return <>
      <Row>
        <Col md="12">
          <PerfectScrollbar className="sisa-perfect-scrollbar">
            <Table>
              <thead className="text-primary">
                <tr>
                  <th style={{ width: '15%' }}>Papel</th>
                  <th style={{ width: '15%' }}>Login</th>
                  <th style={{ width: '40%' }}>Email</th>
                  <th style={{ width: '20%' }}>Acesso</th>
                  <th style={{ width: '10%' }} className='text-right'>Ações</th>
                </tr>
              </thead>
              <tbody>
                {
                  this.state.aluno.vinculos.filter(
                    (vinculo) => vinculo.papel === Papel.ALUNO.value,
                  ).map(
                    (vinculo, idx) => (
                      <tr key={idx}>
                        <td>{this.papeis[vinculo.papel].label}</td>
                        <td>{this.state.aluno.credenciais.login}</td>
                        <td>{this.state.aluno.credenciais.email}</td>
                        <td>
                          <Select
                            noOptionsMessage={() => 'Nenhuma entrada'}
                            className={'react-select primary'}
                            classNamePrefix="react-select"
                            value={this.situacoes[vinculo.situacao]}
                            onChange={(event) => {
                              Object.assign(vinculo, { situacao: event.value });
                              this.setState({ vinculos: this.state.vinculos });
                            }}
                            options={this.situacoes}
                            placeholder="Situação..."
                          />
                        </td>
                        <td className='td-actions text-right'>
                          <div>
                            <Button
                              id={`btnEditarItem${idx}`}
                              className="btn-icon"
                              color="success"
                              size="sm"
                              onClick={() => this.atualizarVinculo(vinculo)}
                              type="button">
                              <i className="fa fa-save" />
                            </Button>
                            <UncontrolledTooltip placement="bottom" target={`btnEditarItem${idx}`} delay={0}>
                              Salvar
                            </UncontrolledTooltip>
                          </div>
                        </td>
                      </tr>
                    ),
                  )
                }
              </tbody>
            </Table>
          </PerfectScrollbar>
        </Col>
      </Row>
      <Row>
        <Col md="6" className="text-left">
          <Button color="primary" onClick={() => this.props.history.goBak()} >
            Voltar
          </Button>
        </Col>
      </Row>
    </>;
  }
}

class LaudoNEE extends React.Component {
  constructor(props) {
    super(props);

    this.fileInputRef = React.createRef();

    this.fileChange = this.fileChange.bind(this);

    this.state = {
      idAluno: props.alunoSelecionado.id,
      alunoSelecionado: props.alunoSelecionado,
      tipoPne: props.alunoSelecionado.tipoPne || '',
      laudoPne: props.alunoSelecionado.laudoPne || '',
      arquivos: [],
    }

    this.usuarioService = new UsuarioService();

    this.atualizarAluno = this.atualizarAluno.bind(this);
  }

  async componentDidMount() {
    await this.carregarArquivosLaudoNEE();
  }

  async carregarArquivosLaudoNEE() {
    try {
      const arquivos = await this.usuarioService
        .carregarArquivosLaudoNEE(this.state.idAluno);

      this.setState({ arquivos });
    } catch (e) {
      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao carregar arquivos de laudo.',
      });

    }
  }

  async atualizarAluno() {
    this.props.callbackMessage({ showAlert: false });
    try {
      Object.assign(this.state.alunoSelecionado, {
        laudoPne: this.state.laudoPne,
        tipoPne: this.state.tipoPne,
      });

      this.setState({
        showLoaderModal: true,
        loaderModalText: 'Atualizando informações do laudo...',
      });

      await this.usuarioService
        .atualizarInformacoesLaudoNEEAluno(this.state.alunoSelecionado);

      this.setState({
        showLoaderModal: false,
      });

      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Laudo atualizado com sucesso',
      });
    } catch (e) {
      this.setState({ showLoaderModal: false });

      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao atualizar laudo',
      });
    }
  }

  fileChange(e) {
    this.props.callbackMessage({ showAlert: false });

    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];

    const re = /(?:\.([^.]+))?$/;
    const ext = re.exec(file.name)[1];

    if (!ext) {
      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao carregar arquivo selecionado. Não é possível enviar arquivos sem extensão.',
      });
      return;
    }

    if (file) {
      reader.onloadend = async () => {
        if (file.size > 10000000) {
          this.props.callbackMessage({
            showAlert: true,
            alertColor: 'danger',
            alertMsg: 'Erro: Arquivo muito grande. O tamanho máximo para arquivo é 10MB',
          });
        } else {
          const regexp = /^data:((\w+)\/(.+));base64,(.+)$/g;
          const matches = regexp.exec(reader.result);

          if (matches.length !== 5) {
            this.setState({ showLoaderModal: false });
            this.props.callbackMessage({
              showAlert: true,
              alertColor: 'danger',
              alertMsg: 'Erro ao carregar arquivo selecionado.',
            });
          } else {
            try {
              this.setState({
                showLoaderModal: true,
                loaderModalText: 'Fazendo upload...',
              });

              const arquivo = {
                nome: file.name,
                conteudo: matches[4],
                extensao: ext,
                tipo: matches[1],
              };

              await this.usuarioService.salvarArquivoLaudoNEE(
                this.state.idAluno, arquivo,
              );

              await this.carregarArquivosLaudoNEE();

              this.setState({ showLoaderModal: false });
              this.props.callbackMessage({
                showAlert: true,
                alertColor: 'success',
                alertMsg: 'Arquivo salvo com sucesso.',
              });
            } catch (msg) {
              this.setState({ showLoaderModal: false });
              this.props.callbackMessage({
                showAlert: true,
                alertColor: 'danger',
                alertMsg: msg || 'Erro ao salvar arquivo selecionado.',
              });
            }
          }
        }
        this.fileInputRef.current.value = '';
      };
      reader.readAsDataURL(file);
    }
  }

  confirmarRemocaoArquivoLaudoNEE(arquivo) {
    this.setState({ arquivoLaudoNEESelecionado: arquivo, showConfirmModal: true });
  }

  async removerArquivoLaudoNEE() {
    this.props.callbackMessage({ showAlert: false });

    try {
      this.setState({
        showLoaderModal: true,
        loaderModalText: 'Removendo arquivo...',
      });

      await this.usuarioService
        .removerArquivoLaudoNEE(this.state.arquivoLaudoNEESelecionado.id);

      this.setState({ showLoaderModal: false });
      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Arquivo removido com sucesso',
      });

      await this.carregarArquivosLaudoNEE();
    } catch (msg) {
      this.setState({ showLoaderModal: false });

      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao remover arquivo',
      });
    }
  }

  render() {
    return <>
      <div className="fileinput text-center">
        <input
          type="file"
          onChange={this.fileChange}
          ref={this.fileInputRef} />
      </div>
      <Row>
        <Col md="8">
          <Row>
            <Col>
              <FormGroup>
                <label>Tipo de necessidade educacional específica</label>
                <Input
                  value={this.state.tipoPne}
                  type="text"
                  maxLength={100}
                  onChange={(evt) => {
                    this.setState({ tipoPne: evt.target.value });
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <label>Laudo médico</label>
                <TextareaAutosize
                  className="autoheight"
                  value={this.state.laudoPne}
                  onChange={(evt) => {
                    this.setState({ laudoPne: evt.target.value });
                  }} />
              </FormGroup>
            </Col>
          </Row>
        </Col>
        <Col md="4">
          <PerfectScrollbar className="sisa-perfect-scrollbar">
            <Table hover>
              <thead className="text-primary">
                <tr>
                  <th>Arquivo</th>
                </tr>
              </thead>
              <tbody>
                {
                  this.state.arquivos.map((arquivo, idx) => <tr className="tr-clickable" key={idx}>
                    <td style={{ width: '90%' }} onClick={() => window.open(arquivo.url)}>{arquivo.nome}</td>
                    <td>
                      <>
                        <Button
                          id={`btnRemover${idx}`}
                          className="btn-icon"
                          color="danger"
                          size="sm"
                          onClick={(evt) => {
                            evt.stopPropagation();
                            this.confirmarRemocaoArquivoLaudoNEE(arquivo);
                          }}
                          type="button">
                          <i className="fa fa-times" />
                        </Button>
                        <UncontrolledTooltip placement="bottom" target={`btnRemover${idx}`} delay={0}>
                          Excluir
                        </UncontrolledTooltip>
                      </>
                    </td>
                  </tr>)
                }
              </tbody>
            </Table>
          </PerfectScrollbar>
        </Col>
      </Row>
      <Row>
        <Col md="8">
          <Row>
            <Col md="6" className="text-left">
              <Button color="primary" onClick={() => this.props.history.goBack()} >
                Voltar
              </Button>
            </Col>
            <Col md="6" className="text-right">
              <Button
                color="primary"
                onClick={() => this.atualizarAluno()} >
                Atualizar laudo
              </Button>
            </Col>
          </Row>
        </Col>
        <Col md="4" className="text-right">
          <Button
            color="primary"
            onClick={() => this.fileInputRef.current.click()} >
            Anexar laudo
          </Button>
        </Col>
      </Row>
      <ConfirmModal
        isOpen={this.state.showConfirmModal}
        callback={(confirm) => {
          this.setState({ showConfirmModal: false });
          if (confirm) {
            this.removerArquivoLaudoNEE();
          } else {
            this.setState({ arquivoLaudoNEESelecionado: null });
          }
        }}
        text='Confirme a exclusão do arquivo' />
      <LoaderModal
        isOpen={this.state.showLoaderModal}
        text={this.state.loaderModalText} />
    </>;
  }
}

class Transportes extends React.Component {
  constructor(props) {
    super(props);

    this.state = {}

    this.transportesService = new TransportesService();
  }

  async componentDidMount() {
    try {
      const transportes = await this.transportesService
        .carregarTodosTransportesAluno(this.props.idAluno);

      this.setState({
        transportes,
      });
    } catch (e) {
      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao buscar transportes do aluno.',
      });
    }
  }

  render() {
    return this.state.transportes ? <>
      <Row>
        <Col>
          {
            this.state.transportes.length === 0 ? <div style={{ margin: 50 }} className="text-center">
              Nenhum registro de transporte <br />
            </div>
              : <PerfectScrollbar className="sisa-perfect-scrollbar">
                <Table>
                  <thead className="text-primary">
                    <tr>
                      <th style={{ width: '10%' }}>Ano letivo</th>
                      <th style={{ width: '15%' }}>Placa</th>
                      <th style={{ width: '75%' }}>Itinerário</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      this.state.transportes.map((t, idx) => <tr key={idx}>
                        <td>{t.ano}</td>
                        <td>{t.transporte.placa}</td>
                        <td>{t.transporte.itinerario}</td>
                      </tr>)
                    }
                  </tbody>
                </Table>
              </PerfectScrollbar>
          }
        </Col>
      </Row>
      <Row>
        <Col md="6" className="text-left">
          <Button color="primary" onClick={() => this.props.history.goBack()} >
            Voltar
          </Button>
        </Col>
      </Row>
    </> : <div align="center" style={{ margin: 50 }}>
      <Loader
        type="Oval"
        color="#053d7c"
        height="50"
        width="50" />
    </div>
  }
}

class MChatAluno extends React.Component {
  constructor(props) {
    super(props);

    this.situacoes = [
      SituacaoMChatUsuario.PASSA,
      SituacaoMChatUsuario.FALHA,
      SituacaoMChatUsuario.NAO_INFORMADA,
    ];

    this.state = {};

    this.mChatsService = new MChatsService();
  }

  async componentDidMount() {
    try {
      const mchats = await this.mChatsService.carregarMChats();

      const fn = (mchat) => new Promise((resolve) => resolve(
        this.mChatsService.carregarSituacaoMChatUsuarioEnte(mchat.id, this.props.idAluno)
      ));

      const actions = mchats.map(fn);

      const situacoesMChat = await Promise.all(actions);

      this.setState({
        mchats,
        situacoesMChat,
      });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  async salvarSituacoesMChats() {
    try {
      this.props.callbackMessage({
        showAlert: false,
        loaderModalText: 'Salvando ficha M-Chat...',
        showLoaderModal: true,
      });

      await this.mChatsService.salvarSituacoesMChats(
        this.props.idAluno,
        this.state.situacoesMChat,
      );

      this.props.callbackMessage({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Ficha M-Chat salva com sucesso.',
      });
    } catch (msg) {
      this.props.callbackMessage({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao salvar ficha M-Chat.',
      });
    }
  }

  render() {
    if (this.state.erro) return <div align="center" style={{ margin: 50 }}>
      Erro ao buscar informações de M-Chats
    </div>

    return this.state.mchats ? <>
      <br />
      <Row>
        <Col md="12">
          <UncontrolledDropdown>
            <Table>
              <thead className="text-primary">
                <tr>
                  <th style={{ width: '40%' }}>Descrição</th>
                  <th style={{ width: '40%' }}>Exemplo</th>
                  <th className="text-center" style={{ width: '20%' }}>Situação</th>
                </tr>
              </thead>
              <tbody>
                {
                  this.state.situacoesMChat.map((situacaoMChat, idx) => <tr key={idx}>
                    <td>{situacaoMChat.mChat.descricao}</td>
                    <td>{situacaoMChat.mChat.exemplo}</td>
                    <td className="text-center">
                      <UncontrolledDropdown>
                        <DropdownToggle
                          aria-haspopup={true}
                          data-toggle="collapse"
                          id="acoesMChatsDropdownMenuLink"
                          onClick={(evt) => evt.stopPropagation()}
                          nav>
                          <Button color={this.situacoes[situacaoMChat.situacao].color}>
                            {this.situacoes[situacaoMChat.situacao].label} <b className="caret" />
                          </Button>
                        </DropdownToggle>
                        <DropdownMenu aria-labelledby="acoesMChatsDropdownMenuLink" right>
                          <DropdownItem
                            onClick={() => {
                              Object.assign(situacaoMChat, {
                                ...situacaoMChat,
                                situacao: SituacaoMChatUsuario.PASSA.value
                              });
                              this.forceUpdate();
                            }}>
                            {SituacaoMChatUsuario.PASSA.label}
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              Object.assign(situacaoMChat, {
                                ...situacaoMChat,
                                situacao: SituacaoMChatUsuario.FALHA.value
                              });
                              this.forceUpdate();
                            }}>
                            {SituacaoMChatUsuario.FALHA.label}
                          </DropdownItem>
                          <DropdownItem
                            onClick={() => {
                              Object.assign(situacaoMChat, {
                                ...situacaoMChat,
                                situacao: SituacaoMChatUsuario.NAO_INFORMADA.value
                              });
                              this.forceUpdate();
                            }}>
                            {SituacaoMChatUsuario.NAO_INFORMADA.label}
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </td>
                  </tr>)
                }
              </tbody>
            </Table>
          </UncontrolledDropdown>
        </Col>
      </Row>
      <Row>
        <Col className="text-left">
          <Button
            color="primary"
            onClick={() => this.props.history.goBack()} >
            Voltar
          </Button>
        </Col>
        <Col md="9" className="text-right">
          <Button
            color="primary"
            onClick={() => this.salvarSituacoesMChats()}>
            Salvar ficha M-Chat
          </Button>
        </Col>
      </Row>
    </> : <div align="center" style={{ margin: 50 }}>
      <Loader
        type="Oval"
        color="#053d7c"
        height="50"
        width="50" />
    </div>;
  }
}

class HistoricoAluno extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      erro: false,
      showAlert: false,
    }

    this.usuarioService = new UsuarioService();
    this.entesService = new EntesService();
  }

  async componentDidMount() {
    try {
      const dadosEnte = await this.entesService
        .carregarDadosEnte();

      const aluno = await this.usuarioService
        .carregarUsuario(this.props.match.params.idAluno);

      const vinculos = await this.usuarioService.carregarVinculosUsuario(aluno.id);
      Object.assign(aluno, { vinculos });

      const foto = await this.usuarioService
        .carregarFoto(aluno.id);

      this.setState({
        dadosEnte,
        alunoSelecionado: aluno,
        foto,
      });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  conteudoPagina() {
    return !this.state.alunoSelecionado ? <Card>
      <div align="center" style={{ margin: 50 }}>
        <Loader
          type="Oval"
          color="#053d7c"
          height="50"
          width="50" />
      </div>
    </Card> : <Row>
      <Col md="12">
        <Alert
          color={this.state.alertColor}
          isOpen={this.state.showAlert}
          toggle={() => { this.setState({ showAlert: false }); }}>
          {this.state.alertMsg}
        </Alert>
        <Breadcrumb>
          <BreadcrumbItem><Link to={`${this.props.layout}/alunos`}>Alunos</Link></BreadcrumbItem>
          <BreadcrumbItem active>{this.state.alunoSelecionado.nome}</BreadcrumbItem>
        </Breadcrumb>
        <Card>
          <CardBody>
            <Row>
              <Col style={{ marginBottom: 20 }}>
                <FormGroup className={'has-label'}>
                  <div>
                    <img
                      alt="..."
                      src={`data:image/jpeg;base64,${this.state.foto}`}
                      width={120}
                      height={160}
                    />
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <Tabs>
              <TabList>
                <Tab>Informações do aluno</Tab>
                {this.state.dadosEnte.alunosPorEscola && <Tab>Escolas</Tab>}
                <Tab>Matrículas</Tab>
                <Tab>Observações</Tab>
                <Tab>Histórico</Tab>
                {this.state.dadosEnte.acessoPais && <Tab>Pais</Tab>}
                <Tab>Dependências</Tab>
                {this.state.dadosEnte.acessoAlunos && this.state.alunoSelecionado.ehAlunoAtivo && <Tab>Acesso</Tab>}
                {this.state.alunoSelecionado.pne && <Tab>Laudo NEE</Tab>}
                <Tab>Transporte</Tab>
                <Tab>M-Chat</Tab>
              </TabList>
              <TabPanel>
                <Informacoes
                  history={this.props.history}
                  alunoSelecionado={this.state.alunoSelecionado}
                />
              </TabPanel>
              {
                this.state.dadosEnte.alunosPorEscola && <TabPanel>
                  <Escolas
                    idAluno={this.state.alunoSelecionado.id}
                    history={this.props.history}
                    callbackMessage={(msg) => this.setState(msg)}
                  />
                </TabPanel>
              }
              <TabPanel>
                <Matriculas
                  idAluno={this.state.alunoSelecionado.id}
                  history={this.props.history}
                  layout={this.props.layout}
                  callbackMessage={(msg) => this.setState(msg)}
                />
              </TabPanel>
              <TabPanel>
                <Observacoes
                  idAluno={this.state.alunoSelecionado.id}
                  history={this.props.history}
                  callbackMessage={(msg) => this.setState(msg)}
                />
              </TabPanel>
              <TabPanel>
                <Historico
                  idAluno={this.state.alunoSelecionado.id}
                  idVinculo={this.props.dadosUsuario.idVinculo}
                  history={this.props.history}
                  layout={this.props.layout}
                  role={this.props.role}
                  dadosEnte={this.state.dadosEnte}
                  callbackMessage={(msg) => this.setState(msg)}
                />
              </TabPanel>
              {
                this.state.dadosEnte.acessoPais && <TabPanel>
                  <Pais
                    idAluno={this.state.alunoSelecionado.id}
                    history={this.props.history}
                    callbackMessage={(msg) => this.setState(msg)}
                  />
                </TabPanel>
              }
              <TabPanel>
                <Dependencias
                  idAluno={this.state.alunoSelecionado.id}
                  history={this.props.history}
                  callbackMessage={(msg) => this.setState(msg)}
                />
              </TabPanel>
              {
                this.state.dadosEnte.acessoAlunos && this.state.alunoSelecionado.ehAlunoAtivo && <TabPanel>
                  <Acesso
                    aluno={this.state.alunoSelecionado}
                    history={this.props.history}
                    callbackMessage={(msg) => this.setState(msg)}
                  />
                </TabPanel>
              }
              {
                this.state.alunoSelecionado.pne && <TabPanel>
                  <LaudoNEE
                    alunoSelecionado={this.state.alunoSelecionado}
                    history={this.props.history}
                    callbackMessage={(msg) => this.setState(msg)}
                  />
                </TabPanel>
              }
              <TabPanel>
                <Transportes
                  idAluno={this.state.alunoSelecionado.id}
                  history={this.props.history}
                  callbackMessage={(msg) => this.setState(msg)}
                />
              </TabPanel>
              <TabPanel>
                <MChatAluno
                  idAluno={this.state.alunoSelecionado.id}
                  history={this.props.history}
                  callbackMessage={(msg) => this.setState(msg)}
                />
              </TabPanel>
            </Tabs>
          </CardBody>
        </Card>
      </Col>
    </Row>;
  }

  render() {
    return (
      <div className="content">
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
        <ConfirmModal
          isOpen={this.state.showConfirmModal}
          callback={(confirm) => {
            this.setState({ showConfirmModal: false });
            if (confirm) {
              this.state.funcaoRemocao();
            }
          }}
          text={this.state.textoRemocao} />
        <LoaderModal
          isOpen={this.state.showLoaderModal}
          text={this.state.loaderModalText} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ dadosUsuario: state.dadosUsuario });

export default connect(mapStateToProps)(HistoricoAluno);
