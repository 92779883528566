import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import {
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Form,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  FormGroup,
  Input,
  Button,
  Table,
  UncontrolledTooltip
} from 'reactstrap';

import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';

import Loader from 'react-loader-spinner';
import TextareaAutosize from 'react-textarea-autosize';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';

import LoginService from 'services/LoginService';
import CursosService from 'services/CursosService';
import EstruturasCurricularesService from 'services/EstruturasCurricularesService';
import MateriasService from 'services/MateriasService';
import CompetenciasMunicipaisService from 'services/CompetenciasMunicipaisService';

import SituacaoEstruturaCurricular from 'assets/csv/situacoes-estruturas-curriculares.json';
import CicloEnsino from 'assets/csv/ciclos-ensino.json';

class CompetenciasMunicipais extends React.Component {
  constructor(props) {
    super(props);

    this.ciclosEnsino = [
      CicloEnsino.ENSINO_INFANTIL,
      CicloEnsino.ENSINO_FUNDAMENTAL_1,
      CicloEnsino.ENSINO_FUNDAMENTAL_2,
      CicloEnsino.ENSINO_MEDIO,
      CicloEnsino.ENSINO_TECNICO,
      CicloEnsino.EJA,
      CicloEnsino.OUTRO,
      CicloEnsino.CORRECAO_FLUXO,
    ];

    this.situacoes = [
      SituacaoEstruturaCurricular.ATIVA,
      SituacaoEstruturaCurricular.INATIVA,
    ];

    this.loginService = new LoginService();
    this.cursosService = new CursosService();
    this.estruturasCurricularesService = new EstruturasCurricularesService();
    this.materiasService = new MateriasService();
    this.competenciasMunicipaisService = new CompetenciasMunicipaisService();

    this.state = {
      showAlert: false,

      buttonDisabled: false,
    };
  }

  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);

    try {
      const curso = await this.cursosService
        .carregarCurso(this.props.match.params.idCurso);

      const estruturaCurricular = await this.estruturasCurricularesService
        .carregarEstruturaCurricular(this.props.match.params.idEstruturaCurricular);

      const materia = await this.materiasService
        .carregarMateria(this.props.match.params.idMateria);

      await this.carregarCompetenciasMunicipais();

      this.setState({
        curso,
        estruturaCurricular,
        materia,
      });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  async carregarCompetenciasMunicipais() {
    const competenciasMunicipais = await this.competenciasMunicipaisService
      .carregarCompetenciasMunicipaisPorMateria(this.props.match.params.idMateria);

    this.setState({
      competenciasMunicipais,
    });
  }

  removerCompetenciaMunicipal(competenciaMunicipal) {
    const competenciasMunicipais = this.state.competenciasMunicipais.filter(
      (competenciaMunicipal2) => competenciaMunicipal !== competenciaMunicipal2,
    );

    this.setState({ competenciasMunicipais });
  }

  validarCampos() {
    let ret = true;

    this.state.competenciasMunicipais.forEach((competenciaMunicipal) => {
      if (!competenciaMunicipal.descricao) {
        Object.assign(competenciaMunicipal, { descricaoState: 'has-danger' });
        ret = false;
      }
    });

    if (!ret) this.forceUpdate();

    return ret;
  }

  async salvarCompetenciasMunicipais() {
    if (!this.validarCampos()) return;

    this.setState({
      buttonDisabled: true,
      showAlert: false,
      loaderModalText: 'Salvando competências do município...',
    });

    try {
      await this.competenciasMunicipaisService
        .salvarCompetenciasMunicipais(this.props.match.params.idMateria, this.state.competenciasMunicipais);

      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Competências municipais salvas com sucesso',
      });

      await this.carregarCompetenciasMunicipais();
    } catch (e) {
      if (this.umounted) return;

      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: e || 'Erro ao salvar competências municipais',
      });
    }
  }

  conteudoPagina() {
    return this.state.curso
      ? <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to="/gestor/cursos">Cursos</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`/gestor/cursos/${this.props.match.params.idCurso}`}>Curso</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`/gestor/cursos/${this.props.match.params.idCurso}/estruturascurriculares/${this.props.match.params.idEstruturaCurricular}`}>Estrutura curricular</Link></BreadcrumbItem>
            <BreadcrumbItem active>Competências municipais para {this.state.materia.nome}
            </BreadcrumbItem>
          </Breadcrumb>
          <Form onSubmit={this.onSubmit}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Competências municipais propostas para os estudantes de {this.state.materia.nome} no {this.state.curso.nome}</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="4">
                    <FormGroup className={`has-label ${this.state.nomeState}`}>
                      <label>Estrucura curricular</label>
                      <Input
                        disabled
                        value={this.state.estruturaCurricular.nome}
                        type="text"
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup className={'has-label'}>
                      <label>Curso</label>
                      <Input
                        value={this.state.curso.nome}
                        type="text"
                        disabled
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup className={'has-label'}>
                      <label>Ciclo</label>
                      <Input
                        value={this.ciclosEnsino[this.state.curso.cicloEnsino].label}
                        type="text"
                        disabled
                      />
                    </FormGroup>
                  </Col>
                  <Col md="2">
                    <FormGroup className={'has-label'}>
                      <label>Situação</label>
                      <Input
                        value={this.situacoes[this.state.estruturaCurricular.situacao].label}
                        type="text"
                        disabled
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button
                      color="primary"
                      onClick={() => {
                        this.state.competenciasMunicipais.push({
                          descricao: '',
                          materia: { id: this.props.match.params.idMateria },
                          ordem: this.state.competenciasMunicipais.length,
                        });
                        this.forceUpdate();
                      }}>
                      Adicionar competência municipal
                    </Button>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <PerfectScrollbar className="sisa-perfect-scrollbar">
                    <Table>
                      <thead className="text-primary">
                        <tr>
                          <th style={{ width: '80%' }}>Descrição</th>
                          <th className="text-left" style={{ paddingLeft: 20, width: '15%' }}>Ação</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          this.state.competenciasMunicipais.map((competenciaMunicipal, index) => (
                            <tr key={index}>
                              <td>
                                <FormGroup className={`has-label ${competenciaMunicipal.descricaoState}`}>
                                  <TextareaAutosize
                                    className={`autoheight-curriculo ${competenciaMunicipal.descricaoState}`}
                                    value={competenciaMunicipal.descricao}
                                    onChange={(event) => {
                                      Object.assign(competenciaMunicipal,
                                        {
                                          descricao: event.target.value,
                                          descricaoState: 'primary',
                                        });
                                      this.forceUpdate();
                                    }} />
                                  {competenciaMunicipal.descricaoState === 'has-danger' ? (
                                    <label className="error">
                                      Informe a descrição da competência municipal.
                                    </label>
                                  ) : null}
                                </FormGroup>
                              </td>
                              <td className="text-left" style={{ verticalAlign: 'top', paddingTop: '17px', paddingLeft: 20 }}>
                                <Button
                                  id={`btnRemoverHabilidade${index}`}
                                  className="btn-icon"
                                  color="danger"
                                  size="sm"
                                  onClick={() => this.removerCompetenciaMunicipal(competenciaMunicipal)}
                                  type="button">
                                  <i className="fa fa-times" />
                                </Button>
                                <UncontrolledTooltip placement="bottom" target={`btnRemoverHabilidade${index}`} delay={0}>
                                  Remover competência municipal
                                </UncontrolledTooltip>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                              </td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </Table>
                    </PerfectScrollbar>
                  </Col>
                </Row>

              </CardBody>
              <CardFooter>
                <Row>
                  <Col md="6" className="text-left">
                    <Button
                      color="primary" disabled={this.state.buttonDisabled}
                      onClick={() => this.props.history.goBack()} >
                      Voltar
                    </Button>
                  </Col>
                  <Col md="6" className="text-right">
                    <Button color="primary" disabled={this.state.buttonDisabled}
                      onClick={() => this.salvarCompetenciasMunicipais()} >
                      Salvar competências municipais
                    </Button>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Form>
        </Col>
      </Row> : <Card>
        <div align="center" style={{ margin: 50 }}>
          <Loader
            type="Oval"
            color="#053d7c"
            height="50"
            width="50" />
        </div>
      </Card>;
  }

  render() {
    return (
      <div className="content">
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
        <LoaderModal
          isOpen={this.state.buttonDisabled}
          text={this.state.loaderModalText} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ dadosUsuario: state.dadosUsuario });

export default connect(mapStateToProps)(CompetenciasMunicipais);