import React from 'react';

import {
  FormGroup,
  Input,
  Form,
  Row,
  Col,
  Button,
} from 'reactstrap';

import Loader from 'react-loader-spinner';

import LoaderModal from 'views/components/LoaderModal';

import EscolasService from 'services/EscolasService';

import Papel from 'assets/csv/papeis.json';

class EscolaCardapio extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      bloqueado: this.props.role !== Papel.GESTOR.value
        && this.props.role !== Papel.DIRETOR.value
        && this.props.role !== Papel.SECRETARIO.value,
    };

    this.escolasService = new EscolasService();

    this.atualizarCardapio = this.atualizarCardapio.bind(this);
  }

  componentWillUnmount() {
    this.escolasService.abortPedingRequests();
  }

  async componentDidMount() {
    try {
      const idEscola = this.props.idEscola || this.props.match.params.idEscola;

      const cardapio = await this.escolasService
        .carregarCardapio(idEscola);

      this.setState({ cardapio });
    } catch (e) {
      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao buscar cardápio da escola',
      });
    }
  }

  async atualizarCardapio(e) {
    e.preventDefault();

    try {
      this.setState({ buttonDisabled: true });

      await this.escolasService
        .atualizarCardapio(this.props.match.params.idEscola,
          this.state.cardapio);

      this.setState({ buttonDisabled: false });

      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Cardápio atualizado com sucesso',
      });
    } catch (msg) {
      this.setState({ buttonDisabled: false });

      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao atualizar cardápio',
      });
    }
  }

  render() {
    return (
      this.state.cardapio ? <>
        <Form onSubmit={this.atualizarCardapio}>
          <Row>
            <Col md="12">
              <FormGroup className={'has-label'}>
                <label>Segunda</label>
                <Input
                  disabled={this.state.bloqueado}
                  type="text"
                  maxLength="200"
                  value={this.state.cardapio.segunda}
                  onChange={(evt) => this.setState({
                    cardapio: { ...this.state.cardapio, segunda: evt.target.value },
                  })}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup className={'has-label'}>
                <label>Terça</label>
                <Input
                  disabled={this.state.bloqueado}
                  type="text"
                  maxLength="200"
                  value={this.state.cardapio.terca}
                  onChange={(evt) => this.setState({
                    cardapio: { ...this.state.cardapio, terca: evt.target.value },
                  })}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup className={'has-label'}>
                <label>Quarta</label>
                <Input
                  disabled={this.state.bloqueado}
                  type="text"
                  maxLength="200"
                  value={this.state.cardapio.quarta}
                  onChange={(evt) => this.setState({
                    cardapio: { ...this.state.cardapio, quarta: evt.target.value },
                  })}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup className={'has-label'}>
                <label>Quinta</label>
                <Input
                  disabled={this.state.bloqueado}
                  type="text"
                  maxLength="200"
                  value={this.state.cardapio.quinta}
                  onChange={(evt) => this.setState({
                    cardapio: { ...this.state.cardapio, quinta: evt.target.value },
                  })}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup className={'has-label'}>
                <label>Sexta</label>
                <Input
                  disabled={this.state.bloqueado}
                  type="text"
                  maxLength="200"
                  value={this.state.cardapio.sexta}
                  onChange={(evt) => this.setState({
                    cardapio: { ...this.state.cardapio, sexta: evt.target.value },
                  })}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <FormGroup className={'has-label'}>
                <label>Sábado</label>
                <Input
                  disabled={this.state.bloqueado}
                  type="text"
                  maxLength="200"
                  value={this.state.cardapio.sabado}
                  onChange={(evt) => this.setState({
                    cardapio: { ...this.state.cardapio, sabado: evt.target.value },
                  })}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            {
              this.props.role !== Papel.ALUNO.value && 
              this.props.role !== Papel.PAIS.value && <Col md="6" className="text-left">
                <Button
                  color="primary"
                  disabled={this.state.showLoaderModal}
                  onClick={() => this.props.history.goBack()} >
                  Voltar
                </Button>
              </Col>
            }
            {
              !this.state.bloqueado && <Col className="text-right">
                <Button type="submit" color="primary" disabled={this.state.buttonDisabled} >
                  Atualizar cardápio
                </Button>
              </Col>
            }
          </Row>
        </Form>
        <LoaderModal
          isOpen={this.state.buttonDisabled}
          text="Atualizando cardápio..." />
      </> : <div align="center" style={{ margin: 50 }}>
        <Loader
          type="Oval"
          color="#053d7c"
          height="50"
          width="50" />
      </div>
    );
  }
}

export default EscolaCardapio;
