import React from 'react';

import { connect } from 'react-redux';
import Loader from 'react-loader-spinner';

import AdaptiveTable from 'views/components/AdaptiveTable';

import UsuarioService from 'services/UsuarioService';

import Papel from 'assets/csv/papeis.json';

class EscolaAlunos extends React.Component {
  constructor(props) {
    super(props);

    this.idPagina = `${this.props.dadosUsuario.idVinculo}-alunos-escola`;

    this.paginaSelecionada = sessionStorage.getItem(`${this.idPagina}-pg`) || 1;
    this.linhasPorPagina = sessionStorage.getItem(`${this.idPagina}-ln`) || 5;
    this.like = '';

    this.state = {
      bloqueado: this.props.role !== Papel.GESTOR.value
        && this.props.role !== Papel.DIRETOR.value
        && this.props.role !== Papel.SECRETARIO.value,

      dadosTabela: {
        header: [['Nome', '40%'], ['Nome da mãe', '40%']],
        columnAlign: ['text-left', 'text-left'],
        keys: ['nome', 'nomeMae'],
        rows: [],
        total: undefined,
      },
    };

    this.usuarioService = new UsuarioService();

    this.carregarAlunosEscola = this.carregarAlunosEscola.bind(this);
    this.solicitarTransferencia = this.solicitarTransferencia.bind(this);
  }

  async componentDidMount() {
    try {
      this.carregarAlunosEscola(this.paginaSelecionada, this.linhasPorPagina, '');
    } catch (e) {
      this.props.callbackMessage({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao buscar cardápio da escola',
      });
    }
  }

  async carregarAlunosEscola(pagina, linhasPorPagina, like) {
    try {
      const idEscola = this.props.idEscola || this.props.match.params.idEscola;

      const data = await this.usuarioService
          .carregarUsuariosVinculadosEscola(idEscola, Papel.ALUNO.value, pagina, linhasPorPagina, like);

      this.setState({
        loaded: true,
        dadosTabela: Object.assign(this.state.dadosTabela,
          { rows: data.usuarios, total: data.total }),
      });
    } catch (e) {
      if (this.umounted) return;
      this.setState({
        dadosTabela: Object.assign(this.state.dadosTabela, { total: -1 }),
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao buscar alunos cadastrados',
      });
    }
  }

  solicitarTransferencia(aluno) {
    this.props.history.push(`${this.props.layout}/escolas/${this.props.match.params.idEscola}/cadastro/solicitacao/transferencia/aluno/${aluno.id}`);
  }

  render() {
    return (
      this.state.loaded ? <>
        <AdaptiveTable
          clickRows={true}
          selectedPage={this.paginaSelecionada}
          rowsPerPage={this.linhasPorPagina}
          disableActions
          disablePrintAction
          disableEditAction
          disableRemoveAction
          customActions={[{
            icon: 'fa fa-arrow-right',
            tooltip: 'Solicitar transferência',
            color: 'primary',
            callback: this.solicitarTransferencia,
            disabled: () => false,
          }]}
          rowsPerPageCallback={(info) => {
            this.paginaSelecionada = 1;
            sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
            this.linhasPorPagina = info.rowsPerPage;
            sessionStorage.setItem(`${this.idPagina}-ln`, this.linhasPorPagina);
            this.carregarAlunosEscola(this.paginaSelecionada, info.rowsPerPage, this.like);
          }}
          likeCallback={(text) => {
            this.like = text;
            this.paginaSelecionada = 1;
            sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
            this.carregarAlunosEscola(this.paginaSelecionada, this.linhasPorPagina, text);
          }}
          paginatorCallback={(page) => {
            this.paginaSelecionada = page;
            sessionStorage.setItem(`${this.idPagina}-pg`, this.paginaSelecionada);
            this.carregarAlunosEscola(this.paginaSelecionada, this.linhasPorPagina, this.like);
          }}
          rowCallback={(aluno) => this.props.history.push(`${this.props.layout}/alunos/historico/${aluno.id}`)}
          data={this.state.dadosTabela} />
      </> : <div align="center" style={{ margin: 50 }}>
        <Loader
          type="Oval"
          color="#053d7c"
          height="50"
          width="50" />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ dadosUsuario: state.dadosUsuario });

export default connect(mapStateToProps)(EscolaAlunos);