import React from 'react';
import { Link } from 'react-router-dom';

import {
  Row,
  Col,
  Button,
  Form,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Breadcrumb,
  BreadcrumbItem,
  FormGroup,
  Input,
  Table,
  UncontrolledTooltip,
} from 'reactstrap';

import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';

import moment from 'moment';
import isDateFormat from 'is-date-format';
import Select from 'react-select';
import Loader from 'react-loader-spinner';

import DatePicker from 'views/components/DatePicker';
import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';
import ConfirmModal from 'views/components/ConfirmModal';

import LoginService from 'services/LoginService';
import AnosLetivosService from 'services/AnosLetivosService';
import EscolasService from 'services/EscolasService';
import TurmasService from 'services/TurmasService';
import MateriasService from 'services/MateriasService';
import DiariosService from 'services/DiariosService';
import RegistroSituacaoMatriculaService from 'services/RegistroSituacaoMatriculaService';

import Turno from 'assets/csv/turnos.json';
import SituacaoMatricula from 'assets/csv/situacoes-matricula.json';
import SituacaoDiario from 'assets/csv/situacoes-diario.json';

class MudarSituacaoAluno extends React.Component {
  constructor(props) {
    super(props);

    this.turnos = [
      Turno.MATUTINO,
      Turno.VESPERTINO,
      Turno.NOTURNO,
      Turno.INTEGRAL,
    ];

    this.situacoesMatricula = [
      SituacaoMatricula.MATRICULADO,
      SituacaoMatricula.TRANSFERIDO,
      SituacaoMatricula.DESISTENTE,
      { ...SituacaoMatricula.APROVADO, isDisabled: true },
      { ...SituacaoMatricula.APROVADO_COM_DEPENDENCIA, isDisabled: true },
      { ...SituacaoMatricula.REPROVADO_POR_NOTA, isDisabled: true },
      { ...SituacaoMatricula.REPROVADO_POR_FREQUENCIA, isDisabled: true },
      { ...SituacaoMatricula.REPROVADO_POR_FREQUENCIA_E_NOTA, isDisabled: true },
      { ...SituacaoMatricula.NAO_MATRICULADO, isDisabled: true },
      SituacaoMatricula.FALECIDO,
    ];

    this.loginService = new LoginService();
    this.anosLetivosService = new AnosLetivosService();
    this.escolasService = new EscolasService();
    this.turmasService = new TurmasService();
    this.materiasService = new MateriasService();
    this.diariosService = new DiariosService();
    this.registroSituacaoMatriculaService = new RegistroSituacaoMatriculaService();

    this.state = {
      showAlert: false,
      buttonDisabled: false,
      showConfirmModal: false,

      error: null,

      dependencias: [],
      selectMaterias: [],

      registroSelecionado: null,

      dependenciasState: '',

      dataMatricula: '',
      viewDate: new Date(new Date().getFullYear(), 0, 1),
    };

    this.atualizarSituacaoAluno = this.atualizarSituacaoAluno.bind(this);
    this.removerRegistro = this.removerRegistro.bind(this);
  }

  componentWillUnmount() {
    this.loginService.abortPedingRequests();
    this.anosLetivosService.abortPedingRequests();
    this.escolasService.abortPedingRequests();
    this.turmasService.abortPedingRequests();
    this.diariosService.abortPedingRequests();
    this.materiasService.abortPedingRequests();
    this.registroSituacaoMatriculaService.abortPedingRequests();
    this.umounted = true;
  }

  async componentDidMount() {
    try {
      const anoLetivo = await this.anosLetivosService
        .carregarAnoLetivo(this.props.match.params.idAnoLetivo);

      const escola = await this.escolasService
        .carregarEscola(this.props.match.params.idEscola);

      const turma = await this.turmasService
        .carregarTurma(this.props.match.params.idTurma);

      const materias = await this.materiasService
        .carregarMateriasPorEstruturaCurricular(turma.estruturaCurricular.id);

      const selectMaterias = [];
      materias.forEach((materia) => {
        selectMaterias.push({
          value: materia.id,
          label: materia.nome,
        });
      });

      Object.assign(turma.estruturaCurricular, { materias });

      const diarios = await this.diariosService
        .carregarDiariosDaTurma(this.props.match.params.idTurma);

      const diariosConsolidados = diarios
        .every((diario) => diario.situacao === SituacaoDiario.CONSOLIDADO.value);

      if (diariosConsolidados) {
        this.situacoesMatricula = [
          { ...SituacaoMatricula.MATRICULADO, isDisabled: true },
          SituacaoMatricula.TRANSFERIDO,
          SituacaoMatricula.DESISTENTE,
          SituacaoMatricula.APROVADO,
          SituacaoMatricula.APROVADO_COM_DEPENDENCIA,
          SituacaoMatricula.REPROVADO_POR_NOTA,
          SituacaoMatricula.REPROVADO_POR_FREQUENCIA,
          SituacaoMatricula.REPROVADO_POR_FREQUENCIA_E_NOTA,
          { ...SituacaoMatricula.NAO_MATRICULADO, isDisabled: true },
          { ...SituacaoMatricula.FALECIDO, isDisabled: true },
        ];
      }

      const matricula = await this.turmasService
        .carregarMatricula(this.props.match.params.idTurma,
          this.props.match.params.idMatricula);

      const registros = await this.registroSituacaoMatriculaService
        .carregarRegistrosSituacaoMatricula(matricula.id);

      Object.assign(matricula, { situacao: registros[0].situacao });

      const dependencias = await this.turmasService
        .carregarDependencias(this.props.match.params.idMatricula);

      this.setState({
        anoLetivo,
        escola,
        turma,
        matricula,
        observacoes: matricula.observacoes,
        registros,
        situacaoSelecionada: this.situacoesMatricula[matricula.situacao],
        selectMaterias,
        dependencias: dependencias.map((dependencia) => {
          const mat = {};
          mat.value = dependencia.materia.id;
          mat.label = dependencia.materia.nome;
          return mat;
        }),
      });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  validarCampos() {
    let ret = true;

    if (this.state.dataMatricula === '') {
      this.setState({
        dataMatriculaState: 'has-danger',
        dataMatriculaErro: 'Informe a data da situação',
      });
      ret = false;
    } else if (!isDateFormat(this.state.dataMatricula, 'dd/mm/yyyy')) {
      this.setState({
        dataMatriculaState: 'has-danger',
        dataMatriculaErro: 'Data inválida',
      });
      ret = false;
    } 

    if (this.state.situacaoSelecionada.value === SituacaoMatricula.APROVADO_COM_DEPENDENCIA.value
      && this.state.dependencias.length === 0) {
      this.setState({ dependenciasState: 'danger' });
      ret = false;
    }
    return ret;
  }

  async atualizarSituacaoAluno() {
    if (!this.validarCampos()) {
      return;
    }

    if (this.state.buttonDisabled) return;

    this.setState({
      buttonDisabled: true,
      loaderModalMessage: 'Atualizando situação do aluno...',
      showAlert: false,
    });

    try {
      const dependencias = this.state.dependencias
        .map((dependencia) => {
          const dep = {};
          dep.materia = { id: dependencia.value };
          dep.matricula = { id: this.state.matricula.id };
          return dep;
        });

      const id = await this.turmasService
        .atualizarSituacaoMatricula(
          this.props.match.params.idTurma,
          this.state.matricula.id,
          this.state.situacaoSelecionada.value,
          this.state.dataMatricula,
          dependencias,
          this.state.observacoes,
        );

      this.state.registros.unshift({
        id,
        situacao: this.state.situacaoSelecionada.value,
        data: this.state.dataMatricula,
      });

      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Situação atualizada com sucesso',
      });
    } catch (msg) {
      if (this.umounted) return;
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao atualizar situação aluno',
      });
    }
  }

  confirmarRemocaoRegistro(registro) {
    this.setState({ registroSelecionado: registro, showConfirmModal: true });
  }

  async removerRegistro() {
    this.setState({
      showAlert: false,
      buttonDisabled: true,
      loaderModalMessage: 'Removendo registro de situação do aluno...',
    });

    try {
      await this.registroSituacaoMatriculaService
        .removerRegistroSituacaoMatricula(this.state.registroSelecionado.id);

      this.setState({
        registros: this.state.registros
          .filter((registro) => registro.id !== this.state.registroSelecionado.id),
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Registro removido com sucesso',
      });
    } catch (msg) {
      if (this.umounted) return;
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao remover registro',
      });
    }
  }

  conteudoPagina() {
    return this.state.escola ? <>
      <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos`}>Turmas e Matrículas</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`}>Ano letivo {this.state.anoLetivo.ano}</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}`}>{this.state.escola.nome}</Link></BreadcrumbItem>
            <BreadcrumbItem>
              {!this.state.turma.multiSeriado ? <Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/${this.state.turma.id}`}>{this.state.turma.nome} -&nbsp;
                {this.turnos[this.state.turma.turno].label}</Link> : <Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/multiseriado/${this.state.turma.turmaMultiseriado.id}`}>{this.state.turma.nome} -&nbsp;
                {this.turnos[this.state.turma.turno].label}</Link>}
            </BreadcrumbItem>
            <BreadcrumbItem active>Situação do aluno</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Form onSubmit={this.matricularAluno}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Situação do aluno</CardTitle>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md="2">
                    <FormGroup className={`has-label ${this.state.dataMatriculaState}`}>
                      <label>Data da situação *</label>
                      <DatePicker
                        viewDate={this.state.viewDate}
                        value={this.state.dataMatricula}
                        onChange={(data) => {
                          const dataStr = moment(data).format('DD/MM/YYYY');
                          this.setState({
                            viewDate: data.toDate ? data.toDate() : this.state.viewDate,
                            dataMatricula: dataStr,
                            dataMatriculaState: '',
                          });
                        }}
                      />
                      {this.state.dataMatriculaState === 'has-danger' ? (
                        <label className="error">
                          {this.state.dataMatriculaErro}
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="5">
                    <FormGroup className="has-label">
                      <label>Aluno</label>
                      <Input
                        disabled
                        type="text"
                        value={this.state.matricula.usuario.nome}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup className="has-label">
                      <label>Mãe</label>
                      <Input
                        disabled
                        type="text"
                        value={this.state.matricula.usuario.nomeMae}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="3">
                    <FormGroup className={'has-label has-danger'}>
                      <label>Situação *</label>
                      <Select
                        noOptionsMessage={() => 'Nenhuma entrada'}
                        className={`react-select primary ${this.state.situacaoState}`}
                        classNamePrefix="react-select"
                        value={this.state.situacaoSelecionada}
                        onChange={(event) => {
                          this.setState({ situacaoSelecionada: event, dependencias: [] });
                        }}
                        options={this.situacoesMatricula}
                        placeholder="Situação..."
                      />
                    </FormGroup>
                  </Col>
                </Row>
                {this.state.situacaoSelecionada.value
                  === SituacaoMatricula.APROVADO_COM_DEPENDENCIA.value
                  && <Row>
                    <Col md="12">
                      <FormGroup className={'has-label has-danger'}>
                        <label>Componentes curriculares em dependência*</label>
                        <Select
                          noOptionsMessage={() => 'Nenhuma entrada'}
                          className={`react-select primary ${this.state.dependenciasState}`}
                          classNamePrefix="react-select"
                          placeholder="Componentes curriculares em dependência..."
                          closeMenuOnSelect={false}
                          isMulti
                          value={this.state.dependencias}
                          onChange={(event) => {
                            this.setState({
                              dependencias: event || [],
                              dependenciasState: '',
                            });
                          }}
                          options={this.state.selectMaterias}
                        />
                        {this.state.dependenciasState === 'danger' ? (
                          <label className="error">
                            Informe pelo menos um componente curricular em dependência.
                          </label>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                }
                <Row>
                  <Col>
                    <FormGroup className={'has-label'}>
                      <label>Observações</label>
                      <Input
                        value={this.state.observacoes}
                        onChange={(evt) => {
                          this.setState({ observacoes: evt.target.value });
                        }}
                        type="textarea"
                        style={{ height: '100px' }} />
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Row>
                  <Col className="text-left">
                    <Button
                      color="primary"
                      onClick={() => this.props.history.goBack()} >
                      Voltar
                  </Button>
                  </Col>
                  <Col className="text-right">
                    <Button
                      onClick={this.atualizarSituacaoAluno}
                      disabled={this.state.buttonDisabled}
                      color="primary">
                      Atualizar situação
                  </Button>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Form>
          <Card>
            <CardHeader>
              <CardTitle tag="h4">Histórico da situação</CardTitle>
              <CardBody>
                <PerfectScrollbar className="sisa-perfect-scrollbar">
                <Table>
                  <thead className="text-primary">
                    <tr>
                      <th style={{ width: '25%' }}>Data</th>
                      <th style={{ width: '75%' }}>Situação</th>
                      <th style={{ width: '75%' }}>Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      this.state.registros.map((registro, idx) => <tr key={idx}>
                        <td>{registro.data}</td>
                        <td>{this.situacoesMatricula[registro.situacao].label}</td>
                        <td className="text-center">
                          <Button
                            id={`btnRemoverItem${idx}`}
                            className="btn-icon"
                            color="danger"
                            size="sm"
                            onClick={() => this.confirmarRemocaoRegistro(registro)}
                            type="button">
                            <i className="fa fa-times" />
                          </Button>
                          <UncontrolledTooltip placement="bottom" target={`btnRemoverItem${idx}`} delay={0}>
                            Remover
                          </UncontrolledTooltip>
                        </td>
                      </tr>)
                    }
                  </tbody>
                </Table>
                </PerfectScrollbar>
              </CardBody>
            </CardHeader>
          </Card>
        </Col >
      </Row >
    </>
      : <Card>
        <div align="center" style={{ margin: 50 }}>
          <Loader
            type="Oval"
            color="#053d7c"
            height="50"
            width="50" />
        </div>
      </Card>;
  }

  render() {
    return (
      <div className='content'>
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
        <ConfirmModal
          isOpen={this.state.showConfirmModal}
          callback={(confirm) => {
            this.setState({ showConfirmModal: false });
            if (confirm) {
              this.removerRegistro();
            } else {
              this.setState({ registroSelecionado: null });
            }
          }}
          text='Confirme a exclusão do registro' />
        <LoaderModal
          isOpen={this.state.buttonDisabled}
          text={this.state.loaderModalMessage}/>
      </div>
    );
  }
}

export default MudarSituacaoAluno;
