import React from 'react';
import { Link } from 'react-router-dom';

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  CardFooter,
  Button,
  FormGroup,
  Input,
} from 'reactstrap';

import Loader from 'react-loader-spinner';
import NumberFormat from 'react-number-format';

import Alert from 'views/components/Alert';

import LoginService from 'services/LoginService';
import PlanosService from 'services/PlanosService';

import TipoPlano from 'assets/csv/tipos-plano.json';

class Plano extends React.Component {
  constructor(props) {
    super(props);

    this.tiposPlano = [
      TipoPlano.K10,
      TipoPlano.K20,
      TipoPlano.K50,
      TipoPlano.CUSTOM,
    ];

    this.precoRef = React.createRef();

    this.state = {
      erro: null,
      plano: null,

      showAlert: false,
    };

    this.loginService = new LoginService();
    this.tiposPlanoService = new PlanosService();
  }

  componentWillUnmount() {
    this.loginService.abortPedingRequests();
    this.tiposPlanoService.abortPedingRequests();
  }

  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);
    try {
      const plano = await this.tiposPlanoService
        .carregarPlano(this.props.match.params.idPlano);

      this.setState({
        plano,
      });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  validarCampos() {
    let ret = true;
    if (this.state.plano.preco === '') {
      this.setState({ precoState: 'has-danger' });
      ret = false;
    }
    if (this.state.plano.escolas === '') {
      this.setState({ escolasState: 'has-danger' });
      ret = false;
    }
    if (this.state.plano.cursos === '') {
      this.setState({ cursosState: 'has-danger' });
      ret = false;
    }
    if (this.state.plano.estruturasCurriculares === '') {
      this.setState({ estruturasCurricularesState: 'has-danger' });
      ret = false;
    }
    if (this.state.plano.anosLetivos === '') {
      this.setState({ anosLetivosState: 'has-danger' });
      ret = false;
    }
    if (this.state.plano.turmas === '') {
      this.setState({ turmasState: 'has-danger' });
      ret = false;
    }
    if (this.state.plano.convites === '') {
      this.setState({ convitesState: 'has-danger' });
      ret = false;
    }
    if (this.state.plano.diretores === '') {
      this.setState({ diretoresState: 'has-danger' });
      ret = false;
    }
    if (this.state.plano.professores === '') {
      this.setState({ professoresState: 'has-danger' });
      ret = false;
    }
    if (this.state.plano.alunos === '') {
      this.setState({ alunosState: 'has-danger' });
      ret = false;
    }
    if (this.state.plano.historicos === '') {
      this.setState({ historicosState: 'has-danger' });
      ret = false;
    }
    return ret;
  }

  async atualizarPlano() {
    if (!this.validarCampos()) return;

    this.setState({
      buttonDisabled: true,
      showAlert: false,
    });

    const plano = {
      ...this.state.plano,
      preco: this.state.plano.preco.replace(',', '.'),
    };

    try {
      await this.tiposPlanoService.atualizarPlano(plano);

      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Plano atualizado com sucesso',
      });
    } catch (e) {
      if (this.umounted) return;
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: e || 'Erro ao atualizar plano',
      });
    }
  }

  conteudoPagina() {
    return this.state.plano === null
      ? <Card>
        <div align="center" style={{ margin: 50 }}>
          <Loader
            type="Oval"
            color="#053d7c"
            height="50"
            width="50" />
        </div>
      </Card> : <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to="/admin/planos">Planos</Link></BreadcrumbItem>
            <BreadcrumbItem active>Plano
              {this.tiposPlano[this.state.plano.tipo].label}</BreadcrumbItem>
          </Breadcrumb>
          <Card>
            <CardHeader>
              <CardTitle tag="h4">Plano {this.tiposPlano[this.state.plano.tipo].label}</CardTitle>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="2">
                  <FormGroup className={`has-label ${this.state.precoState}`}>
                    <label>Preço *</label>
                    <Input
                      value={this.state.plano.preco}
                      style={{ textAlign: 'right' }}
                      decimalSeparator=","
                      decimalScale={2}
                      onValueChange={(val) => this.setState({
                        plano: { ...this.state.plano, preco: val.formattedValue },
                        precoState: '',
                      })}
                      onBlur={() => {
                        const regex = /^\d+,\d\d$/g;
                        if (this.state.plano.preco !== ''
                          && !this.state.plano.preco.match(regex)) {
                          this.setState({
                            plano: {
                              ...this.state.plano,
                              preco: `${this.state.plano.preco},00`,
                            },
                          });
                        }
                      }}
                      tag={NumberFormat} />
                    {this.state.precoState === 'has-danger' ? (
                      <label className="error">
                        Informe o preço
                      </label>
                    ) : null}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="1">
                  <FormGroup className={`has-label ${this.state.escolasState}`}>
                    <label>Escolas *</label>
                    <Input
                      value={this.state.plano.escolas}
                      decimalSeparator={false}
                      allowNegative={false}
                      maxLength={6}
                      style={{ textAlign: 'right' }}
                      onValueChange={(val) => {
                        this.setState({
                          plano: { ...this.state.plano, escolas: val.formattedValue },
                          escolasState: '',
                        });
                      }}
                      tag={NumberFormat} />
                    {this.state.escolasState === 'has-danger' ? (
                      <label className="error">
                        Informe o limite de escolas.
                      </label>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col md="1">
                  <FormGroup className={`has-label ${this.state.cursosState}`}>
                    <label>Cursos *</label>
                    <Input
                      value={this.state.plano.cursos}
                      decimalSeparator={false}
                      allowNegative={false}
                      maxLength={6}
                      style={{ textAlign: 'right' }}
                      onValueChange={(val) => {
                        this.setState({
                          plano: { ...this.state.plano, cursos: val.formattedValue },
                          cursosState: '',
                        });
                      }}
                      tag={NumberFormat} />
                    {this.state.cursosState === 'has-danger' ? (
                      <label className="error">
                        Informe o limite de cursos.
                      </label>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col md="1">
                  <FormGroup className={`has-label ${this.state.estruturasCurricularesState}`}>
                    <label>E. Curriculares *</label>
                    <Input
                      value={this.state.plano.estruturasCurriculares}
                      decimalSeparator={false}
                      allowNegative={false}
                      maxLength={6}
                      style={{ textAlign: 'right' }}
                      onValueChange={(val) => {
                        this.setState({
                          plano: {
                            ...this.state.plano,
                            estruturasCurriculares: val.formattedValue,
                          },
                          estruturasCurricularesState: '',
                        });
                      }}
                      tag={NumberFormat} />
                    {this.state.estruturasCurricularesState === 'has-danger' ? (
                      <label className="error">
                        Informe o limite de estruturas curriculares.
                      </label>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col md="1">
                  <FormGroup className={`has-label ${this.state.anosLetivosState}`}>
                    <label>Anos Letivos *</label>
                    <Input
                      value={this.state.plano.anosLetivos}
                      decimalSeparator={false}
                      allowNegative={false}
                      maxLength={6}
                      style={{ textAlign: 'right' }}
                      onValueChange={(val) => {
                        this.setState({
                          plano: { ...this.state.plano, anosLetivos: val.formattedValue },
                          anosLetivosState: '',
                        });
                      }}
                      tag={NumberFormat} />
                    {this.state.anosLetivosState === 'has-danger' ? (
                      <label className="error">
                        Informe o limite de anos letivos.
                      </label>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col md="1">
                  <FormGroup className={`has-label ${this.state.turmasState}`}>
                    <label>Turmas *</label>
                    <Input
                      value={this.state.plano.turmas}
                      decimalSeparator={false}
                      allowNegative={false}
                      maxLength={6}
                      style={{ textAlign: 'right' }}
                      onValueChange={(val) => {
                        this.setState({
                          plano: { ...this.state.plano, turmas: val.formattedValue },
                          turmasState: '',
                        });
                      }}
                      tag={NumberFormat} />
                    {this.state.turmasState === 'has-danger' ? (
                      <label className="error">
                        Informe o limite de turmas.
                      </label>
                    ) : null}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col md="1">
                  <FormGroup className={`has-label ${this.state.convitesState}`}>
                    <label>Convites *</label>
                    <Input
                      value={this.state.plano.convites}
                      decimalSeparator={false}
                      allowNegative={false}
                      maxLength={6}
                      style={{ textAlign: 'right' }}
                      onValueChange={(val) => {
                        this.setState({
                          plano: { ...this.state.plano, convites: val.formattedValue },
                          convitesState: '',
                        });
                      }}
                      tag={NumberFormat} />
                    {this.state.convitesState === 'has-danger' ? (
                      <label className="error">
                        Informe o limite de convites.
                      </label>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col md="1">
                  <FormGroup className={`has-label ${this.state.diretoresState}`}>
                    <label>Diretores *</label>
                    <Input
                      value={this.state.plano.diretores}
                      decimalSeparator={false}
                      allowNegative={false}
                      maxLength={6}
                      style={{ textAlign: 'right' }}
                      onValueChange={(val) => {
                        this.setState({
                          plano: { ...this.state.plano, diretores: val.formattedValue },
                          diretoresState: '',
                        });
                      }}
                      tag={NumberFormat} />
                    {this.state.diretoresState === 'has-danger' ? (
                      <label className="error">
                        Informe o limite de diretores.
                      </label>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col md="1">
                  <FormGroup className={`has-label ${this.state.professoresState}`}>
                    <label>Professores *</label>
                    <Input
                      value={this.state.plano.professores}
                      decimalSeparator={false}
                      allowNegative={false}
                      maxLength={6}
                      style={{ textAlign: 'right' }}
                      onValueChange={(val) => {
                        this.setState({
                          plano: { ...this.state.plano, professores: val.formattedValue },
                          professoresState: '',
                        });
                      }}
                      tag={NumberFormat} />
                    {this.state.professoresState === 'has-danger' ? (
                      <label className="error">
                        Informe o limite de professores.
                      </label>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col md="1">
                  <FormGroup className={`has-label ${this.state.alunosState}`}>
                    <label>Alunos *</label>
                    <Input
                      value={this.state.plano.alunos}
                      decimalSeparator={false}
                      allowNegative={false}
                      maxLength={6}
                      style={{ textAlign: 'right' }}
                      onValueChange={(val) => {
                        this.setState({
                          plano: { ...this.state.plano, alunos: val.formattedValue },
                          alunosState: '',
                        });
                      }}
                      tag={NumberFormat} />
                    {this.state.alunosState === 'has-danger' ? (
                      <label className="error">
                        Informe o limite de alunos.
                      </label>
                    ) : null}
                  </FormGroup>
                </Col>
                <Col md="1">
                  <FormGroup className={`has-label ${this.state.historicosState}`}>
                    <label>Históricos *</label>
                    <Input
                      value={this.state.plano.historicos}
                      decimalSeparator={false}
                      allowNegative={false}
                      maxLength={6}
                      style={{ textAlign: 'right' }}
                      onValueChange={(val) => {
                        this.setState({
                          plano: { ...this.state.plano, historicos: val.formattedValue },
                          historicosState: '',
                        });
                      }}
                      tag={NumberFormat} />
                    {this.state.historicosState === 'has-danger' ? (
                      <label className="error">
                        Informe o limite de históricos.
                      </label>
                    ) : null}
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <Row>
                <Col md="6" className="text-left">
                  <Button color="primary"
                    disabled={this.state.buttonDisabled}
                    onClick={() => this.props.history.goBack()} >
                    Voltar
                  </Button>
                </Col>
                <Col md="6" className="text-right">
                  <Button color="primary"
                    disabled={this.state.buttonDisabled}
                    onClick={() => this.atualizarPlano()} >
                    Atualizar
                  </Button>
                </Col>
              </Row>

            </CardFooter>
          </Card>
        </Col>
      </Row>;
  }

  render() {
    return (
      <div className="content">
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
          </div>
          </Card>
        }
      </div>
    );
  }
}

export default Plano;
