import React from 'react';
import { Link } from 'react-router-dom';

import { connect } from 'react-redux';

// reactstrap components
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  Table,
  UncontrolledTooltip,
} from 'reactstrap';

import 'react-perfect-scrollbar/dist/css/styles.css';
import PerfectScrollbar from 'react-perfect-scrollbar';

import Loader from 'react-loader-spinner';

import Alert from 'views/components/Alert';
import GetStringModal from 'views/components/GetStringModal';
import LoaderModal from 'views/components/LoaderModal';
import ConfirmModal from 'views/components/ConfirmModal';

import LoginService from 'services/LoginService';
import DiretoriosService from 'services/DiretoriosService';
import AulasService from 'services/AulasService';

class ListagemAulas extends React.Component {
  constructor(props) {
    super(props);

    this.idPagina = `${this.props.dadosUsuario.idVinculo}-aulas`;

    this.loginService = new LoginService();
    this.diretoriosService = new DiretoriosService();
    this.aulasService = new AulasService();

    this.stackDir = JSON.parse(sessionStorage.getItem(`${this.idPagina}-stackDir`)) || { data: [{ id: null, nome: 'Minhas aulas' }] };
    sessionStorage.setItem(`${this.idPagina}-stackDir`, JSON.stringify(this.stackDir));

    this.state = {
      showAlert: false,
      showGetStringModal: false,
      erro: false,
      ready: false,

      diretorios: [],
      aulas: [],

      aulaSelecionada: null,
      diretorioSelecionado: null,
    };
  }

  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);

    try {
      await this.carregarDiretorios();

      await this.carregarAulas();

      this.setState({ ready: true });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  async carregarDiretorios() {
    const diretorios = await this.diretoriosService
      .carregarDiretorios(this.stackDir.data[this.stackDir.data.length - 1].id);

    this.setState({
      diretorios,
    });
  }

  async carregarAulas() {
    const aulas = await this.aulasService
      .carregarAulasDoDiretorio(this.stackDir.data[this.stackDir.data.length - 1].id);

    this.setState({
      aulas,
    });
  }

  async criarDiretorio(nomeDiretorio) {
    if (nomeDiretorio === undefined) return;

    try {
      const diretorio = {
        nome: nomeDiretorio,
        pai: this.stackDir.data[this.stackDir.data.length - 1].id
          ? this.stackDir.data[this.stackDir.data.length - 1] : null,
      };

      await this.diretoriosService.cadastrarDiretorio(diretorio);

      await this.carregarDiretorios();

      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Diretório criado com sucesso',
      });
    } catch (e) {
      if (this.umounted) return;
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: e || 'Erro ao criar diretório',
      });
    }
  }

  async moverRecurso(ev, idDiretorioDestino) {
    try {
      this.setState({
        showAlert: false,
        buttonDisabled: true,
        loaderModalText: 'Movendo recurso...',
      });

      if (ev.dataTransfer.getData('idDiretorio')) {
        await this.diretoriosService
          .moverDiretorio(ev.dataTransfer.getData('idDiretorio'), idDiretorioDestino);
      } else {
        await this.diretoriosService
          .moverAula(ev.dataTransfer.getData('idAula'), idDiretorioDestino);
      }

      await this.carregarDiretorios();

      await this.carregarAulas();

      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Recurso movido com sucesso',
      });
    } catch (e) {
      if (this.umounted) return;
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: e || 'Erro mover recurso',
      });
    }
  }

  abrirAula(aula) {
    this.props.history.push(`${this.props.layout}/ava/aulas/${aula.id}`);
  }

  async abrirDiretorio(diretorio, voltando) {
    if (!voltando) {
      this.stackDir.data.push(diretorio);
      sessionStorage.setItem(`${this.idPagina}-stackDir`, JSON.stringify(this.stackDir));
    }

    this.setState({
      showAlert: false,
      buttonDisabled: true,
      loaderModalText: 'Entrando no diretório...',
    });

    try {
      await this.carregarDiretorios();

      await this.carregarAulas();

      this.setState({
        buttonDisabled: false,
      });
    } catch (e) {
      if (this.umounted) return;
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: e || 'Erro ao entrar no diretório',
      });
    }
  }

  confirmarRemocaoAula(aula) {
    this.setState({
      aulaSelecionada: aula,
      diretorioSelecionado: null,
      showConfirmModal: true,
    });
  }

  async removerAula() {
    this.setState({
      showAlert: false,
      buttonDisabled: true,
      loaderModalText: 'Excluindo aula...',
    });

    try {
      await this.aulasService
        .removerAula(this.state.aulaSelecionada.id);

      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Aula removida com sucesso',
      });

      await this.carregarAulas();
    } catch (msg) {
      if (this.umounted) return;
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao remover aula',
      });
    }
  }

  editarAula(aula) {
    this.props.history.push({
      pathname: `/professor/ava/aulas/edicao/${aula.id}`,
    });
  }

  confirmarRemocaoDireotorio(diretorio) {
    this.setState({
      aulaSelecionada: null,
      diretorioSelecionado: diretorio,
      showConfirmModal: true,
    });
  }

  async removerDiretorio() {
    this.setState({
      showAlert: false,
      buttonDisabled: true,
      loaderModalText: 'Excluindo diretorio...',
    });

    try {
      await this.diretoriosService
        .removerDiretorio(this.state.diretorioSelecionado.id);

      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Diretório removido com sucesso',
      });

      await this.carregarDiretorios();
    } catch (msg) {
      if (this.umounted) return;
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao remover diretório',
      });
    }
  }

  renomearDiretorio(diretorio) {
    this.setState({
      diretorioSelecionado: diretorio,
      showGetStringModal: true,
    });
  }

  async atualizarDiretorio(nome) {
    this.setState({
      showAlert: false,
      buttonDisabled: true,
      loaderModalText: 'Renomeando diretorio...',
    });

    try {
      const diretorio = {
        ...this.state.diretorioSelecionado,
        pai: this.state.diretorioSelecionado.pai ? {
          id: this.state.diretorioSelecionado.pai,
        } : undefined,
        nome,
      };

      await this.diretoriosService
        .atualizarDiretorio(diretorio);

      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Diretório renomeado com sucesso',
        diretorioSelecionado: null,
      });

      await this.carregarDiretorios();
    } catch (msg) {
      if (this.umounted) return;
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao renomear diretório',
        diretorioSelecionado: null,
      });
    }
  }

  async navegar(idx) {
    this.stackDir.data.length = idx + 1;
    sessionStorage.setItem(`${this.idPagina}-stackDir`, JSON.stringify(this.stackDir));
    await this.carregarDiretorios();
    await this.carregarAulas();
  }

  conteudoPagina() {
    return this.state.ready ? <>
      <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem ><Link to={`${this.props.layout}/ava`}>Ambiente Virtual</Link></BreadcrumbItem>
            <BreadcrumbItem active>Aulas</BreadcrumbItem>
          </Breadcrumb>
          <Card>
            <CardHeader>
              <CardTitle tag="h4">Aulas</CardTitle>
            </CardHeader>
            <CardBody>
              <Breadcrumb>
                {
                  this.stackDir.data.map((diretorio, idx) => {
                    const ativo = this.stackDir.data[
                      this.stackDir.data.length - 1].id === diretorio.id;
                    return ativo ? <BreadcrumbItem key={idx} active>
                      {diretorio.nome}
                    </BreadcrumbItem> : <BreadcrumbItem key={idx}>
                      <Link onClick={() => this.navegar(idx)} to={`${this.props.layout}/ava/aulas`}>{diretorio.nome}</Link>
                    </BreadcrumbItem>;
                  })
                }
              </Breadcrumb>
              <PerfectScrollbar className="sisa-perfect-scrollbar">
              <Table className="aulas">
                <thead className="text-primary">
                  <tr>
                    <th style={{ width: '2%' }}></th>
                    <th style={{ width: '50%' }}>Nome</th>
                    <th style={{ width: '30%' }}>Código</th>
                    <th className="text-center" style={{ width: '10%' }}>Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    this.stackDir.data[this.stackDir.data.length - 1].id && <tr>
                      <td className="td-dir"><i className="fas fa-folder"></i></td>
                      <td
                        className="td-name"
                        onDrop={(ev) => this
                          .moverRecurso(ev, this.stackDir.data[this.stackDir.data.length - 2].id)}
                        onDragOver={(ev) => ev.preventDefault()}
                        onClick={() => {
                          this.stackDir.data.pop();
                          sessionStorage.setItem(`${this.idPagina}-stackDir`, JSON.stringify(this.stackDir));
                          this.abrirDiretorio(this.stackDir
                            .data[this.stackDir.data.length - 1], true);
                        }}>..</td>
                      <td></td>
                      <td></td>
                    </tr>
                  }
                  {
                    this.state.diretorios.map((diretorio, idx) => <tr key={idx}>
                      <td className="td-dir"><i className="fas fa-folder"></i></td>
                      <td
                        className="td-name"
                        draggable="true"
                        onDrop={(ev) => this.moverRecurso(ev, diretorio.id)}
                        onDragOver={(ev) => ev.preventDefault()}
                        onDragStart={(ev) => ev.dataTransfer.setData('idDiretorio', diretorio.id)}
                        onClick={() => this.abrirDiretorio(diretorio)}>{diretorio.nome}</td>
                      <td></td>
                      <td className="text-center">
                        <Button
                          id={`btnEditarDiretorio${idx}`}
                          className="btn-icon"
                          color="success"
                          size="sm"
                          onClick={(evt) => {
                            evt.stopPropagation();
                            this.renomearDiretorio(diretorio);
                          }}
                          type="button">
                          <i className="fa fa-edit" />
                        </Button>
                        <UncontrolledTooltip placement="bottom" target={`btnEditarDiretorio${idx}`} delay={0}>
                          Renomear
                        </UncontrolledTooltip>
                        {' '}
                        <Button
                          id={`btnRemoverDiretorio${idx}`}
                          className="btn-icon"
                          color="danger"
                          size="sm"
                          onClick={(evt) => {
                            evt.stopPropagation();
                            this.confirmarRemocaoDireotorio(diretorio);
                          }}
                          type="button">
                          <i className="fa fa-times" />
                        </Button>
                        <UncontrolledTooltip placement="bottom" target={`btnRemoverDiretorio${idx}`} delay={0}>
                          Excluir
                        </UncontrolledTooltip>
                      </td>
                    </tr>)
                  }
                  {
                    this.state.aulas.map((aula, idx) => <tr key={idx}>
                      <td></td>
                      <td
                        className="td-name"
                        draggable="true"
                        onDragStart={(ev) => ev.dataTransfer.setData('idAula', aula.id)}
                        onClick={() => this.abrirAula(aula)}>{aula.nome}</td>
                      <td>{aula.codigo}</td>
                      <td className="text-center">
                        <Button
                          id={`btnEditarAula${idx}`}
                          className="btn-icon"
                          color="success"
                          size="sm"
                          onClick={(evt) => {
                            evt.stopPropagation();
                            this.editarAula(aula);
                          }}
                          type="button">
                          <i className="fa fa-edit" />
                        </Button>
                        <UncontrolledTooltip placement="bottom" target={`btnEditarAula${idx}`} delay={0}>
                          Editar
                        </UncontrolledTooltip>
                        {' '}
                        <Button
                          id={`btnRemoverAula${idx}`}
                          className="btn-icon"
                          color="danger"
                          size="sm"
                          onClick={(evt) => {
                            evt.stopPropagation();
                            this.confirmarRemocaoAula(aula);
                          }}
                          type="button">
                          <i className="fa fa-times" />
                        </Button>
                        <UncontrolledTooltip placement="bottom" target={`btnRemoverAula${idx}`} delay={0}>
                          Excluir
                        </UncontrolledTooltip>
                      </td>
                    </tr>)
                  }
                </tbody>
              </Table>
              </PerfectScrollbar>
            </CardBody>
            <CardFooter>
              <Row>
                <Col className="text-left">
                  <Button color="primary"
                    onClick={() => this.props.history.push(`${this.props.layout}/ava`)}>
                    Voltar
                  </Button>
                </Col>
                <Col className="text-right">
                  <Button color="primary"
                    onClick={() => this.setState({ showGetStringModal: true })}>
                    Criar diretório
                  </Button>
                  &nbsp;&nbsp;
                  <Button color="primary"
                    onClick={() => {
                      this.props.history.push(`${this.props.layout}/ava/aulas/cadastro`);
                    }}>
                    Cadastrar aula
                  </Button>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </> : <Card>
      <div align="center" style={{ margin: 50 }}>
        <Loader
          type="Oval"
          color="#053d7c"
          height="50"
          width="50" />
      </div>
    </Card>;
  }

  render() {
    return (
      <div className='content'>
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
        <LoaderModal
          isOpen={this.state.buttonDisabled}
          text={this.state.loaderModalText} />
        <GetStringModal
          isOpen={this.state.showGetStringModal}
          headerText="Informe o nome do diretório"
          confirmButtonText={!this.state.diretorioSelecionado ? 'Criar' : 'Renomear'}
          value={this.state.diretorioSelecionado ? this.state.diretorioSelecionado.nome : ''}
          callback={(nomeDiretorio) => {
            if (!nomeDiretorio) {
              this.setState({ showGetStringModal: false });
              return;
            }
            if (!this.state.diretorioSelecionado) {
              this.setState({ showGetStringModal: false });
              this.criarDiretorio(nomeDiretorio);
            } else {
              this.setState({ showGetStringModal: false });
              this.atualizarDiretorio(nomeDiretorio);
            }
          }} />
        <ConfirmModal
          isOpen={this.state.showConfirmModal}
          callback={(confirm) => {
            this.setState({ showConfirmModal: false });
            if (confirm) {
              if (this.state.aulaSelecionada) {
                this.removerAula();
              } else {
                this.removerDiretorio();
              }
            } else {
              this.setState({ diretorioSelecionado: null });
            }
          }}
          text={this.state.aulaSelecionada
            ? 'Confirme a exclusão da aula.'
            + ' A aula será removida de todas as turmas virtuais.'
            : 'Confirme a exclusão do diretório.'
            + ' Todo o conteúdo dentro do diretório também será excluído.'} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ dadosUsuario: state.dadosUsuario });

export default connect(mapStateToProps)(ListagemAulas);
