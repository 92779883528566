import React from 'react';
import { Link } from 'react-router-dom';
import _ from 'lodash';

import {
  Row,
  Col,
  Button,
  Form,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  FormGroup,
  CardFooter,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';

import Loader from 'react-loader-spinner';
import Select from 'react-select';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';

import LoginService from 'services/LoginService';
import EscolasService from 'services/EscolasService';
import UsuarioService from 'services/UsuarioService';

import Papel from 'assets/csv/papeis.json';

class CadastroCoordenador extends React.Component {
  constructor(props) {
    super(props);

    this.loginService = new LoginService();
    this.escolasService = new EscolasService();
    this.usuarioService = new UsuarioService();

    this.state = {
      showAlert: false,
      error: null,

      carregandoCoordenadores: false,
      coordenadorSelecionado: null,

      coordenadores: [],
    };

    this.adicionarCoordenador = this.adicionarCoordenador.bind(this);
  }

  componentWillUnmount() {
    this.loginService.abortPedingRequests();
    this.escolasService.abortPedingRequests();
    this.usuarioService.abortPedingRequests();
    this.umounted = true;
  }

  async componentDidMount() {
    this.debouncedFunction = _.debounce((text) => {
      if (text === '') {
        this.setState({ carregandoCoordenadores: false, coordenadores: [] });
      } else {
        this.carregarCoordenadores(text);
      }
    }, 1500);

    try {
      const escola = await this.escolasService
        .carregarEscola(this.props.match.params.idEscola);

      this.setState({
        escola,
      });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  validarCampos() {
    let ret = true;
    if (!this.state.coordenadorSelecionado) {
      this.setState({ coordenadorState: 'danger' });
      ret = false;
    }
    return ret;
  }

  async adicionarCoordenador(e) {
    e.preventDefault();

    if (this.state.buttonDisabled) return;
    if (!this.validarCampos()) return;

    this.setState({
      buttonDisabled: true,
      showAlert: false,
    });

    try {
      const coordenador = {
        escola: { id: this.props.match.params.idEscola },
        usuario: { id: this.state.coordenadorSelecionado.value },
      };

      await this.escolasService.adicionarCoordenador(coordenador);

      this.limparFormulario();

      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Coordenador adicionado com sucesso',
      });
    } catch (msg) {
      if (this.umounted) return;
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao adicionar coordenador',
      });
    }
  }

  limparFormulario() {
    this.setState({
      coordenadorSelecionado: null,
      coordenadores: [],
    });
  }

  async carregarCoordenadores(like) {
    try {
      const data = await this.usuarioService
        .carregarUsuarios(Papel.COORDENADOR_PEDAGOGICO.value, 1, 99999, like);

      const selectCoordenadores = [];
      data.usuarios.forEach((coordenador) => {
        selectCoordenadores.push({
          value: coordenador.id,
          label: coordenador.nome ? coordenador.nome : coordenador.credenciais.email,
        });
      });

      this.setState({
        carregandoCoordenadores: false,
        coordenadores: selectCoordenadores,
      });
    } catch (e) {
      if (this.umounted) return;
      this.setState({
        carregandoCoordenadores: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao buscar coordenadores cadastrados',
      });
    }
  }

  conteudoPagina() {
    return this.state.escola ? <>
      <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to={'/gestor/escolas'}>Escolas</Link></BreadcrumbItem>
            <BreadcrumbItem><Link to={`/gestor/escolas/${this.props.match.params.idEscola}`}>{this.state.escola.nome}</Link></BreadcrumbItem>
            <BreadcrumbItem active>Adicionar coordenador</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Form onSubmit={this.adicionarCoordenador}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Adicionar coordenador</CardTitle>
              </CardHeader>
              <CardBody>
                <Row className="align-items-end">
                  <Col md="6">
                    <FormGroup className={'has-label has-danger'}>
                      <label>Coordenador *</label>
                      <Select
                        isLoading={this.state.carregandoCoordenadores}
                        loadingMessage={() => 'Carregando...'}
                        noOptionsMessage={() => 'Nenhuma entrada'}
                        className={`react-select primary ${this.state.coordenadorState}`}
                        classNamePrefix="react-select"
                        value={this.state.coordenadorSelecionado}
                        onInputChange={(text) => {
                          this.setState({ carregandoCoordenadores: text !== '', coordenadorState: 'primary' });
                          this.debouncedFunction(text);
                        }}
                        onChange={(event) => {
                          this.setState({ coordenadorState: 'primary', coordenadorSelecionado: event });
                        }}
                        options={this.state.coordenadores}
                        placeholder="Digite o nome de um coordenador..."
                      />
                      {this.state.coordenadorState === 'danger' ? (
                        <label className="error">
                          Informe o nome de um coordenador.
                      </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Row>
                  <Col md="6" className="text-left">
                    <Button
                      color="primary"
                      onClick={() => this.props.history
                        .push(`/gestor/escolas/${this.props.match.params.idEscola}`)} >
                      Voltar
                  </Button>
                  </Col>
                  <Col md="6" className="text-right">
                    <Button
                      type="submit"
                      disabled={this.state.buttonDisabled}
                      color="primary">
                      Adicionar
                  </Button>
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Form>
        </Col >
      </Row >
    </>
      : <Card>
        <div align="center" style={{ margin: 50 }}>
          <Loader
            type="Oval"
            color="#053d7c"
            height="50"
            width="50" />
        </div>
      </Card>;
  }

  render() {
    return (
      <div className='content'>
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
        <LoaderModal
          isOpen={this.state.buttonDisabled}
          text="Adicionando coordenador..." />
      </div>
    );
  }
}

export default CadastroCoordenador;
