import React from 'react';
import { Link } from 'react-router-dom';
import env from 'conf/env.json';

import { connect } from 'react-redux';

// reactstrap components
import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Button,
  Breadcrumb,
  BreadcrumbItem,
  FormGroup,
  Input,
} from 'reactstrap';

import Loader from 'react-loader-spinner';
import getVideoId from 'get-video-id';
import { Editor } from '@tinymce/tinymce-react';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';

import LoginService from 'services/LoginService';
import AnosLetivosService from 'services/AnosLetivosService';
import EscolasService from 'services/EscolasService';
import TurmasService from 'services/TurmasService';
import DiariosService from 'services/DiariosService';
import AulasService from 'services/AulasService';

import Turno from 'assets/csv/turnos.json';

class CadastroAula extends React.Component {
  constructor(props) {
    super(props);

    this.idPagina = `${this.props.dadosUsuario.idVinculo}-aulas`;

    this.turnos = [
      Turno.MATUTINO,
      Turno.VESPERTINO,
      Turno.NOTURNO,
      Turno.INTEGRAL,
    ];

    this.fileInputRef = React.createRef();
    this.fileInputRef2 = React.createRef();

    this.nomeRef = React.createRef();

    this.loginService = new LoginService();
    this.anosLetivosService = new AnosLetivosService();
    this.escolasService = new EscolasService();
    this.turmasService = new TurmasService();
    this.diariosService = new DiariosService();
    this.aulasService = new AulasService();

    this.state = {
      showAlert: false,

      showLoaderModal: false,

      erro: false,
    };

    this.fileChange = this.fileChange.bind(this);
    this.cadastrarAula = this.cadastrarAula.bind(this);
    this.atualizarAula = this.atualizarAula.bind(this);
    this.validarCampos = this.validarCampos.bind(this);
    this.limparFormulario = this.limparFormulario.bind(this);
  }

  componentWillUnmount() {
    this.anosLetivosService.abortPedingRequests();
    this.escolasService.abortPedingRequests();
    this.turmasService.abortPedingRequests();
    this.diariosService.abortPedingRequests();
    this.aulasService.abortPedingRequests();
    this.umounted = true;
  }

  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);

    try {
      const anoLetivo = this.props.match.params.idAnoLetivo
        ? await this.anosLetivosService
          .carregarAnoLetivo(this.props.match.params.idAnoLetivo) : null;

      const escola = this.props.match.params.idAnoLetivo
        ? await this.escolasService
          .carregarEscola(this.props.match.params.idEscola) : null;

      const turma = this.props.match.params.idAnoLetivo
        ? await this.turmasService
          .carregarTurma(this.props.match.params.idTurma) : null;

      const diario = this.props.match.params.idAnoLetivo
        ? await this.diariosService
          .carregarDiario(this.props.match.params.idDiario) : null;

      let idDiretorioAula = null;
      if (!this.props.match.params.idAnoLetivo) {
        const stackDir = JSON.parse(sessionStorage.getItem(`${this.idPagina}-stackDir`)) || { data: [{ id: null }] };
        idDiretorioAula = stackDir.data[stackDir.data.length - 1].id;
      }

      let aula = null;

      if (this.props.match.params.idAula !== undefined) {
        aula = await this.aulasService
          .carregarAula(this.props.match.params.idAula);
      } else {
        aula = {
          nome: '',
          conteudo: '',
        };
      }

      this.setState({
        anoLetivo,
        escola,
        turma,
        diario,
        aula,
        idDiretorioAula,
        ready: true,
      });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  fileChange(e) {
    this.setState({ showAlert: false });
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];

    const re = /(?:\.([^.]+))?$/;
    const ext = re.exec(file.name)[1];

    if (!ext) {
      this.setState({
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao carregar arquivo selecionado. Não é possível enviar arquivos sem extensão.',
      });
      return;
    }

    if (file) {
      reader.onloadend = async () => {
        if (file.size > 5000000) {
          this.setState({
            showAlert: true,
            alertColor: 'danger',
            alertMsg: 'Erro: Imagem muito grande. O tamanho máximo para imagem é 5MB',
          });
        } else {
          const regexp = /^data:((\w+)\/(.+));base64,(.+)$/g;
          const matches = regexp.exec(reader.result);

          if (matches.length !== 5) {
            this.setState({
              showAlert: true,
              alertColor: 'danger',
              alertMsg: 'Erro ao carregar arquivo selecionado.',
            });
          } else {
            try {
              this.setState({
                buttonDisabled: true,
                loaderModalText: 'Fazendo upload...',
              });

              const arquivo = {
                conteudo: matches[4],
                extensao: ext,
                tipo: matches[1],
              };

              const url = await this.aulasService
                .uploadArquivo(arquivo);

              if (matches[2] === 'image') {
                const html = `<img src="${url}"/>`;
                this.state.editor.insertContent(html);
              } else {
                const html = `<a href="${url}">${file.name}</a>`;
                this.state.editor.insertContent(html);
              }
              this.setState({ buttonDisabled: false });
            } catch (msg) {
              this.setState({
                buttonDisabled: false,
                showAlert: true,
                alertColor: 'danger',
                alertMsg: msg || 'Erro ao carregar arquivo selecionado.',
              });
            }
          }
        }
      };
      reader.readAsDataURL(file);
    }
  }

  validarCampos() {
    let ret = true;
    if (this.state.aula.nome === '') {
      this.setState({ nomeState: 'has-danger' });
      ret = false;
    }
    if (this.state.editor.getContent() === '') {
      this.state.editor.getBody().style.backgroundColor = '#FFC0A4';
      this.setState({ conteudoState: 'has-danger' });
      ret = false;
    }
    return ret;
  }

  async cadastrarAula() {
    if (!this.validarCampos()) return;

    this.setState({
      buttonDisabled: true,
      showAlert: false,
      loaderModalText: 'Cadastrando aula...',
    });

    try {
      if (this.props.match.params.idAnoLetivo) {
        await this.aulasService.cadastrarAulaEAdicionarNaTurma(this.state.aula,
          this.props.match.params.idTurma,
          this.props.match.params.idMateria);
      } else {
        const aula = {
          ...this.state.aula,
          diretorio: this.state.idDiretorioAula
            ? { id: this.state.idDiretorioAula } : null,
          conteudo: this.state.editor.getContent(),
        };
        await this.aulasService.cadastrarAula(aula);
      }

      this.limparFormulario();

      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Aula cadastrada com sucesso',
      });
    } catch (e) {
      if (this.umounted) return;
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: e || 'Erro ao cadastrar aula',
      });
    }
  }

  async atualizarAula() {
    if (!this.validarCampos()) return;

    this.setState({
      buttonDisabled: true,
      showAlert: false,
      loaderModalText: 'Atualizando escola...',
    });

    try {
      const aula = {
        ...this.state.aula,
        conteudo: this.state.editor.getContent(),
      };
      await this.aulasService.atualizarAula(aula);

      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Aula atualizada com sucesso',
      });
    } catch (e) {
      if (this.umounted) return;
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: e || 'Erro ao atualizar aula',
      });
    }
  }

  limparFormulario() {
    this.state.editor.setContent('');
    this.setState({
      aula: {
        nome: '',
        conteudo: '',
      },
    });
  }

  conteudoPagina() {
    return this.state.ready ? <>
      <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            {
              this.props.match.params.idAnoLetivo ? <>
                <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos`}>Anos letivos</Link></BreadcrumbItem>
                <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}`}>Ano letivo {this.state.anoLetivo.ano}</Link></BreadcrumbItem>
                <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}`}>{this.state.escola.nome}</Link></BreadcrumbItem>
                <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/${this.state.turma.id}`}>{this.state.turma.nome} - {this.turnos[this.state.turma.turno].label}</Link></BreadcrumbItem>
                <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/${this.state.turma.id}/diario/${this.state.diario.id}`}>{this.state.diario.nome}</Link></BreadcrumbItem>
                <BreadcrumbItem><Link to={`${this.props.layout}/anosletivos/${this.props.match.params.idAnoLetivo}/escola/${this.state.escola.id}/turma/${this.state.turma.id}/diario/${this.state.diario.id}/materia/${this.props.match.params.idMateria}/turmavirtual`}>Turma virtual</Link></BreadcrumbItem>
              </> : <>
                  <BreadcrumbItem><Link to={`${this.props.layout}/ava`}>Ambiente Virtual</Link></BreadcrumbItem>
                  <BreadcrumbItem><Link to={`${this.props.layout}/ava/aulas`}>Aulas</Link></BreadcrumbItem>
                  <BreadcrumbItem active>{this.state.aula.id ? this.state.aula.nome : 'Cadastro'}</BreadcrumbItem>
                </>
            }
          </Breadcrumb>
          <Card>
            <CardHeader>
              <CardTitle tag="h4">{this.state.aula.id ? this.state.aula.nome : 'Cadastro de aula'}</CardTitle>
            </CardHeader>
            <CardBody>
              <div className="fileinput text-center">
                <input
                  type="file"
                  accept="image/jpeg, image/png"
                  onChange={this.fileChange}
                  ref={this.fileInputRef} />
                <input
                  type="file"
                  onChange={this.fileChange}
                  ref={this.fileInputRef2} />
              </div>
              <Row>
                <Col md="5">
                  <FormGroup className={`has-label ${this.state.nomeState}`}>
                    <label>Nome *</label>
                    <Input
                      type="text"
                      maxLength="200"
                      value={this.state.aula.nome}
                      onChange={(e) => this.setState({
                        aula: { ...this.state.aula, nome: e.target.value },
                        nomeState: 'has-success',
                      })}
                    />
                    {this.state.nomeState === 'has-danger' ? (
                      <label className="error">
                        Informe um nome para a aula.
                      </label>
                    ) : null}
                  </FormGroup>
                </Col>
                {
                  this.props.match.params.idAula !== undefined && <Col md="2">
                    <FormGroup>
                      <label>Código</label>
                      <Input
                        disabled
                        type="text"
                        value={this.state.aula.codigo}
                      />
                    </FormGroup>
                  </Col>
                }
              </Row>
              <br />
              <Row>
                <Col md="12">
                  <FormGroup className={`has-label ${this.state.conteudoState}`}>
                    <label>Conteúdo *</label>
                    <Editor
                      apiKey='5ri9uiz44o9zmfcm5nsbg8fumf9akfxdjs25t2if5l6pasm5'
                      initialValue={this.state.aula.conteudo}
                      init={{
                        min_height: 500,
                        menubar: false,
                        plugins: [
                          'autoresize advlist autolink lists link image charmap print preview anchor',
                          'searchreplace visualblocks code fullscreen',
                          'insertdatetime media table paste code charmap',
                        ],
                        toolbar:
                          'undo redo | formatselect | fontselect fontsizeselect | '
                          + 'bold italic underline strikethrough | forecolor backcolor |'
                          + 'alignleft aligncenter alignright alignjustify | '
                          + 'bullist numlist outdent indent | removeformat | '
                          + 'table charmap | link uploadfile uploadimage insertvideo | tiny_mce_wiris_formulaEditor tiny_mce_wiris_formulaEditorChemistry',
                        external_plugins: {
                          tiny_mce_wiris: `${env.BASE_ADDRESS}/assets/mathtype-tinymce5/plugin.min.js`,
                        },

                        setup: (editor) => {
                          this.setState({ editor });

                          editor.ui.registry.addButton('uploadfile', {
                            icon: 'document-properties',
                            tooltip: 'Inserir arquivo',
                            onAction: () => this.fileInputRef2.current.click(),
                          });

                          editor.ui.registry.addButton('uploadimage', {
                            icon: 'image',
                            tooltip: 'Inserir imagem',
                            onAction: () => this.fileInputRef.current.click(),
                          });

                          editor.ui.registry.addButton('insertvideo', {
                            icon: 'embed',
                            tooltip: 'Inserir vídeo',
                            onAction: () => {
                              editor.windowManager.open({
                                title: 'Informe o endereço do vídeo',
                                body: {
                                  type: 'panel',
                                  items: [
                                    {
                                      type: 'input',
                                      name: 'inputtext',
                                      inputMode: 'text',
                                      disabled: false,
                                      maximized: true,
                                    },
                                  ],
                                },
                                buttons: [
                                  {
                                    type: 'submit',
                                    text: 'Adicionar',
                                  },
                                ],
                                onSubmit: (dialogApi) => {
                                  const videoId = getVideoId(dialogApi.getData().inputtext);
                                  const embeddedUrl = `https://www.youtube.com/embed/${videoId.id}`;

                                  const html = `<iframe width="853" height="480" src="${embeddedUrl}"></iframe>`;
                                  editor.insertContent(html);
                                  dialogApi.close();
                                },
                              });
                            },
                          });
                        },
                      }}
                      onEditorChange={() => {
                        this.state.editor.getBody().style.backgroundColor = '#FFFFFF';
                        this.setState({ conteudoState: '' });
                      }}
                    />
                    {this.state.conteudoState === 'has-danger' ? (
                      <label className="error">
                        Informe o conteúdo da aula
                      </label>
                    ) : null}
                  </FormGroup>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <Row>
                <Col md="6" className="text-left">
                  <Button color="primary"
                    onClick={() => this.props.history.goBack()}>
                    Voltar
                  </Button>
                </Col>
                <Col md="6" className="text-right">
                  {
                    !this.state.aula.id
                      ? <Button color="primary"
                        onClick={() => this.cadastrarAula()}>
                        Cadastrar
                    </Button>
                      : <Button color="primary"
                        onClick={() => this.atualizarAula()}>
                        Atualizar
                    </Button>
                  }
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </> : <Card>
        <div align="center" style={{ margin: 50 }}>
          <Loader
            type="Oval"
            color="#053d7c"
            height="50"
            width="50" />
        </div>
      </Card>;
  }

  render() {
    return (
      <div className='content'>
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
        <LoaderModal
          isOpen={this.state.buttonDisabled}
          text={this.state.loaderModalText} />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ dadosUsuario: state.dadosUsuario });

export default connect(mapStateToProps)(CadastroAula);
