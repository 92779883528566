import React from 'react';
import { Link } from 'react-router-dom';
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
} from 'reactstrap';

import Loader from 'react-loader-spinner';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';

import SituacaoDepoimento from 'assets/csv/situacoes-depoimento.json';
import Papel from 'assets/csv/papeis.json';

import DepoimentosService from 'services/DepoimentosService';
import LoginService from 'services/LoginService';

class CadastroDepoimento extends React.Component {
  constructor(props) {
    super(props);

    this.situacoes = [
      SituacaoDepoimento.AGUARDANDO_MODERACAO,
      SituacaoDepoimento.ACEITO,
      SituacaoDepoimento.REJEITADO,
    ];

    this.descricaoRef = React.createRef();
    this.textoRef = React.createRef();

    this.depoimentosService = new DepoimentosService();
    this.loginService = new LoginService();

    this.state = {
      showAlert: false,
      buttonDisabled: false,


      descricaoState: '',
      textoState: '',

      loaderModalText: '',

      depoimentoSelecionado: null,
      erro: null,
    };

    this.cadastrarDepoimento = this.cadastrarDepoimento.bind(this);
    this.validarCampos = this.validarCampos.bind(this);
    this.limparFormulario = this.limparFormulario.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentWillUnmount() {
    this.loginService.abortPedingRequests();
    this.depoimentosService.abortPedingRequests();

    this.umounted = true;
  }

  async componentDidMount() {
    await this.loginService.verificarPapel(this.props.role);

    try {
      if (this.props.match.params.idDepoimento !== undefined) {
        const depoimento = await this.depoimentosService
          .carregarDepoimento(this.props.match.params.idDepoimento);

        this.setState({
          depoimentoSelecionado: depoimento,
        });

        this.contentLoad();
      }
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  contentLoad() {
    this.descricaoRef.current.value = this.state.depoimentoSelecionado.descricao;
    this.textoRef.current.value = this.state.depoimentoSelecionado.texto;
  }

  validarCampos() {
    let ret = true;
    if (this.descricaoRef.current.value === '') {
      this.setState({ descricaoState: 'has-danger' });
      ret = false;
    }
    if (this.textoRef.current.value === '') {
      this.setState({ textoState: 'has-danger' });
      ret = false;
    }
    return ret;
  }

  async cadastrarDepoimento() {
    if (!this.validarCampos()) return;

    this.setState({
      buttonDisabled: true,
      showAlert: false,
      loaderModalText: 'Cadastrando depoimento...',
    });

    const depoimento = {
      descricao: this.descricaoRef.current.value,
      texto: this.textoRef.current.value,
    };

    try {
      await this.depoimentosService.cadastrarDepoimento(depoimento);
      this.limparFormulario();
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Depoimento cadastrado com sucesso',
      });
    } catch (e) {
      if (this.umounted) return;
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao cadastrar depoimento',
      });
    }
  }

  async atualizarSituacao(situacao) {
    this.setState({
      buttonDisabled: true,
      showAlert: false,
      loaderModalText: 'Atualizando situacação do depoimento...',
    });

    try {
      await this.depoimentosService.atualizarSituacao(
        this.state.depoimentoSelecionado.id, situacao,
      );
      this.setState({
        depoimentoSelecionado: { ...this.state.depoimentoSelecionado, situacao },
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Situação atualizada com sucesso',
      });
    } catch (e) {
      if (this.umounted) return;
      this.setState({
        buttonDisabled: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: 'Erro ao atualizar situação',
      });
    }
  }

  limparFormulario() {
    this.descricaoRef.current.value = '';
    this.textoRef.current.value = '';
  }

  onSubmit(e) {
    e.preventDefault();
    if (this.state.buttonDisabled) return;
    this.cadastrarDepoimento();
  }

  conteudoPagina() {
    return (this.props.match.params.idDepoimento !== undefined
      && this.state.depoimentoSelecionado === null)
      ? <Card>
        <div align="center" style={{ margin: 50 }}>
          <Loader
            type="Oval"
            color="#053d7c"
            height="50"
            width="50" />
        </div>
      </Card> : <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.props.layout}/depoimentos`}>Depoimentos</Link></BreadcrumbItem>
            <BreadcrumbItem active>{this.state.depoimentoSelecionado ? 'Visualisação' : 'Cadastro'}</BreadcrumbItem>
          </Breadcrumb>
          <Form onSubmit={this.onSubmit}>
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Dados do depoimento</CardTitle>
              </CardHeader>
              <CardBody>
                {
                  this.props.match.params.idDepoimento && this.state.depoimentoSelecionado && <Row>
                    <Col md="5">
                      <FormGroup>
                        <label>Situação *</label>
                        <Input
                          value={this.situacoes[this.state.depoimentoSelecionado.situacao].label}
                          disabled
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                }
                {
                  this.props.role === Papel.ADMINISTRADOR.value && <Row>
                    <Col md="5">
                      <FormGroup>
                        <label>Usuário *</label>
                        <Input
                          value={`${this.state.depoimentoSelecionado.usuario} - ${this.state.depoimentoSelecionado.ente}`}
                          disabled
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                }
                <Row>
                  <Col md="5">
                    <FormGroup className={`has-label ${this.state.descricaoState}`}>
                      <label>Descrição rápida *</label>
                      <Input
                        innerRef={this.descricaoRef}
                        disabled={this.state.depoimentoSelecionado}
                        type="text"
                        maxLength="50"
                        onChange={() => this.setState({ descricaoState: 'has-success' })}
                      />
                      {this.state.descricaoState === 'has-danger' ? (
                        <label className="error">
                          Informe a descrição do depoimento.
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col md="12">
                    <FormGroup className={`has-label ${this.state.textoState}`}>
                      <label>Texto *</label>
                      <Input
                        innerRef={this.textoRef}
                        disabled={this.state.depoimentoSelecionado}
                        onChange={() => this.setState({ textoState: '' })}
                        type="textarea"
                        maxLength="400"
                        style={{ height: '200px' }} />
                      {this.state.textoState === 'has-danger' ? (
                        <label className="error">
                          Informe o texto do depoimento
                        </label>
                      ) : null}
                    </FormGroup>
                  </Col>
                </Row>
                <div className="category form-category">
                  * Campos obrigatórios
                </div>
              </CardBody>
              <CardFooter>
                <Row>
                  <Col md="6" className="text-left">
                    <Button color="primary" disabled={this.state.buttonDisabled} onClick={() => this.props.history.push(`${this.props.layout}/depoimentos`)} >
                      Voltar
                    </Button>
                  </Col>
                  <Col md="6" className="text-right">
                    {
                      this.props.role !== Papel.ADMINISTRADOR.value
                        ? !this.state.depoimentoSelecionado
                        && <Button type="submit" color="primary" disabled={this.state.buttonDisabled} >
                          Cadastrar
                          </Button>
                        : <>
                          <Button
                            onClick={() => this.atualizarSituacao(
                              SituacaoDepoimento.REJEITADO.value,
                            )}
                            color="danger"
                            disabled={this.state.buttonDisabled}>
                            Rejeitar
                          </Button>&nbsp;&nbsp;&nbsp;
                          <Button
                            onClick={() => this.atualizarSituacao(
                              SituacaoDepoimento.ACEITO.value,
                            )}
                            color="primary"
                            disabled={this.state.buttonDisabled}>
                            Aceitar
                          </Button>
                        </>
                    }
                  </Col>
                </Row>
              </CardFooter>
            </Card>
          </Form>
        </Col>
      </Row>;
  }

  render() {
    return (
      <div className="content">
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
          </div>
          </Card>
        }
        <LoaderModal
          isOpen={this.state.buttonDisabled}
          text={this.state.loaderModalText} />
      </div>
    );
  }
}

export default CadastroDepoimento;
