import React from 'react';
import { Link } from 'react-router-dom';

import {
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Breadcrumb,
  BreadcrumbItem,
  Button,
  FormGroup,
  Table,
  UncontrolledTooltip,
} from 'reactstrap';

import Loader from 'react-loader-spinner';
import Select from 'react-select';

import Alert from 'views/components/Alert';
import LoaderModal from 'views/components/LoaderModal';
import ConfirmModal from 'views/components/ConfirmModal';

import UsuarioService from 'services/UsuarioService';
import AnosLetivosService from 'services/AnosLetivosService';
import SolicitacaoMatriculaService from 'services/SolicitacaoMatriculaService';

import Turno from 'assets/csv/turnos.json';
import SituacaoSolicitacaoMatricula from 'assets/csv/situacoes-solicitacao-matricula.json';

class MatriculasSolicitadasPais extends React.Component {
  constructor(props) {
    super(props);

    this.usuarioService = new UsuarioService();
    this.anosLetivosService = new AnosLetivosService();
    this.solicitacaoMatriculaService = new SolicitacaoMatriculaService();

    this.turnos = [
      Turno.MATUTINO,
      Turno.VESPERTINO,
      Turno.NOTURNO,
      Turno.INTEGRAL,
    ];

    this.situacoes = [
      SituacaoSolicitacaoMatricula.AGUARDANDO,
      SituacaoSolicitacaoMatricula.ACEITA,
      SituacaoSolicitacaoMatricula.RECUSADA,
      SituacaoSolicitacaoMatricula.CANCELADA,
    ]

    this.state = {
      showAlert: false,
      error: null,

      dadosTabela: {
        header: [['Nome', '40%'], ['Turma', '40%']],
        columnAlign: ['text-left', 'text-left'],
        keys: ['nome', 'turma'],
        rows: [],
        total: 0,
      },
    };
  }

  async componentDidMount() {
    try {
      const filho = await this.usuarioService
        .carregarUsuario(this.props.match.params.idFilho);

      const data = await this.anosLetivosService
        .carregarAnosLetivos(1, 99999, '');

      const { anosLetivos } = data;
      anosLetivos.forEach((anoLetivo) => Object.assign(
        anoLetivo, { value: anoLetivo.id, label: anoLetivo.ano },
      ));

      const anoLetivoSelecionado = anosLetivos.length > 0 ?
        anosLetivos[anosLetivos.length - 1] : undefined;

      if (anoLetivoSelecionado) {
        this.carregarSolicitacoesMatriculaAluno(anoLetivoSelecionado);
      }

      this.setState({
        anosLetivos,
        filho,
        anoLetivoSelecionado,
      });
    } catch (e) {
      this.setState({ erro: true });
    }
  }

  async carregarSolicitacoesMatriculaAluno(anoLetivo) {

    this.setState({
      showAlert: false,
      showLoaderModal: true,
      loaderModalText: 'Carregando solicitações de matrícula...',
    });

    try {
      const solicitacoesMatricula = await this.solicitacaoMatriculaService
        .carregarSolicitacoesMatriculaAluno(anoLetivo.id, this.props.match.params.idFilho);

      this.setState({
        showLoaderModal: false,
        solicitacoesMatricula,
      });
    } catch (e) {
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: e || 'Erro ao carregar solicitações de matrícula do ano letivo',
      });
    }
  }

  solicitarMatricula() {
    if (!this.state.anoLetivoSelecionado) {
      this.setState({
        anoLetivoState: 'danger',
      });
      return;
    }
    
    this.props.history.push(`${this.props.layout}/matriculasonline/`
      + `anoletivo/${this.state.anoLetivoSelecionado.id}`
      + `/filho/${this.props.match.params.idFilho}/solicitar`)
  }

  confirmarCancelamentoSolicitacaoMatricula(solicitacaoMatricula) {
    this.setState({
      solicitacaoMatriculaSelecionada: solicitacaoMatricula,
      showConfirmModal: true
    });
  }

  async cancelarSolicitacaoMatricula() {
    this.setState({ showAlert: false, showLoaderModal: true });

    try {
      await this.solicitacaoMatriculaService
        .cancelarSolicitacaoMatricula(this.state.solicitacaoMatriculaSelecionada.id);

      this.carregarSolicitacoesMatriculaAluno(this.state.anoLetivoSelecionado);

      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'success',
        alertMsg: 'Solicitação de matrícula cancelada com sucesso',
      });
    } catch (msg) {
      if (this.umounted) return;
      this.setState({
        showLoaderModal: false,
        showAlert: true,
        alertColor: 'danger',
        alertMsg: msg || 'Erro ao cancelar solicitação de matrícula',
      });
    }
  }

  solicitacaoMatriculaSelecionada(solicitacaoMatricula) {
    this.props.history.push(`${this.props.layout}/matriculasonline`
      + `/filho/${this.props.match.params.idFilho}`
      + `/solicitacaomatricula/${solicitacaoMatricula.id}`);
  }

  conteudoPagina() {
    return this.state.filho ? <>
      <Row>
        <Col md="12">
          <Alert
            color={this.state.alertColor}
            isOpen={this.state.showAlert}
            toggle={() => { this.setState({ showAlert: false }); }}>
            {this.state.alertMsg}
          </Alert>
          <Breadcrumb>
            <BreadcrumbItem><Link to={`${this.props.layout}/matriculasonline`}>Matrículas Online</Link></BreadcrumbItem>
            <BreadcrumbItem active>{this.state.filho.nome}</BreadcrumbItem>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">Solicitações de matrícula realizadas</CardTitle>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="2">
                  <FormGroup className={'has-label has-danger'}>
                    <label>Ano letivo *</label>
                    <Select
                      noOptionsMessage={() => 'Nenhuma entrada'}
                      className={`react-select primary ${this.state.anoLetivoState}`}
                      classNamePrefix="react-select"
                      placeholder="Ano letivo..."
                      value={this.state.anoLetivoSelecionado}
                      onChange={(event) => {
                        this.setState({
                          anoLetivoSelecionado: event,
                          anoLetivoState: '',
                        });
                        this.carregarSolicitacoesMatriculaAluno(event);
                      }}
                      options={this.state.anosLetivos}
                    />
                    {this.state.anoLetivoState === 'danger' ? (
                      <label className="error">
                        Informe o ano letivo.
                      </label>
                    ) : null}
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  {
                    this.state.solicitacoesMatricula && <>
                      {
                        this.state.solicitacoesMatricula.length > 0 ?
                          <Table hover>
                            <thead className="text-primary">
                              <tr>
                                <th style={{ width: '20%' }}>Aluno</th>
                                <th style={{ width: '20%' }}>Escola</th>
                                <th style={{ width: '20%' }}>Série</th>
                                <th style={{ width: '20%' }}>Turma</th>
                                <th className="text-center" style={{ width: '10%' }}>Turno</th>
                                <th className="text-center" style={{ width: '10%' }}>Situação</th>
                                <th className="text-center" style={{ width: '10%' }}>Data</th>
                                <th className="text-center" style={{ width: '10%' }}>Ação</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                this.state.solicitacoesMatricula.map((solicitacaoMatricula, idx) => <tr key={idx}
                                  className='tr-clickable'
                                  onClick={() => this.solicitacaoMatriculaSelecionada(solicitacaoMatricula)}>
                                  <td>{solicitacaoMatricula.aluno.nome}</td>
                                  <td>{solicitacaoMatricula.turma.escola.nome}</td>
                                  <td>{solicitacaoMatricula.turma.curso.nome}</td>
                                  <td>{solicitacaoMatricula.turma.nome}</td>
                                  <td className="text-center">{this.turnos[solicitacaoMatricula.turma.turno].label}</td>
                                  <td className="text-center">{this.situacoes[solicitacaoMatricula.situacao].label}</td>
                                  <td className="text-center">{solicitacaoMatricula.data}</td>
                                  <td className="text-center">
                                    {
                                      solicitacaoMatricula.situacao === SituacaoSolicitacaoMatricula.AGUARDANDO.value && <>
                                        <Button
                                          id={`btnCancelar${idx}`}
                                          className="btn-icon"
                                          color="danger"
                                          size="sm"
                                          onClick={(evt) => {
                                            evt.stopPropagation();
                                            this.confirmarCancelamentoSolicitacaoMatricula(solicitacaoMatricula);
                                          }}
                                          type="button">
                                          <i className="fa fa-times" />
                                        </Button>
                                        <UncontrolledTooltip placement="bottom" target={`btnCancelar${idx}`} delay={0}>
                                          Cancelar
                                        </UncontrolledTooltip>
                                      </>
                                    }
                                  </td>
                                </tr>)
                              }
                            </tbody>
                          </Table> : <div align="center" style={{ margin: 50 }}>
                            Nenhuma solicitação de matrícula realizada
                          </div>
                      }
                    </>
                  }
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <Row>
                <Col md="6" className="text-left">
                  <Button
                    color="primary"
                    onClick={() => this.props.history.push(`${this.props.layout}/matriculasonline`)} >
                    Voltar
                  </Button>
                </Col>
                <Col md="6" className="text-right">
                  <Button
                    color="primary"
                    onClick={() => this.solicitarMatricula()}>
                    Solicitar matrícula
                  </Button>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col >
      </Row >
    </>
      : <Card>
        <div align="center" style={{ margin: 50 }}>
          <Loader
            type="Oval"
            color="#053d7c"
            height="50"
            width="50" />
        </div>
      </Card>;
  }

  render() {
    return (
      <div className='content'>
        {!this.state.erro
          ? this.conteudoPagina()
          : <Card>
            <div align="center" style={{ margin: 50 }}>
              Erro ao buscar informações da página
            </div>
          </Card>
        }
        <ConfirmModal
          isOpen={this.state.showConfirmModal}
          callback={(confirm) => {
            this.setState({ showConfirmModal: false });
            if (confirm) {
              this.cancelarSolicitacaoMatricula();
            } else {
              this.setState({ solicitacaoMatriculaSelecionada: null });
            }
          }}
          confirmText="Cancelar"
          cancelText="Manter"
          text="Confirme o cancelamento da solicitação de matrícula." />
        <LoaderModal
          isOpen={this.state.showLoaderModal}
          text="Carregando solicitações de matrícula..." />
      </div>
    );
  }
}

export default MatriculasSolicitadasPais;